import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { MODAL_KEY, toggleModal } from '@/common/store/slices/modal/modalSlice';
import styles from '@/common/styles/components/options.module.scss';
import { useRootSelector } from '@/common/hooks';
import { OPTION_TAB } from '@/common/constants';

/**
 * 헤더 옵션 버튼을 누르면 노출되는 옵션 설정 탭 컴포넌트
 * @returns
 */

interface Props {
  setVisibleTab: Dispatch<SetStateAction<string>>;
}

export const HeaderOptionTab: FC<Props> = ({ setVisibleTab }) => {
  const optionsModal = useRootSelector(
    (state) => state.modalSlice[MODAL_KEY].modals.optionsModal
  );
  const dispatch = useDispatch();

  const toggleOptionModal = useCallback(() => {
    dispatch(toggleModal({ modal: 'optionsModal' }));
    setVisibleTab(OPTION_TAB);
  }, [dispatch, setVisibleTab]);

  return (
    <>
      <div
        className={
          optionsModal.isOn
            ? `${styles.btn_item} ${styles.item_selected}`
            : `${styles.btn_item}`
        }
      >
        <button
          type="button"
          className={styles.btn_setting}
          onClick={toggleOptionModal}
        >
          설정
        </button>
      </div>
    </>
  );
};
