import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';

import { PersonalSliceState } from '../personalSlice';
import { COLLECT_KEY, CollectInterests } from './state';
import { ContentsDisplayType } from '@/common/domain';
import { Storage } from '@/common/modules';
import { authService } from '@/common/services';

export const collectReducers = {
  /**
   * 관심사를 토글하여 선택, 선택 해제할 수 있는 리듀서
   */
  toggleInterest: (
    state: WritableDraft<PersonalSliceState>,
    action: PayloadAction<keyof CollectInterests>
  ) => {
    state[COLLECT_KEY].interests[action.payload] =
      !state[COLLECT_KEY].interests[action.payload];
  },

  /**
   * 콘텐츠 박스 설정을 토글하여 선택, 선택 해제할 수 있는 리듀서
   */
  toggleIsContentsBox: (state: WritableDraft<PersonalSliceState>) => {
    state[COLLECT_KEY].options.isContentsBox =
      !state[COLLECT_KEY].options.isContentsBox;
  },

  /**
   * 컨텐츠 보기 설정을 설정할 수 있는 리듀서
   */
  setContentsDisplayType: (
    state: WritableDraft<PersonalSliceState>,
    action: PayloadAction<ContentsDisplayType>
  ) => {
    state[COLLECT_KEY].options.contentsDisplayType = action.payload;
  },

  /**
   * 모아보기 페이지에 필요한 데이터를 가져오는 리듀서
   */
  loadCollectState: (state: WritableDraft<PersonalSliceState>) => {
    const loadedState = Storage.getData(COLLECT_KEY);

    if (
      !authService.checkIsAuth() &&
      !!loadedState &&
      loadedState !== null &&
      Object.keys(loadedState).length !== 0
    )
      state[COLLECT_KEY] = { ...state[COLLECT_KEY], ...loadedState };
  },
};
