import { createSlice } from '@reduxjs/toolkit';

import {
  COLLECT_KEY,
  CollectState,
  collectState,
  collectReducers,
} from '@/common/store/slices/personal/collect';
import {
  SHORTCUTS_KEY,
  ShortcutsState,
  shortcutsState,
  shortcutsReducers,
} from '@/common/store/slices/personal/shortcuts';
import {
  optionsState,
  OptionsState,
  OPTIONS_KEY,
  optionsReducers,
} from '@/common/store/slices/personal/options';
import { personalExtraReducers as extraReducers } from './extraReducers';

export interface PersonalSliceState {
  [COLLECT_KEY]: CollectState;
  [SHORTCUTS_KEY]: ShortcutsState;
  [OPTIONS_KEY]: OptionsState;
}

export const PERSONAL_SLICE = 'personalSlice';

const initialState: PersonalSliceState = {
  [COLLECT_KEY]: collectState,
  [OPTIONS_KEY]: optionsState,
  [SHORTCUTS_KEY]: shortcutsState,
};

const reducers = {
  ...collectReducers,
  ...shortcutsReducers,
  ...optionsReducers,
};

const personalSlice = createSlice({
  name: PERSONAL_SLICE,
  initialState,
  reducers,
  extraReducers,
});

export const {
  addSite,
  removeSite,
  editSite,
  combineSites,
  addSiteToFolder,
  addFolder,
  removeFolder,
  editFolder,
  convertFolderToSite,
  reorderShortcuts,
  loadShortcutsState,
  selectWallpaper,
  setChangeCycle,
  setIconBorderRadiusRate,
  toggleIsShowFrequentVisitedSite,
  toggleIsHidingIconNames,
  setLocation,
  addSiteToSelectedSites,
  removeSiteFromSelectedSites,
  updateOriginWithTemporary,
  loadOptionsState,
  toggleInterest,
  loadCollectState,
  toggleIsContentsBox,
  setContentsDisplayType,
} = personalSlice.actions;

export default personalSlice;
