import { useState } from 'react';

import { Shortcuts, ShortcutsSite } from '@/common/store/slices/personal';
import { SITE, FOLDER } from '@/common/constants';

export const useTargetSite = (
  shortcuts: Shortcuts[],
  targetSiteUrl: string
) => {
  let parentShortcut: ShortcutsSite | null = null;
  let childShortcut: ShortcutsSite | null = null;

  shortcuts.forEach((parent) => {
    if (parent.type === SITE && parent.url === targetSiteUrl)
      parentShortcut = parent;

    if (parent.type === FOLDER) {
      parent.children.forEach((child) => {
        if (child.url === targetSiteUrl) {
          childShortcut = child;
        }
      });
    }
  });

  const [targetSite] = useState<ShortcutsSite | null>(
    childShortcut ? childShortcut : parentShortcut
  );

  return targetSite;
};
