import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';

import {
  ShortcutsSite,
  ShortcutsFolder,
  SHORTCUTS_KEY,
  Shortcuts,
} from './state';
import { PersonalSliceState } from '../personalSlice';
import { SITE, FOLDER } from '@/common/constants';
import { Storage } from '@/common/modules';
import { authService } from '@/common/services';

interface AddSiteToFolderPayload {
  folder: Omit<ShortcutsFolder, 'children'>;
  site: ShortcutsSite;
}

interface EditSitePayload {
  site: Omit<ShortcutsSite, 'type'>;
  changes: Omit<ShortcutsSite, 'type'>;
}

interface CombineSitesPayload {
  sourceItem: ShortcutsSite;
  destinationItem: ShortcutsSite;
}

interface EditFolderPayload {
  target: Omit<ShortcutsFolder, 'type' | 'children'>;
  changes: Omit<ShortcutsFolder, 'type'>;
}

interface ConvertFolderToSitePayload {
  folder: Omit<ShortcutsFolder, 'type' | 'children'>;
  site: Omit<ShortcutsSite, 'type'>;
}

interface ReorderShortcutsPayload {
  startIndex: number;
  endIndex: number;
}

/**
 * 중복된 아이템이 있는지 검증하는 함수
 */
const isSameItem = (items: Shortcuts[], id: string, type: string): boolean => {
  return items.some((element) => {
    if (element.type === FOLDER && element.children.length)
      return isSameItem(element.children, id, type);

    return (
      (type === SITE && element.type === type && element.url === id) ||
      (type === FOLDER && element.type === type && element.name === id)
    );
  });
};

export const shortcutsReducers = {
  /**
   * 사이트 바로가기에 새로운 사이트를 추가하는 리듀서
   */
  addSite: (
    state: WritableDraft<PersonalSliceState>,
    action: PayloadAction<ShortcutsSite>
  ) => {
    const { shortcuts } = state[SHORTCUTS_KEY];
    const { url, type } = action.payload;

    if (isSameItem(shortcuts, url, type)) return;

    if (!url) return;

    shortcuts.push(action.payload);
  },

  /** 바로가기 폴더에 새로운 사이트를 추가하는 리듀서 */
  addSiteToFolder: (
    state: WritableDraft<PersonalSliceState>,
    action: PayloadAction<AddSiteToFolderPayload>
  ) => {
    const { folder, site } = action.payload;
    const { shortcuts } = state[SHORTCUTS_KEY];

    const folderItem = shortcuts.find(
      (item) => item.type === FOLDER && item.name === folder.name
    );

    if (!folderItem || folderItem.type !== FOLDER) return;

    folderItem.children.push({
      type: SITE,
      name: site.name,
      url: site.url,
    });

    const siteIndex = shortcuts.findIndex(
      (item) => item.type === site.type && item.url === site.url
    );

    shortcuts.splice(siteIndex, 1);
  },

  /**
   * 사이트 바로가기에 선택된 사이트를 수정하는 리듀서
   */
  editSite: (
    state: WritableDraft<PersonalSliceState>,
    action: PayloadAction<EditSitePayload>
  ) => {
    const { shortcuts } = state[SHORTCUTS_KEY];
    const { site, changes } = action.payload;

    const flattenShortcuts = shortcuts.reduce((acc, shortcut) => {
      acc.push(shortcut);

      if (shortcut.type === FOLDER && shortcut.children.length) {
        shortcut.children.forEach((child) => acc.push(child));
      }

      return acc;
    }, [] as typeof shortcuts);

    flattenShortcuts.forEach((shortcut) => {
      if (shortcut.type === SITE && shortcut.url === site.url) {
        shortcut.name = changes.name;
        shortcut.url = changes.url;
      }
    });
  },

  /**
   * 사이트 바로가기에 선택된 사이트를 제거하는 리듀서
   */
  removeSite: (
    state: WritableDraft<PersonalSliceState>,
    action: PayloadAction<Omit<ShortcutsSite, 'name' | 'type'>>
  ) => {
    const { shortcuts } = state[SHORTCUTS_KEY];
    const { url } = action.payload;

    let indexA = -1;
    let indexB = -1;

    shortcuts.forEach((shortcut, index) => {
      if (shortcut.type === SITE && shortcut.url === url) {
        indexA = index;
      }

      if (shortcut.type === FOLDER) {
        shortcut.children.forEach((child, _index) => {
          if (child.url === url) {
            indexA = index;
            indexB = _index;
          }
        });
      }
    });

    if (indexA !== -1 && indexB !== -1) {
      const { children } = shortcuts[indexA] as ShortcutsFolder;

      children.splice(indexB, 1);

      return;
    }

    if (indexA !== -1) {
      shortcuts.splice(indexA, 1);
    }
  },

  /**
   * 사이트 바로가기 두 개를 합쳐서 폴더로 대체하는 메소드
   */
  combineSites: (
    state: WritableDraft<PersonalSliceState>,
    action: PayloadAction<CombineSitesPayload>
  ) => {
    const { sourceItem, destinationItem } = action.payload;
    const { shortcuts } = state[SHORTCUTS_KEY];
    const sourceItemIndex = shortcuts.findIndex(
      (item) => item.type === SITE && item.url === sourceItem.url
    );

    if (sourceItemIndex === -1) return;

    shortcuts[sourceItemIndex] = {
      type: FOLDER,
      name: `${sourceItem.name}, ${destinationItem.name}`,
      children: [sourceItem, destinationItem],
    };
    const destinationItemIndex = shortcuts.findIndex(
      (item) => item.type === SITE && item.url === destinationItem.url
    );

    if (destinationItemIndex === -1) return;

    shortcuts.splice(destinationItemIndex, 1);
  },

  /**
   * 사이트 바로가기에 새로운 폴더를 추가하는 리듀서
   */
  addFolder: (
    state: WritableDraft<PersonalSliceState>,
    action: PayloadAction<ShortcutsFolder>
  ) => {
    const { shortcuts } = state[SHORTCUTS_KEY];
    const { name, type } = action.payload;

    if (isSameItem(shortcuts, name, type)) return;

    shortcuts.push(action.payload);
  },

  /**
   * 사이트 바로가기에 선택된 폴더를 제거하는 리듀서
   */
  removeFolder: (
    state: WritableDraft<PersonalSliceState>,
    action: PayloadAction<Omit<ShortcutsFolder, 'children'>>
  ) => {
    const { shortcuts } = state[SHORTCUTS_KEY];
    const { name, type } = action.payload;

    state[SHORTCUTS_KEY].shortcuts = shortcuts.filter(
      (siteOrFolder) =>
        !(siteOrFolder.type === type && siteOrFolder.name === name)
    );
  },

  /**
   * 사이트 바로가기에서 선택된 폴더를 수정하는 리듀서
   */
  editFolder: (
    state: WritableDraft<PersonalSliceState>,
    action: PayloadAction<EditFolderPayload>
  ) => {
    const { target, changes } = action.payload;
    const { shortcuts } = state[SHORTCUTS_KEY];

    const targetFolder = shortcuts.find(
      (shortcut) => shortcut.type === FOLDER && shortcut.name === target.name
    );

    if (targetFolder?.type !== FOLDER) return;

    targetFolder.children = changes.children;
    targetFolder.name = changes.name;
  },

  /**
   * 사이트 바로가기에서 선택된 폴더를 사이트로 바꾸는 리듀서
   */
  convertFolderToSite: (
    state: WritableDraft<PersonalSliceState>,
    action: PayloadAction<ConvertFolderToSitePayload>
  ) => {
    const { folder, site } = action.payload;
    const { name, url } = site;
    const { shortcuts } = state[SHORTCUTS_KEY];

    const folderIndex = shortcuts.findIndex(
      (shortcut) => shortcut.type === FOLDER && shortcut.name === folder.name
    );

    if (folderIndex === -1) return;

    shortcuts[folderIndex] = { type: SITE, name, url };
  },

  /**
   * 사이트 바로가기들을 재정렬하는 리듀서
   */
  reorderShortcuts: (
    state: WritableDraft<PersonalSliceState>,
    action: PayloadAction<ReorderShortcutsPayload>
  ) => {
    const { startIndex, endIndex } = action.payload;

    const [removed] = state[SHORTCUTS_KEY].shortcuts.splice(startIndex, 1);

    state[SHORTCUTS_KEY].shortcuts.splice(endIndex, 0, removed);
  },

  /**
   * 바로가기 로직에 필요한 데이터를 가져오는 리듀서
   */
  loadShortcutsState: (state: WritableDraft<PersonalSliceState>) => {
    const loadedState: {
      shortcuts: Shortcuts[];
    } = Storage.getData(SHORTCUTS_KEY);

    if (
      !authService.checkIsAuth() &&
      !!loadedState &&
      loadedState !== null &&
      Object.keys(loadedState).length !== 0
    ) {
      // 기존 버그로 인해 로컬스토리지 내에 null 이 발생하여 이를 제거해주는 로직
      loadedState.shortcuts = loadedState.shortcuts.filter(
        (shortcut) => shortcut !== null
      );

      state[SHORTCUTS_KEY] = { ...state[SHORTCUTS_KEY], ...loadedState };
    }
  },
};
