import React, { FC, useCallback } from 'react';

import { useRootSelector } from '@/common/hooks';
import { HeaderShortcuts } from './HeaderShortcuts';
import { SHORTCUTS_KEY } from '@/common/store/slices/personal';
import styles from '@/common/styles/components/links.module.scss';

/**
 * 헤더 바로가기 영역 슬라이드 컴포넌트
 * @returns
 */
export const HeaderShortcutsWrapper: FC = () => {
  const shortcuts = useRootSelector(
    (state) => state.personalSlice[SHORTCUTS_KEY].shortcuts
  );

  const preventDefault = useCallback((e: Event) => {
    e.preventDefault();
  }, []);

  const enableScroll = useCallback(() => {
    window.removeEventListener('wheel', preventDefault);
  }, [preventDefault]);

  const disableScroll = useCallback(() => {
    // @ts-ignore - internet explorer
    const isIE = typeof document.documentMode === 'number';

    if (isIE) {
      window.addEventListener('wheel', preventDefault);
    } else {
      window.addEventListener('wheel', preventDefault, {
        passive: false,
      });
    }
  }, [preventDefault]);

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.slide_wrap}
        onMouseEnter={disableScroll}
        onMouseLeave={enableScroll}
      >
        <div className="slide">
          <HeaderShortcuts items={shortcuts} />
        </div>
      </div>
    </div>
  );
};
