import React, {
  FC,
  UIEvent,
  useCallback,
  EventHandler,
  MouseEvent,
} from 'react';
import { useDispatch } from 'react-redux';

import {
  ShortcutsFolder,
  convertFolderToSite,
  removeSite,
  SHORTCUTS_KEY,
  ShortcutsSite,
  updateShortcutsState,
  fetchPersonalState,
} from '@/common/store/slices/personal';
import { useRootSelector } from '@/common/hooks';
import { MODAL_KEY, toggleModal } from '@/common/store/slices/modal/modalSlice';
import styles from '@/common/styles/components/links.module.scss';
import {
  DEFAULT_URLS_CSS_CLASSES,
  FAVICON_URL,
  FOLDER,
} from '@/common/constants';
import { statisticsService } from '@/common/services';
import { convertToProperUrl } from '@/common/utils';

interface Props {
  item: ShortcutsSite;
}

/**
 * 폴더 영역 사이트 컴포넌트
 */
export const FolderSite: FC<Props> = ({ item }) => {
  const dispatch = useDispatch();
  const shortcuts = useRootSelector(
    (state) => state.personalSlice[SHORTCUTS_KEY].shortcuts
  );
  const optionsIsShowName = useRootSelector(
    (state) => state.personalSlice.options.origin.widget.isHidingIconNames
  );
  const targetFolderName = useRootSelector(
    (state) => state.modalSlice[MODAL_KEY].modals.folderModal.targetId
  );

  const more = (event: UIEvent<HTMLButtonElement>) => {
    const { parentNode } = event.target as HTMLElement;

    if (!parentNode) return;

    const layer = parentNode.querySelector(`.${styles.edit_layer}`);

    if (!layer) return;

    if (layer.classList.contains(`${styles.layer_on}`)) {
      layer.classList.remove(`${styles.layer_on}`);
    } else {
      layer.classList.add(`${styles.layer_on}`);
    }

    statisticsService.send('@Click', {
      pos: 'start_site_folder',
      cm: 'more',
      outurl: item.url,
    });
  };

  const click = useCallback(() => {
    statisticsService.send('@Click', {
      pos: 'start_site_folder',
      cm: 'site',
      outurl: item.url,
    });
  }, [item.url]);

  const remove: EventHandler<MouseEvent> = useCallback(
    async (event) => {
      event.preventDefault();

      dispatch(removeSite({ url: item.url }));

      const targetFolder = shortcuts.find(
        (shortcut) =>
          shortcut.type === FOLDER && shortcut.name === targetFolderName
      ) as ShortcutsFolder;

      if (
        targetFolder &&
        targetFolderName &&
        targetFolder.children.length === 2
      ) {
        dispatch(
          convertFolderToSite({
            folder: { name: targetFolderName },
            site: {
              name: targetFolder.children[0].name,
              url: targetFolder.children[0].url,
            },
          })
        );

        dispatch(
          toggleModal({
            modal: 'folderModal',
          })
        );
      }

      await dispatch(updateShortcutsState());
      await dispatch(fetchPersonalState());

      statisticsService.send('@Click', {
        pos: 'start_site_folder',
        cm: 'delete',
        outurl: item.url,
      });
    },
    [item, shortcuts, targetFolderName, dispatch]
  );

  const edit: EventHandler<MouseEvent> = useCallback(
    (event) => {
      event.preventDefault();

      dispatch(
        toggleModal({
          modal: 'editSiteModal',
          targetId: item.url,
        })
      );

      statisticsService.send('@Click', {
        pos: 'start_site_folder',
        cm: 'edit',
        outurl: item.url,
      });
    },
    [item, dispatch]
  );

  const renderIcon = useCallback((item: ShortcutsSite) => {
    const iconCssByUrl =
      DEFAULT_URLS_CSS_CLASSES[
        convertToProperUrl(item.url) as keyof typeof DEFAULT_URLS_CSS_CLASSES
      ];

    if (iconCssByUrl)
      return <span className={`${styles[iconCssByUrl]}`}>{iconCssByUrl}</span>;

    return (
      <img
        loading="lazy"
        className={`${styles.icon_img}`}
        src={FAVICON_URL + item.url}
        alt={item.name}
      />
    );
  }, []);

  return (
    <>
      <a
        href={convertToProperUrl(item.url)}
        onClick={click}
        target="_blank"
        rel="noreferrer"
      >
        <div className={`${styles.item} ${styles.single}`}>
          {renderIcon(item)}
        </div>
        {optionsIsShowName ? null : (
          <strong className={styles.item_name}>{item.name}</strong>
        )}
      </a>

      <div className="edit">
        <button type="button" className={styles.btn_edit} onClick={more}>
          편집
        </button>
        <div className={styles.edit_layer}>
          <a href="#" onClick={edit}>
            편집
          </a>
          <a href="#" onClick={remove}>
            삭제
          </a>
        </div>
      </div>
    </>
  );
};
