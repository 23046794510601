import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Image from 'next/image';
import dynamic from 'next/dynamic';

import {
  useLocalStorage,
  useRootSelector,
  useUserStates,
  useIsInitialized,
  useIsWeekPassed,
} from '@/common/hooks';

import { HeaderGnb } from '@/common/components/header/HeaderGnb';
import { HeaderSearchContainer } from '@/common/components/header/HeaderSearchContainer';
import { HeaderShortcutsContainer } from '@/common/components/header/shortcuts';
import { HeaderClock, HeaderWeather } from '@/common/components/header/common';
import { HeaderOptionTab } from '@/common/components/header/option';
import { HeaderAlarmTab } from '@/common/components/header/alarm';
import { HeaderLoginTab } from '@/common/components/header/login';
import { HeaderCollectTab } from '@/common/components/header/collect';
import {
  EditSiteModal,
  FolderModal,
  OptionsModal,
  SelectInterestsModal,
  ShareModal,
} from '@/common/components/modals';
const UserSurveyModal = dynamic(
  () =>
    import('@/common/components/modals/UserSurveyModal').then(
      ({ UserSurveyModal }) => UserSurveyModal
    ),
  { ssr: false }
);

import styles from '@/common/styles/layout/header.module.scss';
import { MODAL_KEY } from '@/common/store/slices/modal/modalSlice';
import {
  NonLoginUserModal,
  NonSelectedInterestModal,
  LoginModal,
} from '@/common/components/modals';
import { RootDispatch } from '@/common/store';
import { SHORTCUTS_KEY, COLLECT_KEY } from '@/common/store/slices/personal';
import {
  updateIsLogin,
  updateUserInfo,
  fetchUserInfo,
} from '@/common/store/slices/root';
import {
  DESIRE,
  PAINT,
  AURORA,
  HOPE,
  REST,
  PLANET,
  START,
  PLEASURE,
  LIFE,
  COOL,
  CLOUD,
  MODERN,
  FOCUS,
  SUNSET,
} from '@/common/constants';

import desireImg from '../../assets/images/bg/bg_desire.webp';
import paintImg from '../../assets/images/bg/bg_paint.webp';
import auroraImg from '../../assets/images/bg/bg_aurora.webp';
import hopeImg from '../../assets/images/bg/bg_hope.webp';
import restImg from '../../assets/images/bg/bg_rest.webp';
import planetImg from '../../assets/images/bg/bg_planet.webp';
import startImg from '../../assets/images/bg/bg_start.webp';
import pleasureImg from '../../assets/images/bg/bg_pleasure.webp';
import lifeImg from '../../assets/images/bg/bg_life.webp';
import coolImg from '../../assets/images/bg/bg_cool.webp';
import cloudImg from '../../assets/images/bg/bg_cloud.webp';
import modernImg from '../../assets/images/bg/bg_modern.webp';
import focusImg from '../../assets/images/bg/bg_focus.webp';
import sunsetImg from '../../assets/images/bg/bg_sunset.webp';

export const BG_SRCS = {
  [DESIRE]: desireImg.src,
  [PAINT]: paintImg.src,
  [AURORA]: auroraImg.src,
  [HOPE]: hopeImg.src,
  [REST]: restImg.src,
  [PLANET]: planetImg.src,
  [START]: startImg.src,
  [PLEASURE]: pleasureImg.src,
  [LIFE]: lifeImg.src,
  [COOL]: coolImg.src,
  [CLOUD]: cloudImg.src,
  [MODERN]: modernImg.src,
  [FOCUS]: focusImg.src,
  [SUNSET]: sunsetImg.src,
};

/**
 * header 섹션 컴포넌트
 * @returns
 */
export const Header: FC = () => {
  const dispatch = useDispatch<RootDispatch>();
  const { isLogin, atNotSeenForWeek } = useRootSelector(
    (state) => state.rootSlice
  );

  const modalState = useRootSelector(
    (state) => state.modalSlice[MODAL_KEY].modals
  );
  const collectState = useRootSelector(
    (state) => state.personalSlice[COLLECT_KEY]
  );
  const allInterestsNotSelected = Object.entries(
    collectState?.interests || {}
  ).every(([, value]) => !value);
  const shortcutsState = useRootSelector(
    (state) => state.personalSlice[SHORTCUTS_KEY]
  );
  const wallpapers = useRootSelector(
    (state) => state.personalSlice.options.origin.wallpaperOption.wallpapers
  );
  const [isUpdateUserInfo, setIsUpdateUserInfo] = useState(false);
  const isInitialized = useIsInitialized();
  const { isWeekPassed, currentTimestamp } = useIsWeekPassed({
    atNotSeenForWeek,
    isInitialized,
  });
  const currentWallpaper = wallpapers.find((wallpaper) => wallpaper.isSelected);

  useEffect(() => {
    const updateUserInfos = async () => {
      await dispatch(fetchUserInfo());
      dispatch(updateIsLogin());
    };

    updateUserInfos().then(() => {
      setIsUpdateUserInfo(true);
    });
  }, [dispatch]);

  useUserStates({ isLogin, isUpdateUserInfo });

  useLocalStorage({
    isUpdateUserInfo,
  });

  // 옵션 탭 visible
  const [visibleTab, setVisibleTab] = useState('');

  const checkShortcutsLength = useCallback(() => {
    if (shortcutsState.shortcuts.length <= 9) return 'has_no_paging';

    return '';
  }, [shortcutsState]);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={`${styles.container} ${checkShortcutsLength()}`}>
          <div className={styles.bg_wrap}>
            {currentWallpaper && (
              <Image
                src={BG_SRCS[currentWallpaper?.name]}
                priority={true}
                alt="배경화면"
                fill
                sizes="100vw"
              />
            )}
          </div>

          <div className={styles.common_info}>
            <HeaderWeather />
            <HeaderClock />
          </div>

          <div className={styles.options}>
            <HeaderCollectTab setVisibleTab={setVisibleTab} />
            <HeaderAlarmTab
              visibleTab={visibleTab}
              setVisibleTab={setVisibleTab}
            />
            <HeaderLoginTab
              visibleTab={visibleTab}
              setVisibleTab={setVisibleTab}
            />
            <HeaderOptionTab setVisibleTab={setVisibleTab} />
          </div>
          <div className={styles.inner}>
            <HeaderSearchContainer />
            <HeaderShortcutsContainer />
          </div>
        </div>

        <HeaderGnb />
      </div>
      {!isLogin &&
        isInitialized &&
        isWeekPassed &&
        modalState.loginModal.isOn && (
          <LoginModal currentTimestamp={currentTimestamp} />
        )}

      {/* 선택된 관심사가 없을 시 노출되는 모달 */}
      {isLogin &&
        allInterestsNotSelected &&
        modalState.selectInterestModal.isOn && <SelectInterestsModal />}

      {/* 1개 이상 관심사를 선택하지 않으려하면 노출되는 모달 */}
      {modalState.nonSelectedInterestModal.isOn && <NonSelectedInterestModal />}

      {/* 공유하기 모달 */}
      {modalState.shareModal.isOn && <ShareModal />}

      {/* 사이트 편집 모달 */}
      {!!modalState.editSiteModal.isOn && <EditSiteModal />}

      {/* 사이트 폴더 모달 */}
      {!!modalState.folderModal.isOn && <FolderModal />}

      {/* 옵션 설정 모달 */}
      {!!modalState.optionsModal.isOn && <OptionsModal />}

      {/* 비로그인 유저가 로그인 기능 사용 시 노출시키는 모달 */}
      {!!modalState.nonLoginUserModal.isOn && <NonLoginUserModal />}

      {/* 사용자 조사 프로모션 */}
      {!!modalState.userSurveyModal.isOn && <UserSurveyModal />}
    </>
  );
};
