import { useState, useEffect } from 'react';

import { useRootSelector } from './useRootSelector';
import {
  CollectInterests,
  CollectTopicContent,
  COLLECT_KEY,
} from '@/common/store/slices/personal';
import { ITEM_PER_PAGE_RANKING_NEWS } from '@/common/constants';

interface Props {
  news: CollectTopicContent[] | undefined;
}

export const useFilteredNews = ({ news }: Props) => {
  const [filteredNews, setFilteredNews] = useState<CollectTopicContent[]>([]);
  const interests = useRootSelector(
    (state) => state.personalSlice[COLLECT_KEY].interests
  );

  useEffect(() => {
    if (!news) return;

    if (
      interests['politics'] ||
      interests['society'] ||
      interests['economy'] ||
      interests['world'] ||
      interests['it'] ||
      interests['culture']
    ) {
      const filteredNews = news.filter(
        (article) =>
          interests[
            (article.subCategory || article.category) as keyof CollectInterests
          ]
      );
      const filteredRest = filteredNews.length % ITEM_PER_PAGE_RANKING_NEWS;

      setFilteredNews(
        filteredNews.slice(0, filteredNews.length - filteredRest)
      );
    } else {
      const rest = news.length % ITEM_PER_PAGE_RANKING_NEWS;

      setFilteredNews(news.slice(0, news.length - rest));
    }
  }, [news, interests]);

  return filteredNews;
};
