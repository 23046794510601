import React, { ChangeEvent, FC, memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useRootSelector, useTargetSite } from '@/common/hooks';
import { toggleModal, MODAL_KEY } from '@/common/store/slices/modal/modalSlice';
import {
  editSite,
  SHORTCUTS_KEY,
  updateShortcutsState,
  fetchPersonalState,
} from '@/common/store/slices/personal';
import styles from '@/common/styles/components/modal.module.scss';
import { SITE } from '@/common/constants';
import { ClickStatsWrapper } from '../hoc';
import { convertToProperUrl, validateSiteInput } from '@/common/utils';

/**
 * 바로가기 사이트 편집 시 사용하는 모달
 */
export const EditSiteModal: FC = memo(() => {
  const dispatch = useDispatch();
  const shortcuts = useRootSelector(
    (state) => state.personalSlice[SHORTCUTS_KEY].shortcuts
  );
  const targetSiteUrl = useRootSelector(
    (state) => state.modalSlice[MODAL_KEY].modals.editSiteModal.targetId
  );
  const targetSite = useTargetSite(shortcuts, targetSiteUrl);

  const [name, setName] = useState(targetSite ? targetSite.name : '');
  const [url, setUrl] = useState(targetSite ? targetSite.url : '');

  const changeName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);

  const changeUrl = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  }, []);

  const cancelClick = useCallback(() => {
    dispatch(
      toggleModal({
        modal: 'editSiteModal',
      })
    );
  }, [dispatch]);

  const confirmClick = useCallback(async () => {
    if (!targetSite) return;

    if (!validateSiteInput(name, url)) return;

    if (targetSite.type === SITE && name.trim() && url.trim()) {
      dispatch(
        editSite({
          site: {
            name: targetSite.name,
            url: targetSite.url,
          },
          changes: {
            name,
            url: convertToProperUrl(url),
          },
        })
      );
    }

    dispatch(
      toggleModal({
        modal: 'editSiteModal',
      })
    );

    await dispatch(updateShortcutsState());
    await dispatch(fetchPersonalState());
  }, [name, url, targetSite, dispatch]);

  return (
    <>
      <div className={styles.edit_site_modal}>
        <div className="inner">
          <h3>사이트 편집</h3>
          <div className={styles.edit_site_module}>
            <ClickStatsWrapper
              event="@Click"
              properties={{
                cm: 'edit_typing',
                pos: 'start_site',
              }}
            >
              <span className="input_line">
                <strong>이름</strong>
                <input
                  type="text"
                  placeholder="이름"
                  onChange={changeName}
                  value={name}
                />
              </span>
            </ClickStatsWrapper>

            <ClickStatsWrapper
              event="@Click"
              properties={{ pos: 'start_site', cm: 'edit_url' }}
            >
              <span className="input_line">
                <strong>URL</strong>
                <input
                  type="text"
                  placeholder="URL (예시) http://zum.com"
                  onChange={changeUrl}
                  value={url}
                />
              </span>
            </ClickStatsWrapper>
          </div>
          <div className={`${styles.btn_bottom} ${styles.btn_col_2}`}>
            <ClickStatsWrapper
              event="@Click"
              properties={{ pos: 'start_site', cm: 'edit_no' }}
            >
              <button type="button" onClick={cancelClick}>
                취소
              </button>
            </ClickStatsWrapper>

            <ClickStatsWrapper
              event="@Click"
              properties={{ pos: 'start_site', cm: 'edit_yes' }}
            >
              <button type="button" onClick={confirmClick}>
                저장
              </button>
            </ClickStatsWrapper>
          </div>
        </div>
        <ClickStatsWrapper
          event="@Click"
          properties={{
            pos: 'start_site',
            cm: 'edit_close',
          }}
        >
          <button
            type="button"
            className={styles.btn_modal_close}
            onClick={cancelClick}
          >
            닫기
          </button>
        </ClickStatsWrapper>
      </div>

      <div className={styles.modal_bg}></div>
    </>
  );
});
