import * as React from 'react';

export const useInterval = (callback: any, delay: number) => {
  const savedCallback = React.useRef<typeof callback>();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    const tick = () => {
      if (savedCallback.current) savedCallback.current();
    };

    if (delay === 0 || delay) {
      const id = setInterval(tick, delay);

      return () => clearInterval(id);
    }

    return;
  }, [delay]);
};
