import * as React from 'react';
import { useDispatch } from 'react-redux';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
} from 'react-beautiful-dnd';

import {
  Shortcuts,
  reorderShortcuts,
  combineSites,
  addSiteToFolder,
  updateShortcutsState,
  fetchPersonalState,
  OPTIONS_KEY,
} from '@/common/store/slices/personal';
import { ShortcutFolderItem } from './ShortcutFolderItem';
import { ShortcutsSiteItem } from './ShortcutsSiteItem';
import { throttle, getChunks } from '@/common/utils';
import {
  ITEM_PER_LIST,
  THROTTLE_DELAY,
  PAGE_MOVE_DISTANCE,
  FOLDER,
  SITE,
} from '@/common/constants';
import styles from '@/common/styles/components/links.module.scss';
import { useRootSelector } from '@/common/hooks';
import { toggleModal } from '@/common/store/slices/modal/modalSlice';
import { toggleIsAddSiteMode } from '@/common/store/slices/root/rootSlice';
import { statisticsService } from '@/common/services';

interface Props {
  items: Shortcuts[];
}

const getItemStyle = <
  T extends DraggableStateSnapshot,
  U extends DraggableProvided
>(
  snapshot: T,
  provided: U
): React.CSSProperties => {
  if (!snapshot.isDropAnimating) {
    return {
      userSelect: 'none',

      ...provided.draggableProps.style,
    };
  }

  return { ...provided.draggableProps.style, transitionDuration: `0.001s` };
};

/**
 * 헤더 바로가기 영역 컴포넌트
 * @returns
 */
export const HeaderShortcuts: React.FC<Props> = ({ items }) => {
  const dispatch = useDispatch();
  const optionsTemporary = useRootSelector(
    (state) => state.personalSlice.options.temporary
  );
  const optionsOrigin = useRootSelector(
    (state) => state.personalSlice.options.origin
  );
  const [DOMIsReady, setDOMIsReady] = React.useState(false);
  const [currentScrollIndex, setCurrentScrollIndex] = React.useState(0);
  const chunks = getChunks(items, ITEM_PER_LIST);
  const scrollRef = React.useRef<HTMLDivElement>(null);

  const addButtonClick = React.useCallback(() => {
    statisticsService.send('@Click', {
      pos: 'start_site',
      cm: 'add',
    });

    dispatch(toggleIsAddSiteMode());
    dispatch(toggleModal({ modal: 'optionsModal' }));
  }, [dispatch]);

  const renderShortcuts = React.useCallback((item: Shortcuts) => {
    if (item.type === FOLDER)
      return <ShortcutFolderItem item={item} key={item.name} />;

    return <ShortcutsSiteItem item={item} key={item.url} />;
  }, []);

  const renderDroppables = (chunks: Shortcuts[][]) => {
    return chunks.map((chunk, chunkIndex) => (
      <Droppable
        droppableId={`droppable-${chunkIndex}`}
        direction="horizontal"
        isCombineEnabled
        key={chunkIndex}
      >
        {(provided: DroppableProvided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {chunk.map((item, itemIndex) => {
              const key =
                item.type === SITE
                  ? `${item.type}-${item.name}-${item.url}`
                  : `${item.type}-${item.name}`;

              return (
                <Draggable key={key} draggableId={key} index={itemIndex}>
                  {(
                    provided: DraggableProvided,
                    snapshot: DraggableStateSnapshot
                  ) => (
                    <div
                      className={`${styles.slot} icon_round_type_${
                        optionsOrigin.widget.iconBorderRadiusRate !==
                        optionsTemporary.widget.iconBorderRadiusRate
                          ? optionsTemporary.widget.iconBorderRadiusRate / 25 +
                            1
                          : optionsOrigin.widget.iconBorderRadiusRate / 25 + 1
                      }`}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot, provided)}
                    >
                      {renderShortcuts(item)}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
            <button
              type="button"
              className={styles.btn_add_item}
              onClick={addButtonClick}
            >
              추가
            </button>
          </div>
        )}
      </Droppable>
    ));
  };

  const paging = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const { dataset } = event.target as HTMLElement;
      const nextScrollIndex = Number(dataset.pageIndex);

      if (nextScrollIndex === currentScrollIndex) return;

      setCurrentScrollIndex(nextScrollIndex);

      statisticsService.send('@Click', {
        pos: 'start_site',
        cm: 'page',
      });
    },
    [currentScrollIndex]
  );

  const renderPagingButtons = React.useCallback(() => {
    return Array.from({
      length: Math.ceil(items.length / ITEM_PER_LIST),
    }).map((item: any, index) => (
      <button
        type="button"
        className={index === currentScrollIndex ? `${styles.btn_active}` : ''}
        key={index}
        data-page-index={index}
        onClick={paging}
      />
    ));
  }, [currentScrollIndex, items, paging]);

  const combineItems = React.useCallback(
    (result: DropResult) => {
      if (!result.combine) return;

      const [combinedType, combinedName, combinedUrl] =
        result.draggableId.split('-');
      const [targetType, targetName, targetUrl] =
        result.combine.draggableId.split('-');

      if (targetType === SITE && combinedType === SITE) {
        dispatch(
          combineSites({
            sourceItem: {
              type: SITE,
              name: targetName,
              url: targetUrl,
            },
            destinationItem: {
              type: SITE,
              name: combinedName,
              url: combinedUrl,
            },
          })
        );
      }

      if (targetType === FOLDER && combinedType === SITE) {
        dispatch(
          addSiteToFolder({
            folder: {
              type: FOLDER,
              name: targetName,
            },
            site: {
              type: SITE,
              name: combinedName,
              url: combinedUrl,
            },
          })
        );
      }
    },
    [dispatch]
  );

  const reorder = React.useCallback(
    (result: DropResult) => {
      const { source, destination } = result;

      if (!destination) return false;

      const sourceDroppableId = source.droppableId.split('-')[1];
      const destinationDroppableId = destination.droppableId.split('-')[1];
      const startIndex = source.index + Number(sourceDroppableId) * 9;
      const endIndex = destination.index + Number(destinationDroppableId) * 9;

      if (startIndex === endIndex) return false;

      dispatch(
        reorderShortcuts({
          startIndex,
          endIndex,
        })
      );

      return true;
    },
    [dispatch]
  );

  const dragEnd = React.useCallback(
    async (result: DropResult) => {
      if (result.combine) combineItems(result);
      else reorder(result);

      await dispatch(updateShortcutsState());
      await dispatch(fetchPersonalState());

      statisticsService.send('@IconMove', {
        pos: 'start_site',
        cm: SITE,
      });
    },
    [dispatch, combineItems, reorder]
  );

  const handleOnWheel = React.useCallback(
    (event: React.WheelEvent<HTMLDivElement>) => {
      // 아래로 스크롤 시

      if (event.deltaY > 0) {
        if (currentScrollIndex < Math.ceil(items.length / ITEM_PER_LIST) - 1) {
          setCurrentScrollIndex(currentScrollIndex + 1);

          return;
        }

        setCurrentScrollIndex(0);
      } else {
        if (currentScrollIndex > 0) {
          setCurrentScrollIndex(currentScrollIndex - 1);

          return;
        }

        setCurrentScrollIndex(Math.ceil(items.length / ITEM_PER_LIST) - 1);
      }
    },
    [currentScrollIndex, items]
  );

  const throttledHandleOnWheel = throttle(handleOnWheel, THROTTLE_DELAY);

  React.useEffect(() => {
    setDOMIsReady(true);
  }, []);

  React.useEffect(() => {
    if (
      items.length % ITEM_PER_LIST === 0 &&
      items.length / ITEM_PER_LIST - 1 < currentScrollIndex
    ) {
      setCurrentScrollIndex(currentScrollIndex - 1);

      return;
    }

    if (scrollRef.current) {
      const nextScrollOffset = currentScrollIndex * PAGE_MOVE_DISTANCE;

      scrollRef.current.scrollTo({ top: nextScrollOffset });
    }
  }, [scrollRef, currentScrollIndex, items]);

  return (
    <>
      <div
        className={styles.item_list}
        onWheel={throttledHandleOnWheel}
        ref={scrollRef}
      >
        {DOMIsReady && (
          <DragDropContext onDragEnd={dragEnd} autoScroll={false}>
            {renderDroppables(chunks)}
          </DragDropContext>
        )}
      </div>
      <div className={styles.slide_page}>{renderPagingButtons()}</div>
    </>
  );
};
