import React, { FC, useState } from 'react';

import { IssueKeywordItem } from './IssueKeywordItem';
import styles from '@/common/styles/components/issue-keyword.module.scss';
import theme from '@/common/styles/theme/box.module.scss';
import { useHomeData, useInterval } from '@/common/hooks';
import { ROLLING_DELAY, ROLLING_LIMIT } from '@/common/constants';

/**
 * 헤더 내 실시간 검색어 순위 컴포넌트
 * @returns
 */

export const HeaderIssueKeyword: FC = () => {
  const { data, isLoading, isError } = useHomeData();
  const [keywordIndex, setKeywordIndex] = useState(0);
  const [isShowKeywords, setIsShowKeywords] = useState(false);

  useInterval(() => {
    if (!(data && data.issueWords)) return;

    setKeywordIndex(
      (prev) =>
        (prev + 1) % Math.min(data?.issueWords.items.length, ROLLING_LIMIT)
    );
  }, ROLLING_DELAY);

  if (isLoading) {
    return <></>;
  }

  if (isError) {
    return <></>;
  }

  return (
    <div
      className={styles.wrap}
      onMouseEnter={() => {
        setIsShowKeywords(true);
      }}
      onMouseLeave={() => {
        setIsShowKeywords(false);
      }}
    >
      <div className={`${styles.list_wrap} ${theme.box_common}`}>
        <strong className="title">이슈검색어</strong>
        <ul>
          {isShowKeywords
            ? data?.issueWords.items.slice(0, 10).map((item, index) => (
                <li key={index}>
                  <IssueKeywordItem rank={index} item={item} />
                </li>
              ))
            : !!(data && data.issueWords) && (
                <li>
                  <IssueKeywordItem
                    rank={keywordIndex}
                    item={data?.issueWords.items[keywordIndex]}
                  />
                </li>
              )}
        </ul>
      </div>
    </div>
  );
};
