import { useEffect } from 'react';

import { Storage } from '@/common/modules';
import {
  COLLECT_KEY,
  OPTIONS_KEY,
  SHORTCUTS_KEY,
} from '@/common/store/slices/personal';
import { useRootSelector } from './useRootSelector';
import { ROOT_KEY } from '@/common/store/slices/root/rootSlice';

interface Props {
  isUpdateUserInfo: boolean;
}

export const useLocalStorage = ({ isUpdateUserInfo }: Props) => {
  const isLogin = useRootSelector((state) => state.rootSlice.isLogin);
  const collectState = useRootSelector(
    (state) => state.personalSlice[COLLECT_KEY]
  );
  const shortcutsState = useRootSelector(
    (state) => state.personalSlice[SHORTCUTS_KEY]
  );
  const optionsState = useRootSelector((state) => state.personalSlice.options);
  const rootState = useRootSelector((state) => state.rootSlice);

  useEffect(() => {
    if (!isLogin && isUpdateUserInfo) {
      const { isLoading, isError, ...excepted } = collectState;

      Storage.setData(COLLECT_KEY, excepted);
    }
  }, [isLogin, isUpdateUserInfo, collectState]);

  useEffect(() => {
    if (!isLogin && isUpdateUserInfo)
      Storage.setData(OPTIONS_KEY, { origin: optionsState.origin });
  }, [isLogin, isUpdateUserInfo, optionsState]);

  useEffect(() => {
    if (!isLogin && isUpdateUserInfo) {
      const { isLoading, isError, ...excepted } = shortcutsState;

      Storage.setData(SHORTCUTS_KEY, excepted);
    }
  }, [isLogin, isUpdateUserInfo, shortcutsState]);

  useEffect(() => {
    const { atNotSeenForWeek } = rootState;

    if (!isLogin && isUpdateUserInfo)
      Storage.setData(ROOT_KEY, {
        ...Storage.getData(ROOT_KEY),
        atNotSeenForWeek,
      });
  }, [isLogin, isUpdateUserInfo, rootState]);
};
