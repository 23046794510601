import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FolderSite } from '@/common/components/modals/folder/FolderSite';
import { ShortcutsSite } from '@/common/store/slices/personal';

interface Props {
  item: ShortcutsSite;
}

/**
 * 폴더 내에서 item을 sortable하게 만드는 래퍼
 */
export const FolderSortableItem: React.FC<Props> = ({ item }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.url, data: item });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className="slot">
        <FolderSite item={item} />
      </div>
    </div>
  );
};
