import * as React from 'react';

import styles from '@/common/styles/components/modal.module.scss';
import { useDispatch } from 'react-redux';
import { toggleModal } from '@/common/store/slices/modal/modalSlice';

/**
 *
 * @returns
 */
export const ShareModal: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const closeModal = React.useCallback(() => {
    dispatch(
      toggleModal({
        modal: 'shareModal',
      })
    );
  }, [dispatch]);

  return (
    <>
      <div className={styles.common_modal}>
        <div className="inner">
          <div className={`${styles.icon} noti`}></div>
          <div className={styles.text}>콘텐츠 링크를 복사하였습니다!</div>
          <div className={`${styles.btn_bottom} full`}>
            <button type="button" onClick={closeModal}>
              확인
            </button>
          </div>
        </div>
      </div>

      <div className={styles.modal_bg} onClick={closeModal}></div>
    </>
  );
});
