import { useQuery } from 'react-query';

import { externalApiService } from '@/common/services';
import { HomeDataResponse } from '@/common/domain/home/HomeDataResponse';
import { ReactQueryHooksReturn } from './ReactQueryHooksReturn';

import { HOME_DATA_KEY, TIMES } from '@/common/constants';

/**
 * 공통 데이터를 가져오는 Hooks
 * CSR시 컴포넌트 내에서 사용
 * @returns {ReactQueryHooksReturn<HomeDataResponse | undefined>}
 */
export const useHomeData = (): ReactQueryHooksReturn<
  HomeDataResponse | undefined
> => {
  const { data, isError, isLoading } = useQuery(
    HOME_DATA_KEY,
    externalApiService.fetchHomeData,
    {
      staleTime: TIMES.MINUTE_BY_MS * 10,
    }
  );

  return { data, isError, isLoading };
};
