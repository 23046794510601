import { useEffect } from 'react';

export const useFixedHeader = () => {
  useEffect(() => {
    document.addEventListener('wheel', (event: WheelEvent) => {
      const target = event.target as HTMLElement;

      if (target.closest('div[class*="links_wrapper"], div[class*="modal"]'))
        return;

      if (event.deltaY > 0) {
        document.querySelector('body')?.classList.add('header_fixed');
        return;
      }

      const scrollY =
        'scrollY' in window
          ? window.scrollY
          : document.documentElement.scrollTop;

      if (event.deltaY <= 0 && scrollY <= 0) {
        document.querySelector('body')?.classList.remove('header_fixed');
      }
    });
  });
};
