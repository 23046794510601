import React, { memo, useCallback, FC } from 'react';
import { useDispatch } from 'react-redux';

import { getLoginUrl, LOGIN_URL } from '@/common/utils';
import styles from '@/common/styles/components/modal.module.scss';
import { toggleModal } from '@/common/store/slices/modal/modalSlice';
import { ClickStatsWrapper } from '../hoc';
import { updateAtNotSeenForWeek } from '@/common/store/slices/root/rootSlice';

const icons: Icons[] = [
  { type: 'ZUM', label: 'ZUM' },
  { type: 'FACEBOOK', label: 'Facebook' },
  { type: 'APPLE', label: 'Apple' },
  { type: 'KAKAO', label: 'Kakao' },
];

interface Icons {
  type: keyof typeof LOGIN_URL;
  label: string;
}

interface Props {
  currentTimestamp: number;
}

/**
 * 첫 접속시 노출되는 로그인 팝업 컴포넌트
 * @returns
 */
export const LoginModal: FC<Props> = memo(({ currentTimestamp }: Props) => {
  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    dispatch(toggleModal({ modal: 'loginModal' }));
  }, [dispatch]);

  return (
    <>
      <div className={styles.login_first}>
        <div className="inner">
          <h2>스타트줌을 시작해보세요!</h2>
          <p className="text">
            간편하게 스타트줌 로그인하고 나만의 관심 콘텐츠를 받아보세요.
            <br />
            매일 새로운 콘텐츠가 &lsquo;내 피드&rsquo;에서 펼쳐집니다.
          </p>
          <ul className="login_type">
            {icons.map(({ type, label }) => (
              <li key={type}>
                <ClickStatsWrapper
                  event="@Click"
                  properties={{
                    pos: 'start_identity',
                    cm: label.toLowerCase(),
                  }}
                >
                  <a
                    href={getLoginUrl(type, location.href)}
                    className={type.toLowerCase()}
                  >
                    {label}
                  </a>
                </ClickStatsWrapper>
              </li>
            ))}
          </ul>
          <div className="help">
            로그인은{' '}
            <a href="https://policy.zum.com/info" target={'_blank'}>
              개인 정보 보호 정책
            </a>{' '}
            및{' '}
            <a href="https://policy.zum.com/terms" target={'_blank'}>
              서비스 약관
            </a>
            에 동의 하는 것을 의미하며,
            <br />
            서비스 이용을 위해 이메일과 이름, 프로필 이미지를 수집합니다.
          </div>
          <div className="hide_modal">
            <span className="check_box">
              <input
                type="checkbox"
                id="checkHideModal"
                onClick={() => {
                  console.log(currentTimestamp, 'curr');
                  if (currentTimestamp)
                    dispatch(updateAtNotSeenForWeek(currentTimestamp));

                  dispatch(toggleModal({ modal: 'loginModal' }));
                }}
              />
              <label htmlFor="checkHideModal">일주일간 보지 않기</label>
            </span>
          </div>
        </div>
        <ClickStatsWrapper
          event="@Click"
          properties={{
            pos: 'start_identity',
            cm: 'login_close',
          }}
        >
          <button
            type="button"
            className={styles.btn_modal_close}
            onClick={closeModal}
          >
            닫기
          </button>
        </ClickStatsWrapper>
      </div>

      <div className={styles.modal_bg}></div>
    </>
  );
});
