import React, {
  KeyboardEvent,
  EventHandler,
  FC,
  memo,
  useCallback,
  useState,
  ChangeEvent,
  useEffect,
} from 'react';

import { SearchSuggestList } from './SearchSuggestList';
import searchStyle from '@/common/styles/components/search.module.scss';
import suggestStyle from '@/common/styles/components/search-suggest.module.scss';
import themeStyle from '@/common/styles/theme/box.module.scss';
import { ENTER, REDIRECT_QM } from '@/common/constants';
import { statisticsService } from '@/common/services';

/**
 * 헤더 내 검색창 컴포넌트
 * @returns
 */
export const HeaderSearchBox: FC = memo(() => {
  const [keyword, setKeyword] = useState('');
  const [isFocus, setIsFocus] = useState(false);
  const [isOverSearchbox, setIsOverSearchbox] = useState(false);

  useEffect(() => {
    document.querySelector('body')?.addEventListener('click', () => {
      const target = document.querySelector('.search_over');
      if (!target) {
        setIsFocus(false);
      }
    });
  }, []);

  const enterSearchbox = useCallback(() => {
    setIsOverSearchbox(true);
  }, []);
  const leaveSearchbox = useCallback(() => {
    setIsOverSearchbox(false);
  }, []);

  const toggleSearchBox = useCallback(() => {
    setIsFocus(true);
  }, []);

  const search = useCallback(() => {
    if (keyword.trim() === '') return;

    let $link: HTMLAnchorElement | null = document.createElement('a');
    $link.href = `https://search.zum.com/search.zum?method=uni&option=accu&rd=1&query=${encodeURIComponent(
      keyword
    )}&qm=${REDIRECT_QM}`;
    $link.target = '_blank';
    $link.click();
    $link = null;

    setKeyword('');

    statisticsService.send('@SearchQuery', {
      query: keyword,
      newWin: true,
      eng: ['zum'],
    });
  }, [keyword]);

  const searchKeyEnter: EventHandler<KeyboardEvent> = useCallback(
    (event) => {
      if (event.key === ENTER) search();
    },
    [search]
  );

  const typeKeyword: EventHandler<ChangeEvent<HTMLInputElement>> = useCallback(
    (event) => setKeyword(event.target.value),
    []
  );

  return (
    <div
      className={`${searchStyle.box_wrap} ${
        isFocus ? searchStyle.input_on : ''
      } ${isOverSearchbox ? 'search_over' : ''}`}
      onMouseEnter={enterSearchbox}
      onMouseLeave={leaveSearchbox}
    >
      <div className="inner">
        <a
          className={searchStyle.zum_logo}
          href="https://zum.com/"
          target="_blank"
        >
          ZUM
        </a>
        <input
          type="search"
          placeholder="검색어를 입력하세요"
          className={`${searchStyle.input} ${themeStyle.box_common}`}
          value={keyword}
          onFocus={toggleSearchBox}
          onInput={typeKeyword}
          onKeyPress={searchKeyEnter}
        />
        <button type="submit" className={searchStyle.submit} onClick={search}>
          검색
        </button>

        <div className={suggestStyle.wrap}>
          {!!keyword && (
            <>
              <SearchSuggestList keyword={keyword} />
            </>
          )}
        </div>
      </div>
    </div>
  );
});
