import { SECOND_BY_MS } from '@/common/constants';
import { useState, useEffect } from 'react';

export const useIsInitialized = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsInitialized(true);
    }, SECOND_BY_MS);
  }, []);

  return isInitialized;
};
