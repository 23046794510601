import { SITE, FOLDER } from '@/common/constants';

export const SHORTCUTS_KEY = 'shortcuts';

export interface ShortcutsSite {
  type: typeof SITE;
  name: string;
  url: string;
}

export interface ShortcutsFolder {
  type: typeof FOLDER;
  name: string;
  children: ShortcutsSite[];
}

export type Shortcuts = ShortcutsSite | ShortcutsFolder;

export interface ShortcutsState {
  shortcuts: Shortcuts[];
  isLoading: boolean;
  isError: boolean;
}

export const shortcutsState: ShortcutsState = {
  shortcuts: [
    {
      type: SITE,
      name: '줌',
      url: 'https://zum.com/',
    },
    {
      type: FOLDER,
      name: 'SNS',
      children: [
        {
          type: SITE,
          name: '인스타그램',
          url: 'https://www.instagram.com/',
        },
        {
          type: SITE,
          name: '페이스북',
          url: 'https://www.facebook.com/',
        },
        {
          type: SITE,
          name: '트위터',
          url: 'https://twitter.com/',
        },
      ],
    },
    {
      type: FOLDER,
      name: '포털',
      children: [
        {
          type: SITE,
          name: '네이버',
          url: 'https://www.naver.com/',
        },
        {
          type: SITE,
          name: '다음',
          url: 'https://www.daum.net/',
        },
        {
          type: SITE,
          name: '구글',
          url: 'https://www.google.co.kr/',
        },
        {
          type: SITE,
          name: '네이트',
          url: 'https://www.nate.com/',
        },
      ],
    },
  ],

  isLoading: false,
  isError: false,
};
