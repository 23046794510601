import * as React from 'react';
import { useDispatch } from 'react-redux';

import { useRootSelector } from '@/common/hooks';
import {
  fetchPersonalState,
  OPTIONS_KEY,
  updateOptionsState,
  updateOriginWithTemporary,
  addSite,
  SHORTCUTS_KEY,
} from '@/common/store/slices/personal';
import { toggleModal } from '@/common/store/slices/modal/modalSlice';
import { Wallpapers, WidgetMode, Sites, Location } from './options';
import styles from '@/common/styles/components/options.module.scss';
import { SHORTCUT_LIMIT } from '@/common/constants';
import { Storage } from '@/common/modules';
import { toggleIsAddSiteMode } from '@/common/store/slices/root/rootSlice';
import { ClickStatsWrapper } from '@/common/components/hoc';

const tabs = [
  { name: '배경화면', code: 'bg' },
  { name: '위젯모드', code: 'widget' },
  { name: '사이트', code: 'site' },
  { name: '위치설정', code: 'location' },
];
// feed 탭을 위한 import

/**
 * 설정을 위한 모달 컴포넌트
 */

export const OptionsModal: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const isAddSiteMode = useRootSelector(
    (state) => state.rootSlice.isAddSiteMode
  );
  const optionsData = useRootSelector((state) => state.personalSlice.options);
  const shortcuts = useRootSelector(
    (state) => state.personalSlice[SHORTCUTS_KEY].shortcuts
  );
  const [tabIndex, setTabIndex] = React.useState(0);

  const selectTab: React.EventHandler<React.MouseEvent<HTMLButtonElement>> =
    React.useCallback((event) => {
      event.preventDefault();
      const { dataset } = event.target as HTMLElement;

      if (dataset.tabindex === undefined) return;

      setTabIndex(Number(dataset.tabindex));
    }, []);

  const complete = React.useCallback(async () => {
    optionsData.temporary.selectedSites.forEach((site) => {
      if (shortcuts.length === SHORTCUT_LIMIT) return;

      dispatch(
        addSite({
          type: 'site',
          name: site.title,
          url: site.url,
        })
      );
    });

    await dispatch(updateOptionsState());
    await dispatch(fetchPersonalState());

    if (isAddSiteMode) dispatch(toggleIsAddSiteMode());
    dispatch(toggleModal({ modal: 'optionsModal' }));
  }, [optionsData, shortcuts, isAddSiteMode, dispatch]);

  const closeModal = React.useCallback(() => {
    if (isAddSiteMode) dispatch(toggleIsAddSiteMode());
    dispatch(toggleModal({ modal: 'optionsModal' }));
  }, [isAddSiteMode, dispatch]);

  React.useEffect(() => {
    if (isAddSiteMode) setTabIndex(2);
  }, [isAddSiteMode]);

  return (
    <>
      <div className={styles.setting_modal}>
        <div className={styles.modal_tab}>
          <ul>
            {tabs.map((item, index) => (
              <li
                className={index === tabIndex ? 'selected' : ''}
                key={item.name}
              >
                <ClickStatsWrapper
                  event="@Click"
                  properties={{
                    pos: 'start_settings',
                    cm: 'tab',
                    selectTab: item.code,
                  }}
                >
                  <button
                    type="button"
                    onClick={selectTab}
                    data-tabindex={index}
                  >
                    {item.name}
                  </button>
                </ClickStatsWrapper>
              </li>
            ))}
          </ul>
        </div>
        <div className="inner">
          {tabIndex === 0 && <Wallpapers />}
          {tabIndex === 1 && <WidgetMode />}
          {tabIndex === 2 && <Sites />}
          {tabIndex === 3 && <Location />}
        </div>
        <div className={styles.modal_bottom}>
          <ClickStatsWrapper
            event="@Click"
            properties={{ pos: 'start_settings', cm: 'yes' }}
          >
            <button type="button" onClick={complete}>
              완료
            </button>
          </ClickStatsWrapper>
        </div>
      </div>
      <div className={styles.modal_bg} onClick={closeModal} />
    </>
  );
});
