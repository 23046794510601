import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { RootState } from '@/common/store';
import { PERSONAL_PATH } from '@/common/constants';
import { SHORTCUTS_KEY } from '../shortcuts';

const UPDATE_OPTIONS_STATE = 'options/updateOptionsState';

export const updateOptionsState = createAsyncThunk<
  any,
  void,
  { state: RootState }
>(UPDATE_OPTIONS_STATE, async (_args, { getState }) => {
  try {
    const { wallpaperOption, location, widget } =
      getState().personalSlice.options.temporary;
    const { shortcuts } = getState().personalSlice[SHORTCUTS_KEY];

    await axios.post(
      `/${PERSONAL_PATH}`,
      {
        setting: { wallpaperOption, location, widget, shortcuts },
      },
      {
        withCredentials: true,
      }
    );
  } catch (error) {
    console.log(error);
  }
});
