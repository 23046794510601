import { useEffect, useState } from 'react';

import { TopicContents, TopicContent } from '@/common/domain';
import { CollectState } from '@/common/store/slices/personal';
import { shuffle } from '@/common/utils';
import { useRootSelector } from './useRootSelector';
import { COLLECT_KEY } from '@/common/store/slices/personal';

interface Props {
  contents: TopicContents | undefined;
  domain: keyof TopicContents;
}

export const useFilteredContents = ({ contents, domain }: Props) => {
  const hiddenMedia = useRootSelector(
    (state) => state.personalSlice[COLLECT_KEY].hiddenMedia
  );
  const hiddenContents = useRootSelector(
    (state) => state.personalSlice[COLLECT_KEY].hiddenContents
  );
  const [filteredContents, setFilteredContents] = useState<TopicContent[]>([]);
  const [shuffledContents, setShuffledContents] = useState<TopicContent[]>([]);

  useEffect(() => {
    if (!contents) return;

    const shuffledContents = shuffle(contents[domain]);

    setShuffledContents(shuffledContents);
  }, [contents, domain]);

  useEffect(() => {
    if (!contents || !shuffledContents.length) return;

    const filteredContents = shuffledContents
      .filter(
        (content) =>
          !hiddenMedia.find((media) => media.origin === content.origin)
      )
      .filter(
        (content) =>
          !hiddenContents.find(
            (hiddenContent) => hiddenContent.link === content.link
          )
      );

    setFilteredContents(filteredContents);
  }, [
    hiddenMedia,
    hiddenContents,
    shuffledContents,
    contents,
    domain,
    setFilteredContents,
  ]);

  return filteredContents;
};
