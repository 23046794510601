import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '@/common/store';

import {
  COLLECT_KEY,
  CollectInterests,
  CollectTopicContent,
  CollectAlarm,
  CollectDeletedAlarm,
  CollectHiddenMedia,
} from './state';
import {
  CONTENT_SETTING_PATH,
  HIDDEN_CONTENTS_PATH,
  DELETE_ALARMS_PATH,
  HIDDEN_MEDIA_PATH,
  INTEREST_PATH,
  READ_CONTENTS_PATH,
  SAVE_CONTENTS_PATH,
  PERSONAL_PATH,
} from '@/common/constants';
import { ContentsDisplayType } from '@/common/domain/base';
import { toDeletedAtFormat } from '@/common/utils';

const TOGGLE_INTEREST_WITH_LOGIN = 'collect/toggleInterestWithLogin';
const SET_INTERESTS_WITH_LOGIN = 'collect/setInterestsWithLogin';
const ADD_SAVE_CONTENT = 'collect/addSaveContent';
const REMOVE_SAVE_CONTENT = 'collect/removeSaveContent';
const ADD_HIDDEN_MEDIA = 'collect/addHiddenMedia';
const REMOVE_HIDDEN_MEDIA = 'collect/removeHiddenMedia';
const ADD_HIDDEN_CONTENT = 'collect/addHiddenContent';
const REMOVE_HIDDEN_CONTENT = 'collect/removeHiddenContent';
const ADD_READ_CONTENT = 'collect/addReadContent';
const REMOVE_READ_CONTENT = 'collect/removeReadContent';
const SET_CONTENTS_DISPLAY_TYPE_WITH_LOGIN =
  'collect/setContentsDisplayTypeWithLogin';
const TOGGLE_IS_CONTENTSBOX_WITH_LOGIN = 'collect/toggleIsContentsBoxWithLogin';
const ADD_DELETE_ALARM = 'collect/addDeleteAlarm';
const ADD_DELETE_ALARMS = 'collect/addDeleteAlarms';

export const toggleInterestWithLogin = createAsyncThunk<
  any,
  keyof CollectInterests,
  { state: RootState }
>(TOGGLE_INTEREST_WITH_LOGIN, async (interest, { getState }) => {
  const { interests } = getState().personalSlice[COLLECT_KEY];
  const { auto, ...rest } = interests;

  await axios.post(
    `/${PERSONAL_PATH}/${INTEREST_PATH}`,
    {
      interests: {
        ...('auto' in interests ? rest : interests),
        [interest]: !interests[interest],
      },
    },
    {
      withCredentials: true,
    }
  );
});

export const setInterestsWithLogin = createAsyncThunk<
  any,
  CollectInterests,
  { state: RootState }
>(SET_INTERESTS_WITH_LOGIN, async (interests) => {
  await axios.post(
    `/${PERSONAL_PATH}/${INTEREST_PATH}`,
    {
      interests,
    },
    {
      withCredentials: true,
    }
  );
});

export const addSaveContent = createAsyncThunk<
  any,
  CollectTopicContent,
  { state: RootState }
>(ADD_SAVE_CONTENT, async (targetContent, { getState }) => {
  const { saveContents } = getState().personalSlice[COLLECT_KEY];

  const isDuplicated = saveContents.find(
    (saveContent) => saveContent.link === targetContent.link
  );

  if (isDuplicated) return; // 중복

  await axios.post(
    `/${PERSONAL_PATH}/${SAVE_CONTENTS_PATH}`,
    {
      contents: [...saveContents, targetContent],
    },
    {
      withCredentials: true,
    }
  );
});

export const removeSaveContent = createAsyncThunk<
  any,
  CollectTopicContent,
  { state: RootState }
>(REMOVE_SAVE_CONTENT, async (targetContent, { getState }) => {
  const { saveContents } = getState().personalSlice[COLLECT_KEY];

  const filteredSaveContents = saveContents.filter(
    (saveContent) => saveContent.link !== targetContent.link
  );

  await axios.post(
    `/${PERSONAL_PATH}/${SAVE_CONTENTS_PATH}`,
    {
      contents: filteredSaveContents,
    },
    {
      withCredentials: true,
    }
  );
});

export const addReadContent = createAsyncThunk<
  any,
  CollectTopicContent,
  { state: RootState }
>(ADD_READ_CONTENT, async (targetContent, { getState }) => {
  const { readContents } = getState().personalSlice[COLLECT_KEY];

  const isDuplicated = readContents.find(
    (readContent) => readContent.link === targetContent.link
  );

  if (isDuplicated) return; // 중복

  await axios.post(
    `/${PERSONAL_PATH}/${READ_CONTENTS_PATH}`,
    {
      contents: [...readContents, targetContent],
    },
    {
      withCredentials: true,
    }
  );
});

export const removeReadContent = createAsyncThunk<
  any,
  CollectTopicContent,
  { state: RootState }
>(REMOVE_READ_CONTENT, async (targetContent, { getState }) => {
  const { readContents } = getState().personalSlice[COLLECT_KEY];

  const filteredReadContent = readContents.filter(
    (readContent) => readContent.link !== targetContent.link
  );

  await axios.post(
    `/${PERSONAL_PATH}/${READ_CONTENTS_PATH}`,
    {
      contents: filteredReadContent,
    },
    {
      withCredentials: true,
    }
  );
});

export const addHiddenMedia = createAsyncThunk<
  any,
  CollectHiddenMedia,
  { state: RootState }
>(ADD_HIDDEN_MEDIA, async (targetMedia, { getState }) => {
  const hiddenMedia = { getState }.getState().personalSlice[COLLECT_KEY]
    .hiddenMedia;

  const isDuplicated = hiddenMedia.find(
    (media) => media.origin === targetMedia.origin
  );

  if (isDuplicated) return; // 중복

  await axios.post(
    `/${PERSONAL_PATH}/${HIDDEN_MEDIA_PATH}`,
    {
      media: [...hiddenMedia, targetMedia],
    },
    {
      withCredentials: true,
    }
  );
});

export const removeHiddenMedia = createAsyncThunk<
  any,
  CollectHiddenMedia,
  { state: RootState }
>(REMOVE_HIDDEN_MEDIA, async (targetMedia, { getState }) => {
  const { hiddenMedia } = getState().personalSlice[COLLECT_KEY];

  const filteredHiddenMedia = hiddenMedia.filter(
    (media) => media.origin !== targetMedia.origin
  );

  await axios.post(
    `/${PERSONAL_PATH}/${HIDDEN_MEDIA_PATH}`,
    {
      media: filteredHiddenMedia,
    },
    {
      withCredentials: true,
    }
  );
});

export const addHiddenContent = createAsyncThunk<
  any,
  CollectTopicContent,
  { state: RootState }
>(ADD_HIDDEN_CONTENT, async (targetContent, { getState }) => {
  const { hiddenContents } = getState().personalSlice[COLLECT_KEY];

  const isDuplicated = hiddenContents.find(
    (hiddenContent) => hiddenContent.link === targetContent.link
  );

  if (isDuplicated) return; // 중복

  await axios.post(
    `/${PERSONAL_PATH}/${HIDDEN_CONTENTS_PATH}`,
    {
      contents: [...hiddenContents, targetContent],
    },
    {
      withCredentials: true,
    }
  );
});

export const addDeleteAlarms = createAsyncThunk<
  any,
  CollectAlarm[],
  { state: RootState }
>(ADD_DELETE_ALARMS, async (targetAlarms, { getState }) => {
  const { deleteAlarms } = getState().personalSlice[COLLECT_KEY];

  const filteredTargetAlarms = targetAlarms.filter(
    (targetAlarm) =>
      !deleteAlarms.find((deleteAlarm) => deleteAlarm.link === targetAlarm.link)
  );

  if (!filteredTargetAlarms.length) return; // 중복

  const transformedTargetAlarms: CollectDeletedAlarm[] =
    filteredTargetAlarms.map((filteredTargetAlarm) => {
      return {
        link: filteredTargetAlarm.link,
        deletedAt: toDeletedAtFormat(new Date()),
      };
    });

  await axios.post(
    `/${PERSONAL_PATH}/${DELETE_ALARMS_PATH}`,
    {
      alarms: [...deleteAlarms, ...transformedTargetAlarms],
    },
    {
      withCredentials: true,
    }
  );
});

export const addDeleteAlarm = createAsyncThunk<
  any,
  CollectAlarm,
  { state: RootState }
>(ADD_DELETE_ALARM, async (targetAlarm, { getState }) => {
  const { deleteAlarms } = getState().personalSlice[COLLECT_KEY];

  const isDuplicated = deleteAlarms.find(
    (deleteAlarm) => deleteAlarm.link === targetAlarm.link
  );

  if (isDuplicated) return; // 중복

  const transformedTargetAlarm: CollectDeletedAlarm = {
    link: targetAlarm.link,
    deletedAt: toDeletedAtFormat(new Date()),
  };

  await axios.post(
    `/${PERSONAL_PATH}/${DELETE_ALARMS_PATH}`,
    {
      alarms: [...deleteAlarms, transformedTargetAlarm],
    },
    {
      withCredentials: true,
    }
  );
});

export const removeHiddenContent = createAsyncThunk<
  any,
  CollectTopicContent,
  { state: RootState }
>(REMOVE_HIDDEN_CONTENT, async (targetContent, { getState }) => {
  const { hiddenContents } = getState().personalSlice[COLLECT_KEY];

  const filteredHiddenContents = hiddenContents.filter(
    (hiddenContent) => hiddenContent.link !== targetContent.link
  );

  await axios.post(
    `/${PERSONAL_PATH}/${HIDDEN_CONTENTS_PATH}`,
    {
      contents: filteredHiddenContents,
    },
    {
      withCredentials: true,
    }
  );
});

export const setContentsDisplayTypeWithLogin = createAsyncThunk<
  any,
  ContentsDisplayType,
  { state: RootState }
>(
  SET_CONTENTS_DISPLAY_TYPE_WITH_LOGIN,
  async (contentsDisplayType, { getState }) => {
    const { options } = getState().personalSlice[COLLECT_KEY];

    await axios.post(
      `/${PERSONAL_PATH}/${CONTENT_SETTING_PATH}`,
      {
        options: { ...options, contentsDisplayType },
      },
      {
        withCredentials: true,
      }
    );
  }
);

export const toggleIsContentsBoxWithLogin = createAsyncThunk<
  any,
  void,
  { state: RootState }
>(TOGGLE_IS_CONTENTSBOX_WITH_LOGIN, async (_args, { getState }) => {
  const { options } = getState().personalSlice[COLLECT_KEY];

  await axios.post(
    `/${PERSONAL_PATH}/${CONTENT_SETTING_PATH}`,
    {
      options: { ...options, isContentsBox: !options.isContentsBox },
    },
    {
      withCredentials: true,
    }
  );
});
