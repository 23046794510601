import { useState, useEffect } from 'react';
import axios from 'axios';

import { PATHS } from '@/common/constants';

export interface UseTargetReturn {
  target: number | undefined;
}

export const useTarget = (): UseTargetReturn => {
  const [target, setTarget] = useState<number>();

  useEffect(() => {
    const fetchTarget = async () => {
      const { data } = await axios.get(PATHS.TARGET);

      setTarget(data);
    };

    fetchTarget();
  }, []);

  return { target };
};
