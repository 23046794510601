import axios from 'axios';
import fetchJsonp from 'fetch-jsonp';

import { SUGGEST_URL, PATHS } from '@/common/constants';
import { HomeDataResponse } from '@/common/domain/home/HomeDataResponse';

interface Entry {
  title: string;
}

interface Suggest {
  category: string;
  isDummy: boolean;
  weight: number;
  entry: Entry[];
}

export const externalApiService = {
  /**
   * 현재 검색어에 대한 추천 검색어 목록을 가져오는 함수
   */
  async fetchSuggests(keyword: string): Promise<Suggest[]> {
    return await fetchJsonp(
      `${SUGGEST_URL}&_t=${Date.now()}&query=${keyword}`
    ).then((response) => response.json());
  },

  /**
   * 공통 데이터를 받아오는 fetcher
   * CSR시 사용
   */
  async fetchHomeData(): Promise<HomeDataResponse> {
    const { data } = await axios.get(PATHS.HOME);

    return data;
  },
};
