import { useRootSelector } from './useRootSelector';
import { COLLECT_KEY, SHORTCUTS_KEY } from '@/common/store/slices/personal/';

export const useCollectLoading = () => {
  const isCollectLoading = useRootSelector(
    (state) => state.personalSlice[COLLECT_KEY].isLoading
  );
  const isCollectError = useRootSelector(
    (state) => state.personalSlice[COLLECT_KEY].isError
  );
  const isShortcutsLoading = useRootSelector(
    (state) => state.personalSlice[SHORTCUTS_KEY].isLoading
  );
  const isShortcutsError = useRootSelector(
    (state) => state.personalSlice[SHORTCUTS_KEY].isError
  );

  return {
    isLoading: isCollectLoading && isShortcutsLoading,
    isError: isCollectError && isShortcutsError,
  };
};
