import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { CHROME_EXTENSION, EXTENSION } from '@/common/constants';
import { Storage } from '@/common/modules/Storage';
import {
  SHORTCUTS_KEY,
  loadShortcutsState,
  shortcutsState,
} from '@/common/store/slices/personal';
import {
  transformSitesForMigrate,
  OldSite,
  checkOldSites,
} from '@/common/utils';

interface MesssageData {
  type: string;
  message: Omit<OldSite, 'icon'>[] | OldSite[] | null;
}

declare global {
  interface Window {
    chrome?: any;
  }
}

export const useMessage = () => {
  const dispatch = useDispatch();

  const topSiteMessage = () => {
    // TODO: 자주가는 사이트 로직 추가
  };

  const migrateSitesMessage = useCallback(
    async (site: OldSite[]) => {
      // 로컬스토리지에 저장된 사이트들이 default 세팅과 동일하지 않다면 중도 탈출
      if (
        JSON.stringify(Storage.getData(SHORTCUTS_KEY)?.shortcuts) !==
        JSON.stringify(shortcutsState[SHORTCUTS_KEY])
      )
        return;

      const transformedSites = transformSitesForMigrate(site);

      Storage.setData(SHORTCUTS_KEY, { shortcuts: transformedSites });

      dispatch(loadShortcutsState());
    },
    [dispatch]
  );

  const messageHandler = useCallback(
    (event: MessageEvent<MesssageData>) => {
      const { origin, data } = event;
      const { type, message } = data;

      if (origin.startsWith(CHROME_EXTENSION) && type === EXTENSION) {
        // topSiteMessage();

        if (message && checkOldSites(message)) migrateSitesMessage(message);
      }
    },
    [migrateSitesMessage]
  );

  useEffect(() => {
    window.addEventListener('message', messageHandler);

    if (typeof window.chrome !== 'undefined') {
      const { chrome } = window;
      const extensionIds = [
        'bghgeookcfdmkoocalbclnhofnenmhlf',
        'gaoojjpocldcbdndcnocepncaplpdjag',
      ];

      extensionIds.forEach((id) =>
        chrome.runtime?.sendMessage(
          id,
          {
            type: EXTENSION,
            message: {
              status: 'ready',
            },
          },
          function (response: unknown) {
            if (!Array.isArray(response) || !checkOldSites(response)) return;
            migrateSitesMessage(response);
          }
        )
      );
    }

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [migrateSitesMessage, messageHandler]);
};
