import { useEffect, useCallback } from 'react';

import { statisticsService } from '@/common/services';

export const usePageView = () => {
  const sendPageView = useCallback(() => {
    setTimeout(() => {
      if (
        window.ESTatTracker &&
        typeof window.ESTatTracker.push === 'function'
      ) {
        statisticsService.send('@PageView', {});
      } else sendPageView();
    }, 100);
  }, []);

  useEffect(() => {
    sendPageView();
  }, [sendPageView]);
};
