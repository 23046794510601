import React, { FC, memo, useCallback } from 'react';

import { UserModal } from '@/common/components/modals';
import styles from '@/common/styles/components/options.module.scss';
import { LOGIN_TAB } from '@/common/constants';

/**
 * 헤더 로그인 버튼을 누르면 노출되는 로그인 탭 컴포넌트
 * @returns
 */

interface Props {
  visibleTab: string;
  setVisibleTab: (index: string) => void;
}

export const HeaderLoginTab: FC<Props> = memo(
  ({ visibleTab, setVisibleTab }: Props) => {
    const isVisible = useCallback(() => visibleTab === LOGIN_TAB, [visibleTab]);
    const toggleVisible = useCallback(() => {
      if (isVisible()) return setVisibleTab('');

      setVisibleTab(LOGIN_TAB);
    }, [isVisible, setVisibleTab]);

    return (
      <>
        <div
          className={
            isVisible()
              ? `${styles.btn_item} ${styles.item_selected}`
              : `${styles.btn_item}`
          }
        >
          <button
            type="button"
            className={styles.btn_user}
            onClick={toggleVisible}
          >
            사용자 로그인
          </button>
          {isVisible() && <UserModal />}
        </div>
        {isVisible() && (
          <div className={styles.alarm_modal_bg} onClick={toggleVisible}></div>
        )}
      </>
    );
  }
);
