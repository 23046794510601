import React, { FC, useCallback } from 'react';

import { IssueWord } from '@/common/domain';
import { G_REAL1_START } from '@/common/constants';
import { ClickStatsWrapper } from '@/common/components/hoc';
import styles from '@/common/styles/components/issue-keyword.module.scss';

/**
 * 헤더 내 실시간 검색어 순위 개별 아이템 컴포넌트
 * @returns
 */

interface Props {
  rank: number;
  item: IssueWord;
}

export const IssueKeywordItem: FC<Props> = ({ rank, item }) => {
  const [keyword, upDown] = item;

  const renderUpDownIndicator = useCallback((upDown: number) => {
    if (upDown > 0) return <span className="state up">상승</span>;

    if (upDown < 0) return <span className="state down">하락</span>;

    return <span className="state same">동일</span>;
  }, []);

  return (
    <ClickStatsWrapper
      event="@Click"
      properties={{
        pos: 'start_keyword',
        cm: 'keyword',
      }}
    >
      <div className={styles.item}>
        <strong className="rank">{rank + 1}</strong>
        <a
          href={`https://search.zum.com/search.zum?method=uni&option=accu&query=${encodeURIComponent(
            keyword
          )}&qm=${G_REAL1_START}&real1_id=1`}
          className="text"
          target="_blank"
        >
          {keyword}
        </a>
        {renderUpDownIndicator(Number(upDown))}
      </div>
    </ClickStatsWrapper>
  );
};
