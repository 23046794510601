import * as React from 'react';
import {
  statisticsService,
  StatsInputData,
  eventType,
} from '@/common/services';

interface Props extends StatsInputData {
  readonly children: React.ReactNode;
  readonly event: eventType;
  readonly isOnScrollToBottom?: boolean;
  readonly properties?: Record<string, any>;
}

export const ScrollViewStatsWrapper: React.FC<Props> = ({
  children,
  event,
  scrollPos,
  isOnScrollToBottom,
}) => {
  const notifierRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!notifierRef.current) return;

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) return;

          if (
            isOnScrollToBottom &&
            (entry.intersectionRatio === 1 || entry.intersectionRatio === 0)
          )
            statisticsService.send('@ScrollToBottom', { scrollPos });
          else statisticsService.send(event, { scrollPos });

          if (
            notifierRef.current &&
            (!isOnScrollToBottom ||
              (isOnScrollToBottom && entry.intersectionRatio === 1))
          )
            observer.unobserve(notifierRef.current);
        });
      },
      isOnScrollToBottom
        ? {
            threshold: [0, 1],
          }
        : {}
    );

    observer.observe(notifierRef.current);
  }, [event, isOnScrollToBottom, scrollPos]);

  return (
    <div className="wrapper_block" ref={notifierRef}>
      {children}
    </div>
  );
};
