import React, { FC, useState, useEffect } from 'react';

import styles from '@/common/styles/components/clock.module.scss';
import { AM, NOON, PM } from '@/common/constants';

/**
 * 헤더 상단 왼쪽에 있는 시계 컴포넌트
 * @returns
 */
export const HeaderClock: FC = () => {
  const [date, setDate] = useState<Date>();

  useEffect(() => {
    setDate(new Date());

    let timer = window.setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    // 2022-04-27
    // ClickStatsWrapper 이슈
    // 내용 : 동일한 태그가 같은 class가 적용되거나 구조가 깨지는 현상
    // 수정방법 : btn_item 클래스가 적용되는 div -> span태그로 변경해서 임시 수정
    <span className={styles.info}>
      {!!date && (
        <span className="clock_text">{`${`0${date?.getHours()}`.slice(
          -2
        )}:${`0${date.getMinutes()}`.slice(-2)} ${
          date?.getHours() >= NOON ? PM : AM
        }`}</span>
      )}
    </span>
  );
};
