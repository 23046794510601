import 'es6-math';
import 'element-closest-polyfill';
import 'scroll-behavior-polyfill';
import 'intersection-observer';

if (typeof document !== 'undefined') {
  require('web-animations-js');
}

import React, { FC, useState } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import localFont from 'next/font/local';
import axios from 'axios';
import { QueryClient, QueryClientProvider, Hydrate } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { reduxWrapper } from '@/common/store';
import '@/common/styles/index.scss';
import {
  DEV_BFF_URL,
  LOCAL_BFF_URL,
  QA_BFF_URL,
  PRODUCTION_BFF_URL,
} from '@/common/constants';
import { useFixedHeader, useMessage } from '@/common/hooks';
import { Header } from '@/common/components/header';

//axios baseUrl을 설정
axios.defaults.baseURL =
  process.env.MODE === 'prod'
    ? PRODUCTION_BFF_URL
    : process.env.MODE === 'qa'
    ? QA_BFF_URL
    : process.env.MODE === 'prod-local'
    ? LOCAL_BFF_URL
    : DEV_BFF_URL;

if (process.env.MODE === 'dev' || process.env.MODE === 'prod-local') {
  require('../setupMock');
}

const font = localFont({
  src: [
    {
      path: '../../common/assets/fonts/malgun.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../common/assets/fonts/malgunbold.woff2',
      weight: '700',
      style: 'normal',
    },
  ],
});

const MyApp: FC<AppProps> = ({ Component, pageProps }) => {
  const [queryClient] = useState(() => new QueryClient());

  useMessage();
  useFixedHeader();

  return (
    <>
      <Head>
        <title>스타트줌</title>
      </Head>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <div className={font.className}>
            <Header />
            <main>
              <div className="content_wrapper">
                <Component {...pageProps} />
              </div>
            </main>
          </div>
        </Hydrate>
        {process.env.MODE !== 'prod' && process.env.MODE !== 'qa' && (
          <ReactQueryDevtools />
        )}
      </QueryClientProvider>
    </>
  );
};

export default reduxWrapper.withRedux(MyApp);
