import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const MODAL_SLICE = 'modalSlice';
export const MODAL_KEY = 'modal';

interface ModalInfo {
  isOn: boolean;
  targetId?: string;
}

interface ModalInfoWithTarget {
  isOn: boolean;
  targetId: string;
}

export interface ModalModals {
  selectInterestModal: ModalInfo;
  editSiteModal: ModalInfoWithTarget;
  loginModal: ModalInfo;
  nonLoginUserModal: ModalInfo;
  folderModal: ModalInfo;
  optionsModal: ModalInfo;
  nonSelectedInterestModal: ModalInfo;
  shareModal: ModalInfo;
  userSurveyModal: ModalInfo;
}

export interface ModalSliceState {
  [MODAL_KEY]: {
    modals: ModalModals;
  };
}

interface ToggleModalPayload {
  modal: keyof ModalModals;
  targetId?: string;
}

interface setModalPayload {
  modal: keyof ModalModals;
  onOff: 'on' | 'off';
}

const initialState: ModalSliceState = {
  [MODAL_KEY]: {
    modals: {
      selectInterestModal: { isOn: true },
      editSiteModal: { isOn: false, targetId: '' },
      loginModal: { isOn: false },
      nonLoginUserModal: { isOn: false },
      folderModal: { isOn: false, targetId: '' },
      optionsModal: { isOn: false },
      nonSelectedInterestModal: { isOn: false },
      shareModal: { isOn: false },
      userSurveyModal: { isOn: false },
    },
  },
};

const modalSlice = createSlice({
  name: MODAL_SLICE,
  initialState,
  reducers: {
    /**
     * 모달을 토글하여 선택, 선택 해제할 수 있는 리듀서
     */
    toggleModal: (state, action: PayloadAction<ToggleModalPayload>) => {
      const { modal, targetId } = action.payload;

      state[MODAL_KEY].modals[modal].isOn =
        !state[MODAL_KEY].modals[modal].isOn;

      if (targetId) state[MODAL_KEY].modals[modal].targetId = targetId;
      else state[MODAL_KEY].modals[modal].targetId = '';
    },

    setModal: (state, action: PayloadAction<setModalPayload>) => {
      const { modal, onOff } = action.payload;

      state[MODAL_KEY].modals[modal].isOn = onOff === 'on';
    },
  },
});

export const { toggleModal, setModal } = modalSlice.actions;

export default modalSlice;
