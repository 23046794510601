import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { RootSliceState } from './rootSlice';
import { fetchUserInfo } from './thunks';

export const rootExtraReducers = (
  builder: ActionReducerMapBuilder<RootSliceState>
) => {
  builder.addCase(fetchUserInfo.pending, (state) => {
    state.isLoading = true;
    state.isError = false;
  });

  builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
    state.userInfo = action.payload;

    state.isLoading = false;
    state.isError = false;
  });

  builder.addCase(fetchUserInfo.rejected, (state) => {
    state.isLoading = false;
    state.isError = true;
  });
};
