type StorageData = string | number | boolean | Object | Array<any>;
export class Storage {
  static getData(key: string) {
    const gottenData = localStorage.getItem(key);

    if (gottenData === null) return null;

    return JSON.parse(gottenData);
  }

  static setData(key: string, data: StorageData) {
    const stringifiedData = JSON.stringify(data);

    if (!stringifiedData) return;

    localStorage.setItem(key, stringifiedData);
  }

  static removeData(key: string) {
    try {
      if (key in localStorage) {
        localStorage.removeItem(key);
        return true;
      }
    } catch (e) {
      /* passthrough */
    }
    return false;
  }
}
