import React, { useState, useCallback, memo, FC } from 'react';
import { useDispatch } from 'react-redux';

import { Site, SiteCategory } from '@/common/components/modals/options/Sites';
import {
  addSiteToSelectedSites,
  removeSiteFromSelectedSites,
} from '@/common/store/slices/personal';
import { ClickStatsWrapper } from '@/common/components/hoc';
import styles from '@/common/styles/components/options.module.scss';
import { useRootSelector } from '@/common/hooks';

interface Props {
  siteCategory: SiteCategory;
}

/**
 * 옵션 모달 - 추천 사이트 목록
 * @returns
 */
export const RecommendedSiteCategory: FC<Props> = memo(
  ({ siteCategory }: Props) => {
    const dispatch = useDispatch();
    const selectedSites = useRootSelector(
      (state) => state.personalSlice.options.temporary.selectedSites
    );

    const checkIsSelectedSite = useCallback(
      (site: Site) => {
        return selectedSites.some(
          (selectedSite) => selectedSite.url === site.url
        );
      },
      [selectedSites]
    );

    const toggleSite = useCallback(
      (site: Site) => {
        if (checkIsSelectedSite(site)) {
          dispatch(
            removeSiteFromSelectedSites({
              targetSite: site,
            })
          );

          return;
        }

        dispatch(
          addSiteToSelectedSites({
            targetSite: site,
          })
        );
      },
      [dispatch, checkIsSelectedSite]
    );
    const [pageIndex, changePageIndex] = useState(0);
    const ITEM_PER_PAGE = 5;
    const maxPageIndex =
      Math.floor(siteCategory.sites.length / ITEM_PER_PAGE) +
      (Math.floor(siteCategory.sites.length % ITEM_PER_PAGE) > 1 ? 1 : 0) -
      1;

    return (
      <div className={styles.sec_recommended_items}>
        <strong className="title">{`${siteCategory.title[0]}
      ${siteCategory.title[1]}`}</strong>
        <div className={styles.items_container}>
          <div className="list_wrapper">
            <ul>
              {siteCategory.sites.map(
                (site, index) =>
                  Math.floor(index / ITEM_PER_PAGE) === pageIndex && (
                    <li
                      className={checkIsSelectedSite(site) ? 'selected' : ''}
                      key={site.url + site.title}
                    >
                      <ClickStatsWrapper
                        event="@Click"
                        pos="start_settings"
                        selectTab="site"
                        cm={siteCategory.id}
                      >
                        <div className={styles.site_item}>
                          <div className="logo">
                            <span className={site.icon}></span>
                          </div>
                          <span className="name">{site.title}</span>
                          <button
                            type="button"
                            onClick={() => {
                              toggleSite(site);
                            }}
                          >
                            선택
                          </button>
                        </div>
                      </ClickStatsWrapper>
                    </li>
                  )
              )}
            </ul>
          </div>
          <div className="list_nav">
            {pageIndex !== 0 && (
              <ClickStatsWrapper
                event="@Click"
                properties={{
                  pos: 'start_settings',
                  selectTab: 'site',
                  cm: 'left',
                }}
              >
                <button
                  type="button"
                  className="prev"
                  onClick={() => {
                    changePageIndex(pageIndex - 1);
                  }}
                >
                  이전
                </button>
              </ClickStatsWrapper>
            )}
            {pageIndex < maxPageIndex && (
              <ClickStatsWrapper
                event="@Click"
                properties={{
                  pos: 'start_settings',
                  selectTab: 'site',
                  cm: 'right',
                }}
              >
                <button
                  type="button"
                  className="next"
                  onClick={() => {
                    changePageIndex(pageIndex + 1);
                  }}
                >
                  다음
                </button>
              </ClickStatsWrapper>
            )}
          </div>
        </div>
      </div>
    );
  }
);
