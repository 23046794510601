import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';

import { PersonalSliceState, Site } from '@/common/store/slices/personal';
import { OPTIONS_KEY, OptionsChangeCycle } from './state';
import {
  DESIRE,
  PAINT,
  AURORA,
  HOPE,
  REST,
  PLANET,
  START,
  LIFE,
  COOL,
  PLEASURE,
  CLOUD,
  MODERN,
  FOCUS,
  SUNSET,
} from '@/common/constants';
import { Storage } from '@/common/modules';
import { authService } from '@/common/services';

export type WallpaperName =
  | typeof DESIRE
  | typeof PAINT
  | typeof AURORA
  | typeof HOPE
  | typeof REST
  | typeof PLANET
  | typeof START
  | typeof LIFE
  | typeof COOL
  | typeof PLEASURE
  | typeof CLOUD
  | typeof MODERN
  | typeof FOCUS
  | typeof SUNSET;

interface AddSiteToSelectedSitesPayload {
  targetSite: Site;
}

interface RemoveSiteFromSelectedSitePayload
  extends AddSiteToSelectedSitesPayload {}

export const optionsReducers = {
  /**
   * 배경화면을 select하는 리듀서 (임시 설정)
   */
  selectWallpaper: (
    state: WritableDraft<PersonalSliceState>,
    actions: PayloadAction<WallpaperName>
  ) => {
    const { wallpapers } = state.options.temporary.wallpaperOption;

    wallpapers.forEach((wallpaper) => {
      if (wallpaper.name === actions.payload) {
        wallpaper.isSelected = true;

        return;
      }

      wallpaper.isSelected = false;
    });
  },

  /**
   * 배경화면 변경주기를 set하는 리듀서 (임시 설정)
   */
  setChangeCycle: (
    state: WritableDraft<PersonalSliceState>,
    action: PayloadAction<OptionsChangeCycle>
  ) => {
    state.options.temporary.wallpaperOption.changeCycle = action.payload;
  },

  /**
   * 자주 방문한 사이트를 바로가기 위젯에 노출하는 지 여부를 토글하는 리듀서 (임시 설정)
   */
  toggleIsShowFrequentVisitedSite: (
    state: WritableDraft<PersonalSliceState>
  ) => {
    state.options.temporary.widget.isShowFrequentVisitedSite =
      !state.options.temporary.widget.isShowFrequentVisitedSite;
  },

  /**
   * 바로가기 위젯의 아이콘 이름을 숨기는지 여부를 토글하는 리듀서 (임시 설정)
   */
  toggleIsHidingIconNames: (state: WritableDraft<PersonalSliceState>) => {
    state.options.temporary.widget.isHidingIconNames =
      !state.options.temporary.widget.isHidingIconNames;
  },

  /**
   * 아이콘 모서리의 둥근 정도를 set하는 리듀서 (임시 설정)
   */
  setIconBorderRadiusRate: (
    state: WritableDraft<PersonalSliceState>,
    actions: PayloadAction<number>
  ) => {
    state.options.temporary.widget.iconBorderRadiusRate = actions.payload;
  },

  /**
   * 위치 설정하는 리듀서
   */
  setLocation: (
    state: WritableDraft<PersonalSliceState>,
    action: PayloadAction<string>
  ) => {
    state.options.temporary.location = action.payload;
  },

  /**
   * 임시 설정 값을 실제 설정 값으로 업데이트하는 리듀서
   */
  updateOriginWithTemporary: (state: WritableDraft<PersonalSliceState>) => {
    const { wallpaperOption, widget, location } = state.options.temporary;

    state.options.origin = {
      wallpaperOption,
      widget,
      location,
    };
  },

  /**
   * selectedSites에 site를 추가하는 리듀서
   */
  addSiteToSelectedSites: (
    state: WritableDraft<PersonalSliceState>,
    action: PayloadAction<AddSiteToSelectedSitesPayload>
  ) => {
    const { targetSite } = action.payload;
    const { temporary } = state.options;

    const duplicatedSite = temporary.selectedSites.find(
      (site) => site.url === targetSite.url
    );

    if (duplicatedSite) return;

    state.options.temporary.selectedSites.push(targetSite);
  },

  /**
   * selectedSites에 site를 제거하는 리듀서
   */
  removeSiteFromSelectedSites: (
    state: WritableDraft<PersonalSliceState>,
    action: PayloadAction<RemoveSiteFromSelectedSitePayload>
  ) => {
    const { targetSite } = action.payload;
    const { temporary } = state.options;

    const siteIndex = temporary.selectedSites.findIndex(
      (site) => site.url === targetSite.url
    );

    if (siteIndex === -1) return;

    state.options.temporary.selectedSites.splice(siteIndex, 1);
  },

  /**
   * 옵션에 필요한 데이터를 가져오는 리듀서
   */
  loadOptionsState: (state: WritableDraft<PersonalSliceState>) => {
    const loadedState = Storage.getData(OPTIONS_KEY);

    if (
      !authService.checkIsAuth() &&
      loadedState &&
      Object.keys(loadedState).length
    ) {
      state.options = {
        ...loadedState,
        temporary: { ...loadedState.origin, selectedSites: [] },
      };
      const { changeCycle, wallpapers: originWallpaper } =
        state.options.origin.wallpaperOption;
      const { wallpapers: temporaryWallpaper } =
        state.options.temporary.wallpaperOption;

      if (changeCycle === 'everyTime') {
        let tries = 0;

        while (tries < 100) {
          const randomIndex = Math.trunc(
            Math.random() * originWallpaper.length - 1
          );

          if (originWallpaper[randomIndex].isSelected) {
            tries++;

            continue;
          }

          originWallpaper.forEach((wallpaper, index) => {
            wallpaper.isSelected = index === randomIndex;
          });

          temporaryWallpaper.forEach((wallpaper, index) => {
            wallpaper.isSelected = index === randomIndex;
          });

          break;
        }
      }
    }
  },
};
