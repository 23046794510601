import React, { memo, FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import styles from '@/common/styles/components/options.module.scss';
import { useRootSelector } from '@/common/hooks';
import { getTimeText } from '@/common/utils';
import {
  addReadContent,
  CollectInterests,
  collectInterestsLabels,
  fetchPersonalState,
  addDeleteAlarm,
  CollectAlarm,
} from '@/common/store/slices/personal';
import { Alarm } from '@/common/domain/base';
import { ClickStatsWrapper } from '@/common/components/hoc';

interface Props {
  alarms: Alarm[];
}

/**
 * 알람 모달 - 알람 리스트
 * @returns
 */
export const AlarmList: FC<Props> = memo(({ alarms }: Props) => {
  const dispatch = useDispatch();
  const isLogin = useRootSelector((state) => state.rootSlice.isLogin);

  const readAlarm = useCallback(
    async (alarm: CollectAlarm) => {
      if (isLogin) {
        await dispatch(addReadContent(alarm));
        await dispatch(fetchPersonalState());
      }
    },
    [isLogin, dispatch]
  );

  const deleteAlarm = useCallback(
    async (alarm: CollectAlarm) => {
      if (isLogin) {
        await dispatch(addDeleteAlarm(alarm));
        await dispatch(fetchPersonalState());
      }
    },
    [isLogin, dispatch]
  );

  return (
    <div className={styles.alarm_list}>
      <ul>
        {alarms.map((alarm) => (
          <li key={alarm.link + alarm.category + alarm.origin + alarm.title}>
            <ClickStatsWrapper
              event="@Click"
              properties={{
                pos: 'start_notification',
                cm: 'contents',
                outurl: alarm.link,
              }}
            >
              <a
                className={styles.alarm_item}
                href={alarm.link}
                target="_blank"
                onClick={() => {
                  readAlarm(alarm);
                }}
              >
                <div className="thumb">
                  {alarm.thumbnail ? (
                    <img
                      loading="lazy"
                      src={alarm.thumbnail}
                      alt={alarm.title}
                    />
                  ) : (
                    <span className="img_default"></span>
                  )}
                </div>
                <div className="title">{alarm.title}</div>
                <ul className="info">
                  <li className="date">{alarm.contentsUploadDate}</li>
                  <li className="category">
                    {{ ...collectInterestsLabels }[
                      alarm.subCategory as keyof CollectInterests
                    ] ||
                      { ...collectInterestsLabels }[
                        alarm.category as keyof CollectInterests
                      ]}
                  </li>
                  <li className="update">{getTimeText(alarm.createdDate)}</li>
                </ul>
              </a>
            </ClickStatsWrapper>
            <button
              type="button"
              className="btn_remove"
              onClick={() => {
                deleteAlarm(alarm);
              }}
            >
              삭제
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
});
