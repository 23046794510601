import { WallpaperOption, Widget, ChangeCycle } from '@/common/domain';

import {
  DESIRE,
  PAINT,
  AURORA,
  HOPE,
  REST,
  PLANET,
  START,
  LIFE,
  COOL,
  PLEASURE,
  CLOUD,
  MODERN,
  FOCUS,
  SUNSET,
} from '@/common/constants';

export interface Site {
  icon: string;
  title: string;
  url: string;
}

export type OptionsChangeCycle = ChangeCycle;
export const optionsChangeCycleLabels: Record<OptionsChangeCycle, string> = {
  everyTime: '열 때 마다',
  day: '하루',
  fixed: '고정',
};

interface OptionsWidget extends Widget {}

interface OptionsWallpaperOption extends WallpaperOption {}

interface OptionBasicStruct {
  wallpaperOption: OptionsWallpaperOption;
  widget: OptionsWidget;
  location: string | null;
}

interface OptionTemporaryStruct extends OptionBasicStruct {
  selectedSites: Site[];
}

export interface OptionsState {
  origin: OptionBasicStruct;
  temporary: OptionTemporaryStruct;
}

export const OPTIONS_KEY = 'options';

export const optionsState: OptionsState = {
  origin: {
    wallpaperOption: {
      changeCycle: 'day',
      wallpapers: [
        { name: DESIRE, isSelected: true },
        { name: PAINT, isSelected: false },
        { name: AURORA, isSelected: false },
        { name: HOPE, isSelected: false },
        { name: REST, isSelected: false },
        { name: PLANET, isSelected: false },
        { name: START, isSelected: false },
        { name: LIFE, isSelected: false },
        { name: COOL, isSelected: false },
        { name: PLEASURE, isSelected: false },
        { name: CLOUD, isSelected: false },
        { name: MODERN, isSelected: false },
        { name: FOCUS, isSelected: false },
        { name: SUNSET, isSelected: false },
      ],
    },

    widget: {
      isShowFrequentVisitedSite: false,
      isHidingIconNames: false,
      iconBorderRadiusRate: 50,
    },

    location: null,
  },
  temporary: {
    wallpaperOption: {
      changeCycle: 'day',
      wallpapers: [
        { name: DESIRE, isSelected: true },
        { name: PAINT, isSelected: false },
        { name: AURORA, isSelected: false },
        { name: HOPE, isSelected: false },
        { name: REST, isSelected: false },
        { name: PLANET, isSelected: false },
        { name: START, isSelected: false },
        { name: LIFE, isSelected: false },
        { name: COOL, isSelected: false },
        { name: PLEASURE, isSelected: false },
        { name: CLOUD, isSelected: false },
        { name: MODERN, isSelected: false },
        { name: FOCUS, isSelected: false },
        { name: SUNSET, isSelected: false },
      ],
    },

    widget: {
      isShowFrequentVisitedSite: false,
      isHidingIconNames: false,
      iconBorderRadiusRate: 50,
    },

    location: null,

    selectedSites: [],
  },
};
