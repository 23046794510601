import React, {
  useState,
  useCallback,
  memo,
  FC,
  EventHandler,
  ChangeEvent,
} from 'react';
import { useDispatch } from 'react-redux';

import { RecommendedSiteCategory } from './RecommendedSiteCategory';
import styles from '@/common/styles/components/options.module.scss';
import {
  addSite,
  fetchPersonalState,
  updateShortcutsState,
} from '@/common/store/slices/personal';
import { toggleModal } from '@/common/store/slices/modal/modalSlice';
import { useRootSelector } from '@/common/hooks';
import { ClickStatsWrapper } from '@/common/components/hoc';
import { validateSiteInput } from '@/common/utils';

const siteCategories: SiteCategory[] = require('@/common/resources/stub/sites.json');

export interface SiteCategory {
  title: string[];
  id: string;
  sites: Site[];
}

export interface Site {
  icon: string;
  title: string;
  url: string;
}

/**
 * 옵션 모달 - 사이트
 * @returns
 */
export const Sites: FC = memo(() => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');

  const apply = useCallback(async () => {
    if (!url || !name) return;

    if (!validateSiteInput(name, url)) return;

    dispatch(
      addSite({
        type: 'site',
        name,
        url,
      })
    );

    await dispatch(updateShortcutsState());
    await dispatch(fetchPersonalState());

    dispatch(
      toggleModal({
        modal: 'optionsModal',
      })
    );
  }, [name, url, dispatch]);

  const changeName: EventHandler<ChangeEvent<HTMLInputElement>> = useCallback(
    (event) => {
      setName(event.target.value);
    },
    []
  );

  const changeUrl: EventHandler<ChangeEvent<HTMLInputElement>> = useCallback(
    (event) => {
      setUrl(event.target.value);
    },
    []
  );

  return (
    <div className={styles.sites_option}>
      <div className={`${styles.setting_block}`}>
        <strong className="setting_name">
          사이트 추가{' '}
          <small>*사이트 바로가기는 로그인 시, 최대 40개 등록 가능</small>
        </strong>
        <div className={styles.setting_content}>
          <div className={styles.add_site_module}>
            <ClickStatsWrapper
              event="@Click"
              properties={{
                pos: 'start_settings',
                selectTab: 'site',
                cm: 'typing',
              }}
            >
              <span className="input_line">
                <input type="text" placeholder="이름" onChange={changeName} />
              </span>
            </ClickStatsWrapper>

            <ClickStatsWrapper
              event="@Click"
              properties={{
                pos: 'start_settings',
                selectTab: 'site',
                cm: 'url',
              }}
            >
              <span className="input_line">
                <input
                  type="text"
                  placeholder="URL을 입력해주세요. (예시) http://zum.com, zum.com"
                  onChange={changeUrl}
                />
              </span>
            </ClickStatsWrapper>

            <ClickStatsWrapper
              event="@Click"
              properties={{
                pos: 'start_settings',
                selectTab: 'site',
                cm: 'apply',
              }}
            >
              <button type="button" onClick={apply}>
                적용
              </button>
            </ClickStatsWrapper>
          </div>
        </div>
      </div>

      <div className={`${styles.setting_block}`}>
        <strong className="setting_name">추천 사이트</strong>
        <div className={styles.setting_content}>
          <div className={styles.recommended_sites}>
            {[...siteCategories].map((siteCategory) => (
              <RecommendedSiteCategory
                key={siteCategory.id}
                siteCategory={siteCategory}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});
