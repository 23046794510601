import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
  collectInterestsLabels,
  CollectInterests,
  setInterestsWithLogin,
  fetchPersonalState,
} from '@/common/store/slices/personal';
import styles from '@/common/styles/components/modal.module.scss';
import { MODAL_KEY, toggleModal } from '@/common/store/slices/modal/modalSlice';
import { NonSelectedInterestModal } from './NonSelectedInterestModal';
import { useRootSelector } from '@/common/hooks';
import { ClickStatsWrapper } from '../hoc';

/**
 * 첫 접속 시 관심사 설정을 위한 모달 컴포넌트
 */
export const SelectInterestsModal: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const [interests, setInterests] = React.useState<CollectInterests>({
    popularity: false,
    politics: false,
    society: false,
    economy: false,
    world: false,
    it: false,
    culture: false,
    entertainment: false,
    sports: false,
    biz: false,
  });
  const modalState = useRootSelector(
    (state) => state.modalSlice[MODAL_KEY].modals
  );

  const selectInterest = React.useCallback(
    (key: keyof CollectInterests) => {
      const copiedInterests = { ...interests };

      copiedInterests[key] = !copiedInterests[key];

      setInterests(copiedInterests);
    },
    [interests]
  );

  const complete = React.useCallback(async () => {
    const allInterestsNotSelected = Object.entries(interests).every(
      ([, value]) => !value
    );

    if (allInterestsNotSelected)
      dispatch(toggleModal({ modal: 'nonSelectedInterestModal' }));
    else {
      if ('auto' in interests) {
        const { auto, ...rest } = interests;

        await dispatch(setInterestsWithLogin(rest));
      } else {
        await dispatch(setInterestsWithLogin(interests));
      }

      await dispatch(fetchPersonalState());
      dispatch(toggleModal({ modal: 'selectInterestModal' }));
    }
  }, [interests, dispatch]);

  return (
    <>
      <div className={styles.select_interest_first}>
        <div className="inner">
          <h2>당신의 관심사를 알려주세요!</h2>
          <p className="text">설정한 관심사에 맞는 콘텐츠를 보여드려요.</p>
          <ul className="interest_list">
            {Object.entries(interests).map((interest) => {
              const [key, value] = interest;

              return (
                <li key={key}>
                  <button
                    onClick={() =>
                      selectInterest(key as keyof CollectInterests)
                    }
                    className={value ? 'selected' : ''}
                  >
                    <i className={`icon ${key}`}></i>
                    {
                      { ...collectInterestsLabels }[
                        key as keyof CollectInterests
                      ]
                    }{' '}
                    <span className="blind">{value ? '선택됨' : ''}</span>
                  </button>
                </li>
              );
            })}
          </ul>
          <div className={`${styles.btn_bottom} ${styles.btn_col_1}`}>
            <ClickStatsWrapper
              event="@ArrayClick"
              properties={{
                cm: 'concern_yes',
                pos: 'start_identity',
                items: Object.entries(interests)
                  .filter(([, value]) => value)
                  .map(([key]) => key),
              }}
            >
              <button type="button" onClick={complete}>
                선택 완료
              </button>
            </ClickStatsWrapper>
          </div>
        </div>
      </div>

      <div className={styles.modal_bg} />
      {modalState.nonSelectedInterestModal.isOn && <NonSelectedInterestModal />}
    </>
  );
});
