// 모아보기의 관심사 선택 데이터 인터페이스
export const enum InterestsEnum {
  POPULARITY = 'popularity',
  POLITICS = 'politics',
  SOCIETY = 'society',
  ECONOMY = 'economy',
  WORLD = 'world',
  IT = 'it',
  CULTURE = 'culture',
  ENTERTAINMENT = 'entertainment',
  SPORTS = 'sports',
  BIZ = 'biz',
  COLUMN = 'column' /** 뉴스줌 칼럼 카테고리를 경제로 보여주는 기획 */,
}

export type Interests = Partial<Record<InterestsEnum, boolean>>;
