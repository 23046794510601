import * as React from 'react';

import styles from '@/common/styles/components/options.module.scss';
import { useRootSelector } from '@/common/hooks';
import { UNION_API_URL, ZUM } from '@/common/constants';
import { getLoginUrl } from '@/common/utils';
import axios from 'axios';
import Cookies from 'js-cookie';

/**
 * 유저 정보 모달 컴포넌트
 * @returns
 */
export const UserModal: React.FC = React.memo(() => {
  const isLogin = useRootSelector((state) => state.rootSlice.isLogin);
  const userInfo = useRootSelector((state) => state.rootSlice.userInfo);

  const onLogoutClick: React.MouseEventHandler = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${UNION_API_URL}/logout`,
        {},
        {
          params: {
            redirectUrl: location.origin,
          },
          withCredentials: true,
        }
      );
      afterLogout();
    } catch (error) {
      afterLogout(); /** @temp Prod API 배포 전 임시 처리 */
      console.error(error);
    }
  };

  const afterLogout = () => {
    Cookies.remove('EZSID', { domain: '.zum.com', path: '/' });
    location.href = location.origin;
  };

  return (
    <div className={styles.user_info_modal}>
      {!!isLogin && (
        <div className={styles.user_info}>
          <span className="name">
            <strong>{userInfo!.loginId}</strong>님
          </span>
        </div>
      )}

      <div className={styles.user_option}>
        <ul>
          {isLogin ? (
            <li>
              <a onClick={onLogoutClick}>로그아웃</a>
            </li>
          ) : (
            <li>
              <div className={styles.before_login}>
                <h4>로그인 해보세요!</h4>
                <p>
                  스타트줌에 로그인 하시고,
                  <br />
                  나만의 맞춤서비스를 만나보세요.
                </p>
                <a href={getLoginUrl(ZUM, location.href)}>로그인</a>
              </div>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
});
