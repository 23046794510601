import { useEffect, useState } from 'react';

import { TopicContents, TopicContent } from '@/common/domain';
import { CollectInterests, COLLECT_KEY } from '@/common/store/slices/personal';
import { shuffle } from '@/common/utils';
import { POPULARITY } from '@/common/constants';
import { useRootSelector } from './useRootSelector';

interface Props {
  contents: TopicContents | undefined;
}

export const useFilteredFeedContents = ({ contents }: Props) => {
  const [filteredContents, setFilteredContents] = useState<TopicContent[]>([]);
  const [shuffledContents, setShuffledContents] = useState<TopicContent[]>([]);

  const hiddenMedia = useRootSelector(
    (state) => state.personalSlice[COLLECT_KEY].hiddenMedia
  );
  const hiddenContents = useRootSelector(
    (state) => state.personalSlice[COLLECT_KEY].hiddenContents
  );
  const interests = useRootSelector(
    (state) => state.personalSlice[COLLECT_KEY].interests
  );

  useEffect(() => {
    if (!contents) return;

    const shuffledContents = shuffle(
      Object.entries(contents).flatMap(
        ([key, value]: [string, TopicContent[]]) => {
          if (key === POPULARITY)
            return value.map((element) => {
              element.category = POPULARITY;

              return element;
            });

          return value;
        }
      )
    );

    setShuffledContents(shuffledContents);
  }, [contents]);

  useEffect(() => {
    if (!contents || !shuffledContents.length) return;

    const filteredContents = shuffledContents
      .filter(
        (content) =>
          !hiddenMedia.find((media) => media.origin === content.origin)
      )
      .filter(
        (content) =>
          !hiddenContents.find(
            (hiddenContent) => hiddenContent.link === content.link
          )
      )
      .filter(
        (content) => interests[content.category as keyof CollectInterests]
      );

    setFilteredContents(filteredContents);
  }, [contents, shuffledContents, hiddenContents, hiddenMedia, interests]);

  return filteredContents;
};
