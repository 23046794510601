import * as React from 'react';
import { useDispatch } from 'react-redux';

import { useRootSelector } from '@/common/hooks';
import styles from '@/common/styles/components/options.module.scss';
import {
  OPTIONS_KEY,
  setIconBorderRadiusRate,
  toggleIsHidingIconNames,
} from '@/common/store/slices/personal';
import { ClickStatsWrapper } from '@/common/components/hoc';

const roundOptions = [0, 25, 50, 75, 100],
  roundClasses = roundOptions.map((el, index) => {
    return 'icon_round_type_' + (index + 1);
  });

/**
 * 옵션 모달 - 위젯모드
 * @returns
 */
export const WidgetMode: React.FC = React.memo(() => {
  const temporary = useRootSelector(
    (state) => state.personalSlice.options.temporary
  );
  const dispatch = useDispatch();
  const styleRound = {
    width: temporary.widget.iconBorderRadiusRate + '%',
  };

  const changeRangeValue = (value: number) => {
    const roundIndex = [...roundOptions].indexOf(value);
    document.querySelector('body')?.classList.remove(...roundClasses);
    document
      .querySelector('body')
      ?.classList.add('icon_round_type_' + (roundIndex + 1));
  };

  const hideIconNames = React.useCallback(() => {
    dispatch(toggleIsHidingIconNames());
  }, [dispatch]);

  const changeBorderRadiusRate: React.EventHandler<
    React.ChangeEvent<HTMLInputElement>
  > = React.useCallback(
    (event) => {
      const value = Number(event.target.value);
      changeRangeValue(value);
      dispatch(setIconBorderRadiusRate(value));
    },
    [dispatch]
  );

  return (
    <div className={styles.widget_mode_option}>
      <div className={`${styles.setting_block}`}>
        <strong className="setting_name">아이콘 이름 숨기기</strong>
        <div className={styles.setting_content}>
          <ClickStatsWrapper
            event="@Click"
            properties={{
              pos: 'start_settings',
              selectTab: 'widget',
              cm: temporary.widget.isHidingIconNames
                ? 'hiding_off'
                : 'hiding_on',
            }}
          >
            <div className={styles.check_box}>
              <input
                type="checkbox"
                id="isHidingIconNames"
                checked={temporary.widget.isHidingIconNames}
                onChange={hideIconNames}
              />

              <label htmlFor="isHidingIconNames">아이콘 이름 숨기기</label>
            </div>
          </ClickStatsWrapper>
        </div>
      </div>

      <div className={`${styles.setting_block} ${styles.icon_round}`}>
        <strong className="setting_name">아이콘 둥근 모서리</strong>
        <div className={styles.setting_content}>
          <div className={styles.value_slider}>
            <strong className="value">
              {temporary.widget.iconBorderRadiusRate + '%'}
            </strong>
            <div className="slider">
              <ClickStatsWrapper
                event="@Click"
                properties={{
                  pos: 'start_settings',
                  selectTab: 'widget',
                  cm: 'edge',
                }}
              >
                <input
                  type="range"
                  min="0"
                  max="100"
                  step="25"
                  value={temporary.widget.iconBorderRadiusRate}
                  onChange={changeBorderRadiusRate}
                />
                <span className="bg"></span>
                <span className="bar" style={styleRound}></span>
              </ClickStatsWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
