import React, { FC, useCallback } from 'react';
import Link from 'next/link';
import { useDispatch } from 'react-redux';

import styles from '@/common/styles/components/options.module.scss';
import { COLLECT, COLLECT_TAB } from '@/common/constants';
import {
  CurrentRoute,
  setCurrentRoute,
} from '@/common/store/slices/root/rootSlice';

/**
 * 헤더 알람 버튼을 누르면 노출되는 모아보기 탭 컴포넌트
 * @returns
 */

interface Props {
  setVisibleTab: (index: string) => void;
}

export const HeaderCollectTab: FC<Props> = ({ setVisibleTab }) => {
  const dispatch = useDispatch();
  const selectTab = useCallback(
    (route: CurrentRoute) => {
      dispatch(setCurrentRoute(route));
      setVisibleTab(COLLECT_TAB);
    },
    [dispatch, setVisibleTab]
  );

  return (
    <div className={styles.btn_item}>
      {
        <Link href={`/views/${COLLECT}`} as={`/${COLLECT}`} legacyBehavior>
          <button
            className={styles.btn_bookmarks}
            onClick={() => selectTab(COLLECT)}
          >
            모아보기
          </button>
        </Link>
      }
    </div>
  );
};
