import * as React from 'react';

import { HeaderSearchBox, HeaderIssueKeyword } from './search';
import styles from '@/common/styles/components/search.module.scss';

/**
 * 헤더 중앙의 컨테이너 컴포넌트
 * 검색어 창, 실시간 검색어 순위를 포함한다.
 * @returns
 */
export const HeaderSearchContainer: React.FC = () => {
  return (
    <div className={styles.container}>
      <HeaderSearchBox />
      <HeaderIssueKeyword />
    </div>
  );
};
