import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { removeFalsyProperty, makeMatcher } from '@/common/utils';
import { PROMISE_STATES } from '@/common/constants';
import { PersonalSliceState } from './personalSlice';
import { COLLECT_KEY } from './collect';
import { SHORTCUTS_KEY } from './shortcuts';
import { OPTIONS_KEY } from './options';
import { fetchPersonalState } from './thunks';
import { PersonalDataResponse } from '@/common/domain';

const isPending = makeMatcher(PROMISE_STATES.PENDING);
const isFulfilled = makeMatcher(PROMISE_STATES.FULFILLED);
const isRejected = makeMatcher(PROMISE_STATES.REJECTED);

export const personalExtraReducers = (
  builder: ActionReducerMapBuilder<PersonalSliceState>
) => {
  builder.addCase(
    fetchPersonalState.fulfilled,
    (state, action: PayloadAction<PersonalDataResponse>) => {
      if (!action.payload || !action.payload.setting) return;

      const filteredPersonalData = removeFalsyProperty(action.payload);
      const {
        interests,
        saveContents,
        readContents,
        hiddenContents,
        hiddenMedia,
        deleteAlarms,
        options,
        setting,
      } = filteredPersonalData;
      const { wallpaperOption, widget, location = null, shortcuts } = setting;

      /** 23-12-29 투자 서비스 종료 */
      if (interests && 'investment' in interests) {
        delete interests['investment'];
      }

      const filteredShortcuts = shortcuts.filter(
        (item) => !('url' in item && /(invest|finance)\.zum/.test(item.url))
      );

      const { changeCycle, wallpapers } = wallpaperOption;

      if (changeCycle && wallpapers && changeCycle === 'everyTime') {
        let tries = 0;

        while (tries < 100) {
          const randomIndex = Math.trunc(Math.random() * wallpapers.length - 1);

          if (wallpapers[randomIndex].isSelected) {
            tries++;

            continue;
          }

          wallpapers.forEach((wallpaper, index) => {
            if (index === randomIndex) wallpaper.isSelected = true;
            else wallpaper.isSelected = false;
          });

          break;
        }
      }

      state[COLLECT_KEY] = {
        ...state[COLLECT_KEY],
        interests,
        saveContents,
        readContents,
        hiddenContents,
        hiddenMedia,
        deleteAlarms,
        options,
      };

      state[SHORTCUTS_KEY] = {
        ...state[SHORTCUTS_KEY],
        shortcuts: filteredShortcuts,
      };

      state[OPTIONS_KEY] = {
        ...state[OPTIONS_KEY],
        origin: { wallpaperOption, widget, location },
        temporary: {
          wallpaperOption,
          widget,
          location,
          selectedSites: [],
        },
      };
    }
  );

  builder.addMatcher(isPending, (state) => {
    state[COLLECT_KEY].isLoading = true;
    state[SHORTCUTS_KEY].isLoading = true;
  });

  builder.addMatcher(isFulfilled, (state) => {
    state[COLLECT_KEY].isLoading = false;
    state[COLLECT_KEY].isError = false;
    state[SHORTCUTS_KEY].isLoading = false;
    state[SHORTCUTS_KEY].isError = false;
  });

  builder.addMatcher(isRejected, (state) => {
    state[COLLECT_KEY].isLoading = false;
    state[COLLECT_KEY].isError = true;
    state[SHORTCUTS_KEY].isLoading = false;
    state[SHORTCUTS_KEY].isError = true;
  });
};
