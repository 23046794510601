import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  BIZ,
  COLLECT,
  ENTERTAINMENT,
  NEWS,
  POPULARITY,
  SPORTS,
  TV,
} from '@/common/constants';
import { UnionUserInfo } from '@/common/domain/base/UserInfo';
import { Storage } from '@/common/modules';
import { rootExtraReducers as extraReducers } from './extraReducers';

export const ROOT_SLICE = 'rootSlice';
export const ROOT_KEY = 'root';

export type CurrentRoute =
  | '/'
  | typeof POPULARITY
  | typeof NEWS
  | typeof TV
  | typeof ENTERTAINMENT
  | typeof SPORTS
  | typeof BIZ
  | typeof COLLECT;

export interface RootSliceState {
  isLoading: boolean;
  isError: boolean;
  isLogin: boolean;
  userInfo: UnionUserInfo | null;
  currentRoute: CurrentRoute;
  isAddSiteMode: boolean;
  atNotSeenForWeek: number;
}

const initialState: RootSliceState = {
  // TODO: user slice 분리
  isLoading: false,
  isError: false,
  isLogin: false,
  userInfo: null,
  currentRoute: '/',
  isAddSiteMode: false,
  atNotSeenForWeek: 0,
};

const rootSlice = createSlice({
  name: ROOT_SLICE,
  initialState,
  reducers: {
    /**
     * 현재 라우트를 설정하는 리듀서
     */
    setCurrentRoute: (state, action: PayloadAction<CurrentRoute>) => {
      state.currentRoute = action.payload;
    },

    /**
     * 현재 로그인 상태를 업데이트하는 리듀서
     */
    updateIsLogin: (state) => {
      if (state.userInfo && state.userInfo.id) {
        state.isLogin = true;
        return;
      }
      state.isLogin = false;
    },

    /**
     * 현재 유저 정보를 업데이트하는 리듀서
     */
    updateUserInfo: (
      state,
      action: PayloadAction<UnionUserInfo | undefined>
    ) => {
      const userInfo = action.payload;

      if (!userInfo) return;

      state.userInfo = {
        ...state.userInfo,
        ...userInfo,
      };

      /** loginId가 없는 경우 email 로컬 파트를 사용하는 것으로 협의 */
      if (!state.userInfo.loginId && userInfo.email) {
        state.userInfo.loginId = userInfo.email.split('@')[0] ?? '';
      }
    },

    /**
     * 현재 사이트 추가 모드인지 토글하는 리듀서
     */
    toggleIsAddSiteMode: (state) => {
      state.isAddSiteMode = !state.isAddSiteMode;
    },

    /**
     * 로그인 유도 모달에서 일주일 간 보지 않기 선택 시 선택 시간 저장하는 리듀서
     */
    updateAtNotSeenForWeek: (state, action: PayloadAction<number>) => {
      state.atNotSeenForWeek = action.payload;
    },

    /**
     * 로컬스토리지에 저장된 rootState를 가져오는 리듀서
     */
    loadRootState: (state) => {
      const loadedState = Storage.getData(ROOT_KEY);

      if (
        !state.isLogin &&
        !!loadedState &&
        Object.keys(loadedState).length !== 0
      )
        state.atNotSeenForWeek = loadedState.atNotSeenForWeek;
    },
  },
  extraReducers,
});

export const {
  setCurrentRoute,
  updateIsLogin,
  updateUserInfo,
  toggleIsAddSiteMode,
  updateAtNotSeenForWeek,
  loadRootState,
} = rootSlice.actions;

export default rootSlice;
