import * as React from 'react';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';

import { FolderSortableItem } from '@/common/components/modals/folder/FolderSortableItem';
import { ShortcutsSite } from '@/common/store/slices/personal';

interface Props {
  items: ShortcutsSite[];
  chunkIndex: number;
}
/**
 * 폴더 내에서 페이지 단위인 sortable한 container 역할을 하는 컴포넌트
 */
export const FolderSortableContext: React.FC<Props> = ({
  items,
  chunkIndex,
}) => {
  const { setNodeRef } = useDroppable({
    id: `droppable-${chunkIndex}`,
  });

  return (
    <SortableContext
      id={`${chunkIndex}`}
      items={items.map((item) => item.url)}
      strategy={rectSortingStrategy}
    >
      <div ref={setNodeRef}>
        {items.map((item) => (
          <FolderSortableItem item={item} key={item.url} />
        ))}
      </div>
    </SortableContext>
  );
};
