import React, {
  FC,
  memo,
  useState,
  useCallback,
  EventHandler,
  KeyboardEvent,
} from 'react';

import { LocationResults } from './LocationResults';
import styles from '@/common/styles/components/options.module.scss';
import { throttle } from '@/common/utils';
import { ClickStatsWrapper } from '@/common/components/hoc';
import {
  METROPOLITANS_SCHORTCUTS,
  PROVINCE_SHORTCUTS,
  SPECIAL_CITY_SHORTCUTS,
} from '@/common/constants';

type ShortcutsObject =
  | typeof SPECIAL_CITY_SHORTCUTS
  | typeof METROPOLITANS_SCHORTCUTS
  | typeof PROVINCE_SHORTCUTS;

export interface Address {
  id: string;
  addr: string;
}

const addresses: Address[] = require('../../../resources/stub/address.json');

/**
 * 옵션 모달 - 위치설정
 * @returns
 */
export const Location: FC = memo(() => {
  const [locations, setLocations] = useState<Address[]>(addresses);
  const [searchWord, setSearchWord] = useState('');

  const checkIsSearchWordInObjectKeys = (
    searchWord: string,
    shortcuts: ShortcutsObject
  ): searchWord is keyof ShortcutsObject => {
    return searchWord in shortcuts;
  };

  const convertToFullNameOfLocation = (searchWord: string) => {
    // 서울시 -> 서울특별시로 검색어 변경
    // 세종시 -> 세종특별자치시로 검색어 변경
    // 제주도 -> 제주특별자치도로 검색어 변경
    if (checkIsSearchWordInObjectKeys(searchWord, SPECIAL_CITY_SHORTCUTS))
      return SPECIAL_CITY_SHORTCUTS[searchWord];

    // 광역시의 경우, XX시 -> XX광역시로 변경
    // EX) 인천시 -> 인천광역시
    if (checkIsSearchWordInObjectKeys(searchWord, METROPOLITANS_SCHORTCUTS))
      return METROPOLITANS_SCHORTCUTS[searchWord];

    // 도의 약어의 경우 풀네임으로 변경
    // EX) 충남 -> 충청남도
    if (checkIsSearchWordInObjectKeys(searchWord, PROVINCE_SHORTCUTS))
      return PROVINCE_SHORTCUTS[searchWord];

    return searchWord;
  };

  const enterKeyword: EventHandler<KeyboardEvent<HTMLInputElement>> =
    useCallback((event) => {
      const eventTarget = event.target as HTMLInputElement;
      const { value: keyword } = eventTarget;
      const convertedKeyword = convertToFullNameOfLocation(keyword);

      setSearchWord(convertedKeyword);
      setLocations(
        addresses.filter((address) => address.addr.includes(convertedKeyword))
      );
    }, []);

  const throttledEnterKeyword = throttle(enterKeyword);

  return (
    <div className={styles.location_option}>
      <div className={`${styles.setting_block}`}>
        <strong className="setting_name">위치설정</strong>
        <div className={styles.setting_content}>
          <div className={styles.find_location_module}>
            <ClickStatsWrapper
              event="@Click"
              properties={{
                pos: 'start_settings',
                selectTab: 'location',
                cm: 'typing',
              }}
            >
              <span className="search_box">
                <input
                  type="text"
                  placeholder="지역을 검색해주세요(동/읍/면)"
                  onInput={throttledEnterKeyword}
                />
              </span>
            </ClickStatsWrapper>

            <div className={styles.search_result}>
              {locations.length ? (
                <LocationResults
                  locations={locations}
                  searchWord={convertToFullNameOfLocation(searchWord)}
                />
              ) : (
                <p className={styles.result_none}>
                  검색어에 대한 검색결과가 없습니다.
                  <br />
                  지역명을 다시 입력해 주세요.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
