import { createAsyncThunk } from '@reduxjs/toolkit';
import { authService } from '@/common/services';

import { RootState } from '@/common/store';

const FETCH_USER_INFO = `rootSlice/fetchUserInfo`;

export const fetchUserInfo = createAsyncThunk<any, void, { state: RootState }>(
  FETCH_USER_INFO,
  async (_args) => {
    return await authService.getUserInfo();
  }
);
