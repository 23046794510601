import * as React from 'react';
import {
  statisticsService,
  StatsInputData,
  eventType,
} from '@/common/services';

interface Props extends StatsInputData {
  readonly children: React.ReactNode;
  readonly event: eventType;
  readonly properties?: StatsInputData;
}

export const ClickStatsWrapper: React.FC<Props> = React.memo(
  ({ children, event, properties }: Props) => {
    const sendClickStat = () => {
      statisticsService.send(event, {
        ...properties,
      });
    };

    return <div onClick={sendClickStat}>{children}</div>;
  }
);
