import * as React from 'react';
import { useDispatch } from 'react-redux';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  DragStartEvent,
  DragOverEvent,
  DragEndEvent,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates, arrayMove } from '@dnd-kit/sortable';

import { useRootSelector } from '@/common/hooks';
import styles from '@/common/styles/components/modal.module.scss';
import { MODAL_KEY, toggleModal } from '@/common/store/slices/modal/modalSlice';
import {
  ShortcutsSite,
  editFolder,
  SHORTCUTS_KEY,
  updateShortcutsState,
  fetchPersonalState,
  ShortcutsFolder,
} from '@/common/store/slices/personal';
import {
  ITEM_PER_FOLDER_PAGE,
  FODLER_PAGE_MOVE_DISTACNE,
  THROTTLE_DELAY,
  ENTER,
} from '@/common/constants';
import { getChunks, throttle } from '@/common/utils';
import { Data } from '@dnd-kit/core/dist/store';

import {
  FolderSortableContext,
  FolderSite,
} from '@/common/components/modals/folder';
import { statisticsService } from '@/common/services';

/**
 * 바로가기 폴더 관리를 위한 모달 컴포넌트
 */
export const FolderModal: React.FC = () => {
  const dispatch = useDispatch();
  const targetFolderName = useRootSelector(
    (state) => state.modalSlice[MODAL_KEY].modals.folderModal.targetId
  );
  const shortcuts = useRootSelector(
    (state) => state.personalSlice[SHORTCUTS_KEY].shortcuts
  );
  const targetFolder = shortcuts.find(
    (shortcut) =>
      shortcut.type === 'folder' && shortcut.name === targetFolderName
  ) as ShortcutsFolder;

  const [activeItem, setActiveItem] = React.useState<ShortcutsSite>();
  const [isEditFolderName, setIsEditFolderName] = React.useState(false);
  const [editedFolderName, setEditedFolderName] =
    React.useState(targetFolderName);
  const [currentScrollIndex, setCurrentScrollIndex] = React.useState(0);
  const chunks = getChunks(targetFolder.children, 6);
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const bgClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();

      dispatch(
        toggleModal({
          modal: 'folderModal',
          targetId: '',
        })
      );
    },
    [dispatch]
  );

  const dragStart = React.useCallback((event: DragStartEvent) => {
    if (!event.active) return;

    const { name, type, url } = event.active.data.current as Data &
      ShortcutsSite;

    setActiveItem({ name, url, type });
  }, []);

  const dragOver = React.useCallback(
    (event: DragOverEvent) => {
      const { active, over } = event;

      if (!active.data.current) return;

      const { containerId: activeChunksIndex } = active.data.current.sortable;

      if (!over || !over.data.current) return;

      const { containerId: overChunksIndex } = over.data.current.sortable;

      if (
        !activeChunksIndex ||
        !overChunksIndex ||
        activeChunksIndex === overChunksIndex
      )
        return;

      const copiedChunks = [...chunks];
      const activeItemIndex = copiedChunks[activeChunksIndex].findIndex(
        (item) => item.url == active.id
      );

      if (activeItemIndex === -1) return;

      const activeItem = copiedChunks[activeChunksIndex][activeItemIndex];
      const overItemIndex = copiedChunks[overChunksIndex].findIndex(
        (item) => item.url === over.id
      );

      if (overItemIndex === -1) return;

      const overItem = copiedChunks[overChunksIndex][overItemIndex];

      copiedChunks[activeChunksIndex].splice(activeItemIndex, 1, overItem);
      copiedChunks[overChunksIndex].splice(overItemIndex, 1, activeItem);

      dispatch(
        editFolder({
          target: { name: targetFolder.name },
          changes: {
            name: targetFolder.name,
            children: copiedChunks.flat(),
          },
        })
      );
    },
    [chunks, targetFolder, dispatch]
  );

  const dragEnd = React.useCallback(
    async (event: DragEndEvent) => {
      const { active, over } = event;

      if (!active.data.current) return;

      const { containerId: activeChunksIndex } = active.data.current.sortable;

      if (!over || !over.data.current) return;

      const { containerId: overChunksIndex } = over.data.current.sortable;

      if (
        !activeChunksIndex ||
        !overChunksIndex ||
        activeChunksIndex !== overChunksIndex
      )
        return;

      const copiedChunks = [...chunks];
      const activeItemIndex = copiedChunks[activeChunksIndex].findIndex(
        (item) => item.url == active.id
      );

      const overItemIndex = copiedChunks[activeChunksIndex].findIndex(
        (item) => item.url === over.id
      );

      copiedChunks[activeChunksIndex] = arrayMove(
        copiedChunks[activeChunksIndex],
        activeItemIndex,
        overItemIndex
      );

      dispatch(
        editFolder({
          target: { name: targetFolder.name },
          changes: {
            name: targetFolder.name,
            children: copiedChunks.flat(),
          },
        })
      );

      await dispatch(updateShortcutsState());
      await dispatch(fetchPersonalState());

      statisticsService.send('@Click', {
        pos: 'start_site_folder',
        cm: 'icon',
      });
    },
    [chunks, targetFolder, dispatch]
  );

  const toggleIsEditFolderName = React.useCallback(() => {
    setIsEditFolderName(!isEditFolderName);
  }, [isEditFolderName]);

  const inputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEditedFolderName(event.target.value);

      statisticsService.send('@Click', {
        pos: 'start_site_folder',
        cm: 'folder_name',
      });
    },
    []
  );

  const inputOnBlur = React.useCallback(async () => {
    if (!editedFolderName) return;

    dispatch(
      toggleModal({
        modal: 'folderModal',
      })
    );
    dispatch(
      editFolder({
        target: { name: targetFolder.name },
        changes: {
          name: editedFolderName,
          children: targetFolder.children,
        },
      })
    );

    await dispatch(updateShortcutsState());

    setIsEditFolderName(!isEditFolderName);
  }, [editedFolderName, isEditFolderName, dispatch, targetFolder]);

  const inputKeyPress = React.useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (!editedFolderName) return;

      if (event.key === ENTER) {
        dispatch(
          toggleModal({
            modal: 'folderModal',
          })
        );
        dispatch(
          editFolder({
            target: { name: targetFolder.name },
            changes: {
              name: editedFolderName,
              children: targetFolder.children,
            },
          })
        );

        await dispatch(updateShortcutsState());

        setIsEditFolderName(!isEditFolderName);
      }
    },
    [targetFolder, editedFolderName, isEditFolderName, dispatch]
  );

  const wheel = React.useCallback(
    (event: React.WheelEvent<HTMLDivElement>) => {
      // 아래로 스크롤 시
      if (event.deltaY > 0) {
        if (
          currentScrollIndex <
          Math.ceil(targetFolder.children.length / ITEM_PER_FOLDER_PAGE) - 1
        ) {
          setCurrentScrollIndex(currentScrollIndex + 1);

          return;
        }

        setCurrentScrollIndex(0);
      } else {
        if (currentScrollIndex > 0) {
          setCurrentScrollIndex(currentScrollIndex - 1);

          return;
        }

        setCurrentScrollIndex(
          Math.ceil(targetFolder.children.length / ITEM_PER_FOLDER_PAGE) - 1
        );
      }
    },
    [currentScrollIndex, targetFolder]
  );

  const paging = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const { dataset } = event.target as HTMLElement;
      const nextScrollIndex = Number(dataset.pageIndex);

      if (nextScrollIndex === currentScrollIndex) return;

      setCurrentScrollIndex(nextScrollIndex);

      statisticsService.send('@Click', {
        pos: 'start_site_folder',
        cm: 'page',
      });
    },
    [currentScrollIndex]
  );

  const throttledWheel = throttle(wheel, THROTTLE_DELAY);

  const renderPagingButtons = React.useCallback(() => {
    return Array.from({
      length: Math.ceil(targetFolder.children.length / ITEM_PER_FOLDER_PAGE),
    }).map((item: any, index) => (
      <button
        type="button"
        className={index === currentScrollIndex ? `${styles.btn_active}` : ''}
        key={index}
        data-page-index={index}
        onClick={paging}
      />
    ));
  }, [currentScrollIndex, targetFolder, paging]);

  const renderSortableContexts = React.useCallback(() => {
    return chunks.map((chunk: ShortcutsSite[], index: number) => (
      <FolderSortableContext
        items={chunk}
        key={JSON.stringify(chunk)}
        chunkIndex={index}
      />
    ));
  }, [chunks]);

  const preventDefault = React.useCallback((e: Event) => {
    e.preventDefault();
  }, []);

  React.useEffect(() => {
    // @ts-ignore - internet explorer
    const isIE = typeof document.documentMode === 'number';

    if (isIE) {
      window.addEventListener('wheel', preventDefault);
    } else {
      window.addEventListener('wheel', preventDefault, {
        passive: false,
      });
    }

    return () => {
      window.removeEventListener('wheel', preventDefault);
    };
  }, [preventDefault]);

  React.useEffect(() => {
    if (
      targetFolder.children.length % ITEM_PER_FOLDER_PAGE === 0 &&
      targetFolder.children.length / ITEM_PER_FOLDER_PAGE - 1 <
        currentScrollIndex
    ) {
      setCurrentScrollIndex(currentScrollIndex - 1);

      return;
    }

    if (scrollRef.current) {
      const nextScrollOffset = currentScrollIndex * FODLER_PAGE_MOVE_DISTACNE;

      scrollRef.current.scrollTo({ top: nextScrollOffset });
    }
  }, [scrollRef, currentScrollIndex, targetFolder, shortcuts]);

  return (
    <>
      <div className={styles.folder_modal} onWheel={throttledWheel}>
        {isEditFolderName ? (
          <div className={styles.folder_name}>
            <input
              value={editedFolderName}
              onChange={inputChange}
              onBlur={inputOnBlur}
              onKeyPress={inputKeyPress}
              autoFocus
            ></input>
          </div>
        ) : (
          <div className={styles.folder_name} onClick={toggleIsEditFolderName}>
            <strong>{targetFolder.name}</strong>
          </div>
        )}

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          autoScroll={false}
          onDragStart={dragStart}
          onDragOver={dragOver}
          onDragEnd={dragEnd}
        >
          <div className={styles.folder_wrap} ref={scrollRef}>
            <div className={styles.folder_slots}>
              {renderSortableContexts()}
            </div>
          </div>
          <DragOverlay>
            {activeItem ? <FolderSite item={activeItem} /> : null}
          </DragOverlay>
        </DndContext>
        <div className={styles.folder_page}>{renderPagingButtons()}</div>
      </div>

      <div className={styles.modal_bg} onClick={bgClick}></div>
    </>
  );
};
