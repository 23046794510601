import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { SHORTCUTS_KEY } from './state';
import { PERSONAL_PATH } from '@/common/constants';
import { RootState } from '@/common/store';

const UPDATE_SHORTCUTS_STATE = 'shortcuts/updateShortcutsState';

export const updateShortcutsState = createAsyncThunk<
  any,
  void,
  { state: RootState }
>(UPDATE_SHORTCUTS_STATE, async (_args, { getState }) => {
  try {
    const { isLoading } = getState().rootSlice;
    const { shortcuts } = getState().personalSlice[SHORTCUTS_KEY];
    const { wallpaperOption, widget, location } =
      getState().personalSlice.options.origin;

    if (isLoading) {
      alert(`사용자 정보를 불러오는 중입니다. 잠시 후 다시 시도해주세요.`);
      return;
    }

    await axios.post(
      `/${PERSONAL_PATH}`,
      {
        setting: { shortcuts, wallpaperOption, widget, location },
      },
      {
        withCredentials: true,
      }
    );
  } catch (error) {
    console.log(error);
  }
});
