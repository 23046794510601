import * as React from 'react';

import styles from '@/common/styles/components/modal.module.scss';
import { useDispatch } from 'react-redux';
import { toggleModal } from '@/common/store/slices/modal/modalSlice';
import { getLoginUrl } from '@/common/utils';
import { ZUM } from '@/common/constants';

/**
 * 비로그인 시 로그인을 유도하는 모달
 * @returns
 */
export const NonLoginUserModal: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const closeModal = React.useCallback(() => {
    dispatch(
      toggleModal({
        modal: 'nonLoginUserModal',
      })
    );
  }, [dispatch]);
  const routeToLogin = React.useCallback(() => {
    window.location.href = getLoginUrl(ZUM, location.href);
  }, []);

  return (
    <>
      <div className={styles.common_modal}>
        <div className="inner">
          <div className={`${styles.icon} noti`}></div>
          <div className={styles.text}>
            스타트줌 로그인하고
            <br />
            다양한 서비스를 경험하세요!
          </div>
          <div className={`${styles.btn_bottom} full`}>
            <button type="button" onClick={routeToLogin}>
              로그인 하러 가기
            </button>
          </div>
        </div>
        <button
          type="button"
          className={styles.btn_modal_close}
          onClick={closeModal}
        >
          닫기
        </button>
      </div>

      <div className={styles.modal_bg} onClick={closeModal}></div>
    </>
  );
});
