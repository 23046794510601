import { EZSID, UNION_API_URL } from '@/common/constants';
import { UnionUserInfo } from '@/common/domain/base/UserInfo';
import axios from 'axios';

export const authService = {
  /**
   * 현재 로그인 상태인지 확인하는 함수
   */
  checkIsAuth() {
    const cookie = document.cookie;

    const ezsid =
      cookie
        .split('; ')
        .find((row) => row.startsWith(EZSID))
        ?.split('=')[1] || '';

    return Boolean(ezsid);
  },

  /**
   * 로그인된 사용자 정보를 가져오는 함수
   */
  getUserInfo(): Promise<UnionUserInfo> | undefined {
    const isAuth = this.checkIsAuth();

    if (!isAuth) return;

    const fetchUserInfo = axios.get(`${UNION_API_URL}/member/info`, {
      withCredentials: true,
    });

    return fetchUserInfo
      .then(({ data }) => data)
      .catch((error) => {
        console.error(error);
      });
  },
};
