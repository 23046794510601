import React, { FC } from 'react';

import { HeaderShortcutsWrapper } from './HeaderShortcutsWrapper';
import styles from '@/common/styles/components/links.module.scss';

/**
 * 헤더 중앙의 컨테이너 컴포넌트
 * 바로가기 버튼, 폴더 버튼을 포함한다.
 * @returns
 */
export const HeaderShortcutsContainer: FC = () => {
  function toggleLinks() {
    document
      .querySelector('div[class*="header_wrapper"]')
      ?.classList.toggle('header_unfold');
  }

  return (
    <div className={styles.container}>
      <HeaderShortcutsWrapper />
      <button type="button" className={styles.btn_toggle} onClick={toggleLinks}>
        바로가기 노출
      </button>
    </div>
  );
};
