import React, { FC, useEffect, useState } from 'react';

import styles from '@/common/styles/components/search-suggest.module.scss';
import { externalApiService } from '@/common/services';
import { AUTOCOMPLETE, F_SUGGEST_START } from '@/common/constants';
import { debounce } from '@/common/utils';
import { ClickStatsWrapper, ViewStatsWrapper } from '@/common/components/hoc';

interface Props {
  keyword: string;
}

/**
 * 헤더 내 검색 서제스트 추천검색어 컴포넌트
 * @returns
 */
export const SearchSuggestList: FC<Props> = ({ keyword }) => {
  const [keywords, setKeywords] = useState<string[]>([]);

  useEffect(() => {
    const fetchAndSetKeywords = async () => {
      const suggests = await externalApiService.fetchSuggests(keyword);
      const targetSuggest = suggests.find(
        (suggest) => suggest.category === AUTOCOMPLETE
      );

      if (!targetSuggest) return;

      const keywords = targetSuggest.entry.map((element) => element.title);

      setKeywords(keywords);
    };
    const debounced = debounce(fetchAndSetKeywords, 300);

    debounced();
  }, [keyword]);

  return (
    <>
      {!!keywords.length && (
        <div className={styles.recommend_keyword}>
          <strong className="suggest_title">추천 검색어</strong>
          <ViewStatsWrapper
            event="@SuggestPageView"
            properties={{
              keyword,
              ac: keywords.length,
            }}
          >
            <ul>
              {keywords.map((suggestedKeyword) => (
                <li key={suggestedKeyword}>
                  <ClickStatsWrapper
                    event="@SuggestMove"
                    properties={{
                      keyword: suggestedKeyword,
                      em: 'click',
                      qm: 'AdditionalKeyword',
                      selkey: keyword,
                      outurl: `https://search.zum.com/search.zum?method=uni&option=accu&rd=1&query=${encodeURIComponent(
                        suggestedKeyword
                      )}&qm=${F_SUGGEST_START}&sug_q=${keyword}&sug_tot=45&sug_fix=pre`,
                    }}
                  >
                    <a
                      href={`https://search.zum.com/search.zum?method=uni&option=accu&rd=1&query=${encodeURIComponent(
                        suggestedKeyword
                      )}&qm=${F_SUGGEST_START}&sug_q=${keyword}&sug_tot=45&sug_fix=pre`}
                      target="_blank"
                      className="keyword"
                    >
                      {suggestedKeyword}
                    </a>
                  </ClickStatsWrapper>
                </li>
              ))}
            </ul>
          </ViewStatsWrapper>
        </div>
      )}
    </>
  );
};
