import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';

import modalSlice, { MODAL_SLICE } from './slices/modal/modalSlice';
import rootSlice, { ROOT_SLICE } from './slices/root/rootSlice';
import personalSlice, { PERSONAL_SLICE } from './slices/personal/personalSlice';

/**
 * root store 를 생성하고 이를 next에서 사용하기 위해 wrapper HOC를 만든다.
 */

// store 생성 함수
export const makeStore = () => {
  return configureStore({
    reducer: {
      [ROOT_SLICE]: rootSlice.reducer,
      [MODAL_SLICE]: modalSlice.reducer,
      [PERSONAL_SLICE]: personalSlice.reducer,
    },
  });
};

// 타입 설정
export type RootStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<RootStore['getState']>;
export type RootDispatch = RootStore['dispatch'];

// wrapper HOC
export const reduxWrapper = createWrapper<RootStore>(makeStore);
