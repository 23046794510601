import { useEffect, useState } from 'react';

import { Alarm } from '@/common/domain';
import { useRootSelector } from './useRootSelector';
import { COLLECT_KEY, CollectInterests } from '@/common/store/slices/personal/';

interface Props {
  alarms: Alarm[] | undefined;
}

export const useFilteredAlarms = ({ alarms }: Props) => {
  const interests = useRootSelector(
    (state) => state.personalSlice[COLLECT_KEY].interests
  );
  const deleteAlarms = useRootSelector(
    (state) => state.personalSlice[COLLECT_KEY].deleteAlarms
  );
  const readContents = useRootSelector(
    (state) => state.personalSlice[COLLECT_KEY].readContents
  );
  const [filteredAlarms, setFilteredAlarms] = useState<Alarm[]>([]);

  useEffect(() => {
    if (!alarms) return;

    const filteredAlarms = alarms
      .filter(
        (alarm) =>
          interests[alarm.category as keyof CollectInterests] ||
          interests[alarm.subCategory as keyof CollectInterests]
      )
      .filter(
        (alarm) =>
          !deleteAlarms.find((deleteAlarm) => deleteAlarm.link === alarm.link)
      )
      .filter(
        (alarm) =>
          !readContents.find((contents) => contents.link === alarm.link)
      );

    setFilteredAlarms(filteredAlarms);
  }, [alarms, deleteAlarms, interests, readContents]);

  return filteredAlarms;
};
