import axios from 'axios';

import { PATHS } from '@/common/constants';

export const timestampService = {
  async getTimestamp(): Promise<number> {
    const { data: timestamp } = await axios.get(`${PATHS.TIMESTAMP}`);

    return timestamp;
  },
};
