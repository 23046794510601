import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { RootDispatch } from '@/common/store';
import { ROOT_KEY, loadRootState } from '@/common/store/slices/root/rootSlice';
import {
  fetchPersonalState,
  updateOptionsState,
} from '@/common/store/slices/personal';
import { Storage } from '@/common/modules';

interface Params {
  isLogin: boolean;
  isUpdateUserInfo: boolean;
}

export const useUserStates = ({ isLogin, isUpdateUserInfo }: Params) => {
  const dispatch = useDispatch<RootDispatch>();

  useEffect(() => {
    if (!isUpdateUserInfo) return;

    dispatch(loadRootState());
    dispatch(fetchPersonalState()).then(() => {
      /**
       * NOTE: 로컬스토리지 개인화 데이터를 API로 옮김 처리하는 로직
       * 추후 마이그레이션 기간 종료 시 로컬스토리지 정리 및 제거 필요
       */
      if (!Storage.getData(ROOT_KEY)?.personalMigrateAt && !isLogin) {
        dispatch(updateOptionsState()).then(() => {
          Storage.setData(ROOT_KEY, {
            ...Storage.getData(ROOT_KEY),
            personalMigrateAt: Date.now(),
          });
          dispatch(fetchPersonalState());
        });
      }
    });
  }, [isLogin, isUpdateUserInfo, dispatch]);
};
