import { AnyAction } from '@reduxjs/toolkit';
import * as DOMpurify from 'dompurify';

import {
  DAY_BY_MS,
  HOUR_BY_MS,
  HTTP,
  HTTPS,
  BASE_URL,
  ITEM_PER_EVEN_ROW,
  ITEM_PER_ODD_ROW,
  MINUTE_BY_MS,
  SECOND_BY_MS,
  SITE,
  SLASH,
  WWW_DOT,
  XSS_EVAL,
  XSS_SCRIPT_TAG,
} from '@/common/constants';
import { ShortcutsSite } from '@/common/domain';
import { CollectTopicContent } from '@/common/store/slices/personal';

export interface OldSite {
  icon: string;
  title: string;
  url: string;
}

export const LOGIN_URL = {
  ZUM: (isQa: boolean) => {
    const prefix = isQa ? 'test.' : '';
    const clientId = isQa ? '8941192' : '7430794';
    return `https://${prefix}altools.co.kr/user/login?type=callback&client_id=${clientId}&redirect_url=https://union-user-api.zum.com/api/login&service_name=startzum&service_type=pc_web&device_type=pc&state=`;
  },
  FACEBOOK: () => 'https://user.zum.com/login/general?targetUrl=',
  KAKAO: () => 'https://sign.zum.com/auth/klogin_proc?targetUrl=',
  APPLE: () => 'https://user.zum.com/login/general?targetUrl=',
};

export function getLoginUrl(
  serviceName: keyof typeof LOGIN_URL,
  targetUrl: string = BASE_URL
) {
  const isQa = targetUrl.includes('dev') || targetUrl.includes('localhost');
  return LOGIN_URL[serviceName](isQa) + targetUrl;
}

/**
 * 간단한 throttle 함수
 */

export const throttle = (callback: (...args: any[]) => any, delay = 250) => {
  let shouldWait = false;

  return (...args: any[]) => {
    if (shouldWait) return;

    callback(...args);

    shouldWait = true;

    setTimeout(() => {
      shouldWait = false;
    }, delay);
  };
};
/**
 * 간단한 debounce 함수
 */

let timer: ReturnType<typeof setTimeout> | undefined;

export const debounce = (callback: (...args: any[]) => any, delay: number) => {
  return function () {
    if (timer) clearTimeout(timer);

    timer = setTimeout(callback, delay);
  };
};

/**
 * 대상 배열을 특정 횟수만큼 원소로 채우는 함수
 */
export const fillArray = (target: any[], element: any, count: number) => {
  Array.from({
    length: count,
  }).forEach(() => {
    target.push(element);
  });
};

/**
 * 배열에서 주어진 number만큼 나누어진 chunk를 만들어내는 함수
 */
export const getChunks = <T>(array: T[], number: number): T[][] => {
  let chunkIndex = 0;

  return array.reduce(
    (acc, element, index) => {
      acc[chunkIndex].push(element);
      if ((index + 1) % number === 0) {
        acc.push([]);
        chunkIndex += 1;

        return acc;
      } else return acc;
    },
    [[]] as T[][]
  );
};

/**
 * 컨텐츠들을 row 형태로 나타내기 위한 chunk를 나눠 반환하는 함수
 */
export const getChunksForRows = <T>(array: T[]): T[][] => {
  let chunkIndex = 0;
  let baseIndex = 0;

  return array.reduce(
    (acc, element, index) => {
      acc[chunkIndex].push(element);

      if (
        (chunkIndex % 2 === 0 &&
          (index + 1 - baseIndex) % ITEM_PER_EVEN_ROW === 0) ||
        (chunkIndex % 2 !== 0 &&
          (index + 1 - baseIndex) % ITEM_PER_ODD_ROW === 0)
      ) {
        index + 1 !== array.length && acc.push([]);
        baseIndex = index + 1;
        chunkIndex += 1;

        return acc;
      }

      return acc;
    },
    [[]] as T[][]
  );
};

/**
 * shffule 함수
 */
export const shuffle = <T>(array: T[]): T[] => {
  for (let index = array.length - 1; index > 0; index--) {
    // 무작위 index 값을 만든다. (0 이상의 배열 길이 값)
    const randomPosition = Math.floor(Math.random() * (index + 1));

    // 임시로 원본 값을 저장하고, randomPosition을 사용해 배열 요소를 섞는다.
    const temporary = array[index];
    array[index] = array[randomPosition];
    array[randomPosition] = temporary;
  }

  return array;
};

/**
 * Site 관련 name과 url을 검증하는 함수
 */
export const validateSiteInput = (name: string, url: string) => {
  if (!name.trim().length) {
    alert('이름을 입력해주세요.');

    return false;
  }

  if (!url.trim().length) {
    alert('URL을 입력해주세요.');

    return false;
  }

  return true;
};

/**
 * 현재 시간과 time의 diff를 구하고 이를 text로 나타내어 반환하는 메소드
 */
export const getTimeText = (time: string | number) => {
  const diff = Date.now() - Number(time);

  if (diff < MINUTE_BY_MS) return Math.ceil(diff / SECOND_BY_MS) + '초 전';

  if (diff >= MINUTE_BY_MS && diff < HOUR_BY_MS)
    return Math.ceil(diff / MINUTE_BY_MS) + '분 전';

  if (diff >= HOUR_BY_MS && diff < DAY_BY_MS)
    return Math.ceil(diff / HOUR_BY_MS) + '시간 전';

  return Math.ceil(diff / DAY_BY_MS) + '일 전';
};

/**
 * 개편 전 newtab의 추천사이트를 현재 스타트줌에 마이그레이션을 위해 변환하는 메소드
 */
export const transformSitesForMigrate = (oloSites: OldSite[]) => {
  const transformedSites: ShortcutsSite[] = oloSites.map((site) => {
    const { title, url } = site;

    return { type: SITE, name: title, url: url };
  });

  return transformedSites;
};

/**
 * oldSites인지 판단하는 판별 함수
 */
export const checkOldSites = (
  sites: Omit<OldSite, 'icon'>[] | OldSite[]
): sites is OldSite[] => {
  if (!sites.length) return false;

  const firstSite = sites[0];

  if (!('icon' in firstSite)) return false;

  const { title, url } = firstSite;

  return Boolean(title && url);
};

/**
 * deletedAt 프로퍼티에 들어가는 포맷대로 문자열을 반환하는 메소드
 */
export const toDeletedAtFormat = (date: Date) => {
  const apliedDate = applyTimezoneOffset(date);
  const string = apliedDate.toISOString();
  const splited = string.split('.');

  return getHead(splited);
};

/**
 * 받은 Date 인스턴스에 timezoneOffset을 적용하여 반환하는 메소드
 */
const applyTimezoneOffset = (date: Date) => {
  const timezoneOffset = new Date().getTimezoneOffset() * MINUTE_BY_MS;

  return new Date(date.getTime() - timezoneOffset);
};

/**
 * 배열의 첫 번째 배열을 반환하는 유틸 메소드
 */
export const getHead = (array: any[]) => {
  if (array.length) return array[0];

  return null;
};

/**
 * 통계에 필요한 thumb 프로퍼티 값을 만드는 유틸 함수
 */
export const makeThumb = (content: CollectTopicContent) => {
  return Number(content.largeThumbnail || content.thumbnail).toString();
};

/**
 * XSS 공격인지 판별하는 유틸 함수
 */
export const purifyScript = (script: string) => DOMpurify.sanitize(script);

// 객체에 falsy 프로퍼티가 있는 경우 필터링하는 유틸함수
export const removeFalsyProperty = <T>(object: T) => {
  for (const key in object) {
    if (!object[key]) delete object[key];
  }

  return object;
};

/**
 * 리덕스 extraReducer에 프로미스 귀결 방식에 따른 예외처리를 위한 matcher를 만들기 위한 커링 유틸 함수
 */
export const makeMatcher = (matchType: string) => (action: AnyAction) =>
  action.type.endsWith(`/${matchType}`);

/**
 * 잘못된 url을 적절한 url 형태로 변경하는 유틸함수
 */
export const convertToProperUrl = (url: string) => {
  // url이 http:// 이나 https:// 시작하지않는 경우
  if (!url.startsWith(HTTP) && !url.startsWith(HTTPS)) url = HTTPS + url;

  // url의 마지막이 /로 끝나는 경우
  if (url.endsWith(SLASH)) url = url.slice(0, url.length - 1);

  return url;
};

// 반환 값이 true 라면 value를 T 타입으로 간주하는 함수, T로 이루어진 array에 value가 포함되는지 판단한다.
export const contains = <T extends unknown>(
  array: ReadonlyArray<T>,
  value: unknown
): value is T => {
  return array.some((item) => item === value);
};
