import * as React from 'react';
import { INFINITE_SCROLL_ROOT_MARGIN } from '@/common/constants';

export const useInfiteScroll = (
  setCurrentPage: (currentPage: number) => void,
  currentPage: number
) => {
  const notiferRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const observer: IntersectionObserver = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) return;

        setCurrentPage(currentPage + 1);

        observer.disconnect();
      },
      {
        rootMargin: INFINITE_SCROLL_ROOT_MARGIN,
      }
    );
    if (notiferRef.current) observer.observe(notiferRef.current);
  });

  return {
    notiferRef,
  };
};
