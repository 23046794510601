import React, { useCallback, useState, FC, UIEvent } from 'react';
import { useDispatch } from 'react-redux';

import {
  fetchPersonalState,
  removeSite,
  ShortcutsSite,
  updateShortcutsState,
} from '@/common/store/slices/personal';
import { toggleModal } from '@/common/store/slices/modal/modalSlice';
import styles from '@/common/styles/components/links.module.scss';
import { useRootSelector } from '@/common/hooks';
import { FAVICON_URL, DEFAULT_URLS_CSS_CLASSES } from '@/common/constants';
import { statisticsService } from '@/common/services';
import { convertToProperUrl } from '@/common/utils';

interface Props {
  item: ShortcutsSite;
}

/**
 * 헤더 바로가기 영역 싱글 아이템 컴포넌트
 * @returns
 */
export const ShortcutsSiteItem: FC<Props> = ({ item }) => {
  const dispatch = useDispatch();
  const optionsIsShowName = useRootSelector(
    (state) => state.personalSlice.options.origin.widget.isHidingIconNames
  );
  const toggleLayer = (event: UIEvent<HTMLButtonElement>) => {
    const { parentNode } = event.target as HTMLElement;

    if (parentNode === null) return;

    const layer = parentNode.querySelector(`.${styles.edit_layer}`);

    if (layer === null) return;

    if (layer.classList.contains(`${styles.layer_on}`)) {
      layer.classList.remove(`${styles.layer_on}`);
    } else {
      layer.classList.add(`${styles.layer_on}`);
    }

    statisticsService.send('@Click', {
      pos: 'start_site',
      cm: 'more',
      outurl: item.url,
    });
  };

  const click = useCallback(() => {
    statisticsService.send('@Click', {
      pos: 'start_site',
      cm: 'site',
      outurl: item.url,
    });
  }, [item.url]);

  const remove = useCallback(
    async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();

      dispatch(removeSite({ url: item.url }));

      await dispatch(updateShortcutsState());
      await dispatch(fetchPersonalState());

      statisticsService.send('@Click', {
        pos: 'start_site',
        cm: 'delete',
        outurl: item.url,
      });
    },
    [item, dispatch]
  );

  const edit = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();

      dispatch(
        toggleModal({
          modal: 'editSiteModal',
          targetId: item.url,
        })
      );

      statisticsService.send('@Click', {
        pos: 'start_site',
        cm: 'edit',
        outurl: item.url,
      });
    },
    [item, dispatch]
  );

  const renderIcon = useCallback(() => {
    const iconCssByUrl =
      DEFAULT_URLS_CSS_CLASSES[
        convertToProperUrl(item.url) as keyof typeof DEFAULT_URLS_CSS_CLASSES
      ];

    if (iconCssByUrl)
      return <span className={`${styles[iconCssByUrl]}`}>{iconCssByUrl}</span>;

    return <img src={FAVICON_URL + item.url} alt={item.name} loading="lazy" />;
  }, [item.name, item.url]);

  return (
    <>
      <a href={convertToProperUrl(item.url)} onClick={click} target="_blank">
        <div className={`${styles.item} ${styles.single}`}>{renderIcon()}</div>
        {optionsIsShowName ? null : (
          <strong className={styles.item_name}>{item.name}</strong>
        )}
      </a>

      <div className="edit">
        <button type="button" className={styles.btn_edit} onClick={toggleLayer}>
          편집
        </button>
        <div className={styles.edit_layer}>
          <a href="#" onClick={edit}>
            편집
          </a>
          <a href="#" onClick={remove}>
            삭제
          </a>
        </div>
      </div>
    </>
  );
};
