import {
  Interests,
  TopicContent,
  HiddenMedia,
  Alarm,
  InterestsEnum,
} from '@/common/domain';
import { CollectOptions, ContentsDisplayType } from '@/common/domain/base';

export interface CollectInterests extends Interests {}

export interface CollectTopicContent extends TopicContent {}

export interface CollectHiddenContent extends Pick<TopicContent, 'link'> {}

export interface CollectHiddenMedia extends HiddenMedia {}

export interface CollectAlarm extends Alarm {}

export interface CollectDeletedAlarm extends Pick<CollectAlarm, 'link'> {
  deletedAt: string;
}

export const collectInterestsLabels: Record<InterestsEnum, string> = {
  [InterestsEnum.POPULARITY]: '인기',
  [InterestsEnum.POLITICS]: '정치',
  [InterestsEnum.SOCIETY]: '사회',
  [InterestsEnum.ECONOMY]: '경제',
  [InterestsEnum.WORLD]: '국제',
  [InterestsEnum.IT]: 'IT/과학',
  [InterestsEnum.CULTURE]: '문화',
  [InterestsEnum.ENTERTAINMENT]: '연예',
  [InterestsEnum.SPORTS]: '스포츠',
  [InterestsEnum.BIZ]: '비즈',
  [InterestsEnum.COLUMN]: '경제',
};

export const contentsDisplayTypeLabels: Record<ContentsDisplayType, string> = {
  all: '콘텐츠 표시',
  onlyTitle: '제목만 표시',
  part: '부분적으로 표시되는 콘텐츠',
};

export const COLLECT_KEY = 'collect';

export interface CollectState {
  interests: CollectInterests;
  options: CollectOptions;
  saveContents: CollectTopicContent[];
  readContents: CollectTopicContent[];
  hiddenContents: CollectHiddenContent[];
  hiddenMedia: CollectHiddenMedia[];
  deleteAlarms: CollectDeletedAlarm[];
  isLoading: boolean;
  isError: boolean;
}

export const collectState: CollectState = {
  interests: {
    popularity: true,
    politics: true,
    society: true,
    economy: true,
    world: true,
    it: true,
    culture: true,
    entertainment: true,
    sports: true,
    biz: true,
  },

  saveContents: [],
  readContents: [],
  hiddenContents: [],
  hiddenMedia: [],
  deleteAlarms: [],

  options: {
    isContentsBox: false,
    contentsDisplayType: 'all',
  },

  isLoading: false,
  isError: false,
};
