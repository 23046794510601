import React, { useState, useCallback, memo, MouseEvent, FC } from 'react';
import { useDispatch } from 'react-redux';

import { setLocation } from '@/common/store/slices/personal';
import { Address } from '@/common/components/modals/options/Location';
import styles from '@/common/styles/components/options.module.scss';
import { ClickStatsWrapper } from '@/common/components/hoc';
import { purifyScript } from '@/common/utils';

interface Props {
  locations: Address[];
  searchWord: string;
}

/**
 * 옵션 모달 - 위치 검색 결과
 * @returns
 */
export const LocationResults: FC<Props> = memo(
  ({ locations, searchWord }: Props) => {
    const dispatch = useDispatch();
    const [address, setAddress] = useState('');

    const selectAddress = useCallback(
      (address: string, event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        dispatch(setLocation(address));

        setAddress(address);
      },
      [dispatch]
    );

    const addEmphasis = useCallback(
      (address: string) => {
        const purifiedAddress = purifyScript(address);
        const replacer = '<strong>$&</strong>',
          replaceResult = purifiedAddress.replace(searchWord, replacer);

        return replaceResult;
      },
      [searchWord]
    );

    return (
      <div className={styles.result_list}>
        <ul>
          {searchWord &&
            locations.map((location) => (
              <ClickStatsWrapper
                event="@Click"
                properties={{
                  pos: 'start_settings',
                  selectTab: 'location',
                  cm: 'address',
                }}
                key={location.id}
              >
                <li className={address === location.addr ? 'selected' : ''}>
                  <a
                    href="#"
                    onClick={selectAddress.bind(null, location.addr)}
                    dangerouslySetInnerHTML={{
                      __html: addEmphasis(location.addr),
                    }}
                  />
                </li>
              </ClickStatsWrapper>
            ))}
        </ul>
      </div>
    );
  }
);
