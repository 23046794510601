import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { RootState } from '@/common/store';
import { PERSONAL_PATH } from '@/common/constants';

const FETCH_PERSONAL_STATE = 'personal/fetchPersonalState';

export const fetchPersonalState = createAsyncThunk<
  any,
  void,
  { state: RootState }
>(FETCH_PERSONAL_STATE, async () => {
  const { data } = await axios.get(`/${PERSONAL_PATH}`, {
    withCredentials: true,
  });

  return data;
});
