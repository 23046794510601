import * as React from 'react';
import { useDispatch } from 'react-redux';

import { useRootSelector } from '@/common/hooks';
import styles from '@/common/styles/components/options.module.scss';
import {
  WallpaperName,
  selectWallpaper,
  setChangeCycle,
  OptionsChangeCycle,
} from '@/common/store/slices/personal';
import { optionsChangeCycleLabels } from '@/common/store/slices/personal/options';
import { BG_CSS_CLASSES } from '@/common/constants';
import { changeCycles } from '@/common/domain';
import { ClickStatsWrapper } from '@/common/components/hoc';

/**
 * 옵션 모달 - 배경화면
 */
export const Wallpapers: React.FC = React.memo(() => {
  const wallpaperOption = useRootSelector(
    (state) => state.personalSlice.options.temporary.wallpaperOption
  );

  const dispatch = useDispatch();

  const selectedWallpaper = wallpaperOption.wallpapers.find(
    (wallpaper) => wallpaper.isSelected
  )?.name;
  const selectedCycle = wallpaperOption.changeCycle;
  const [isSelectBoxFold, setIsSelectBoxFold] = React.useState(true);
  const handleCycleButtonClick = React.useCallback(() => {
    setIsSelectBoxFold(!isSelectBoxFold);
  }, [isSelectBoxFold]);
  const handleCycleInputChange: React.EventHandler<
    React.ChangeEvent<HTMLInputElement>
  > = React.useCallback(
    (event) => {
      setIsSelectBoxFold(!isSelectBoxFold);

      const { dataset } = event.target as HTMLElement;

      dispatch(setChangeCycle(dataset.cycle as OptionsChangeCycle));
    },
    [isSelectBoxFold, dispatch]
  );

  const handleWallpaperSelectButtonClick: React.EventHandler<
    React.MouseEvent<HTMLButtonElement>
  > = React.useCallback(
    (event) => {
      const { dataset } = event.target as HTMLElement;

      if (dataset.name)
        dispatch(selectWallpaper(dataset.name as WallpaperName));
    },
    [dispatch]
  );

  return (
    <div className={styles.wallpaper_option_container}>
      <div className={`${styles.setting_block} ${styles.change_cycle}`}>
        <strong className="setting_name">화면 변경 주기</strong>
        <div className={styles.setting_content}>
          <div
            className={
              isSelectBoxFold
                ? styles.select_box
                : `${styles.select_box} unfold`
            }
          >
            {isSelectBoxFold && (
              <button type="button" onClick={handleCycleButtonClick}>
                설정
              </button>
            )}

            <ul>
              {changeCycles.map((cycle, index) => (
                <li
                  className={selectedCycle === cycle ? 'selected' : ''}
                  key={cycle}
                >
                  <ClickStatsWrapper
                    event="@Click"
                    properties={{
                      pos: 'start_settings',
                      selectTab: 'bg',
                      cm: cycle,
                    }}
                  >
                    <input
                      type="radio"
                      name="changeCycle"
                      id={`changeCycle${index + 1}`}
                      data-cycle={cycle}
                      onChange={handleCycleInputChange}
                    />
                    <label htmlFor={`changeCycle${index + 1}`}>
                      {optionsChangeCycleLabels[cycle]}
                    </label>
                  </ClickStatsWrapper>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className={`${styles.setting_block} ${styles.wallpapers}`}>
        <strong className="setting_name">화면 선택</strong>
        <div className={styles.setting_content}>
          <div className={styles.wallpaper_list}>
            <ul>
              {wallpaperOption.wallpapers.map((wallpaper, index) => (
                <li
                  className={
                    selectedWallpaper === wallpaper.name ? 'selected' : ''
                  }
                  key={wallpaper.name}
                >
                  <div className="item">
                    <div className="thumb">
                      <span
                        className={styles[BG_CSS_CLASSES[wallpaper.name]]}
                      ></span>
                    </div>
                    <span className="name">{wallpaper.name}</span>
                    <ClickStatsWrapper
                      event="@Click"
                      properties={{
                        cm: 'image',
                        pos: 'start_settings',
                        selectTab: 'bg',
                        image: String(index + 1),
                      }}
                    >
                      <button
                        type="button"
                        onClick={handleWallpaperSelectButtonClick}
                        data-name={wallpaper.name}
                      >
                        선택
                      </button>
                    </ClickStatsWrapper>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
});
