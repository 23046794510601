import {
  DESIRE,
  PAINT,
  AURORA,
  HOPE,
  REST,
  PLANET,
  START,
  LIFE,
  COOL,
  PLEASURE,
  CLOUD,
  MODERN,
  FOCUS,
  SUNSET,
} from '../../constants';

export const changeCycles = ['everyTime', 'day', 'fixed'] as const;
export type ChangeCycle = typeof changeCycles[number];
export type WallpaperName =
  | typeof DESIRE
  | typeof PAINT
  | typeof AURORA
  | typeof HOPE
  | typeof REST
  | typeof PLANET
  | typeof START
  | typeof LIFE
  | typeof COOL
  | typeof PLEASURE
  | typeof CLOUD
  | typeof MODERN
  | typeof FOCUS
  | typeof SUNSET;
export interface Wallpaper {
  name: WallpaperName;
  isSelected: boolean;
}

export interface WallpaperOption {
  changeCycle: ChangeCycle;
  wallpapers: Wallpaper[];
}
