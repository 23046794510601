import React, { Dispatch, FC, memo, SetStateAction, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import styles from '@/common/styles/components/options.module.scss';
import {
  useHomeData,
  useRootSelector,
  useFilteredAlarms,
} from '@/common/hooks';
import {
  addDeleteAlarms,
  CollectAlarm,
  fetchPersonalState,
} from '@/common/store/slices/personal';
import { AlarmList } from '@/common/components/modals/alarm/AlarmList';
import { getLoginUrl } from '@/common/utils';
import { ALARM_TAB, ZUM } from '@/common/constants';
import { ClickStatsWrapper } from '@/common/components/hoc';

/**
 * 헤더 알람 버튼을 누르면 노출되는 알람 탭 컴포넌트
 * @returns
 */

interface Props {
  visibleTab: string;
  setVisibleTab: Dispatch<SetStateAction<string>>;
}

export const HeaderAlarmTab: FC<Props> = memo(
  ({ visibleTab, setVisibleTab }: Props) => {
    const dispatch = useDispatch();
    const isLogin = useRootSelector((state) => state.rootSlice.isLogin);
    const { data, isError, isLoading } = useHomeData();
    const filteredAlarms = useFilteredAlarms({ alarms: data?.alarms });

    const checkVisible = useCallback(
      () => visibleTab === ALARM_TAB,
      [visibleTab]
    );

    const toggleVisible = useCallback(() => {
      if (checkVisible()) return setVisibleTab('');

      setVisibleTab(ALARM_TAB);
    }, [checkVisible, setVisibleTab]);

    const deleteAlarms = useCallback(
      async (alarms: CollectAlarm[]) => {
        if (isLogin) {
          await dispatch(addDeleteAlarms(alarms));
          await dispatch(fetchPersonalState());
        }
      },

      [isLogin, dispatch]
    );

    if (isError) {
      return <></>;
    }

    if (isLoading) {
      return <></>;
    }

    return (
      // 2022-04-27
      // ClickStatsWrapper 이슈
      // 내용 : 동일한 태그가 같은 class가 적용되거나 구조가 깨지는 현상
      // 수정방법 : btn_item 클래스가 적용되는 div -> span태그로 변경해서 임시 수정
      <>
        <span
          className={
            checkVisible()
              ? `${styles.btn_item} ${styles.item_selected}`
              : `${styles.btn_item}`
          }
        >
          <ClickStatsWrapper
            event="@Click"
            properties={{
              pos: 'start_notification',
              isAlert:
                !!filteredAlarms && filteredAlarms.length > 0
                  ? String(true)
                  : String(false),
            }}
          >
            {/* <ClickStatsWrapper
            event="@Click"
            properties={{
              pos: 'start_notification',
              isAlert:
                !!filteredAlarms && filteredAlarms.length > 0
                  ? String(true)
                  : String(false),
            }}
          >
            <button
              type="button"
              className={styles.btn_alarm}
              onClick={toggleVisible}
            >
              알림
            </button>
          </ClickStatsWrapper> */}
            <button
              type="button"
              className={styles.btn_alarm}
              onClick={toggleVisible}
            >
              알림
            </button>

            {!!filteredAlarms && filteredAlarms.length > 0 && (
              <span className="badge_count" onClick={toggleVisible}>
                {filteredAlarms.length}
              </span>
            )}
            {checkVisible() && (
              // 비로그인 시 팝업 디자인 변경에 맞춰 before_login_style 클래스 지정
              // 2022.07.13
              <div
                className={
                  !isLogin
                    ? `${styles.alarm_modal} ${styles.before_login_style}`
                    : `${styles.alarm_modal}`
                }
              >
                <div className={styles.modal_header}>
                  <h3>알림</h3>
                  <button
                    type="button"
                    className="btn_remove_all"
                    onClick={() => {
                      deleteAlarms(filteredAlarms);
                    }}
                  >
                    전체삭제
                  </button>
                </div>
                <div className="inner">
                  {isLogin && !!filteredAlarms && !!filteredAlarms.length && (
                    <AlarmList alarms={filteredAlarms} />
                  )}
                  {isLogin && !!filteredAlarms && !filteredAlarms.length && (
                    <>
                      <p className={styles.list_none}>
                        모든 알림을 다 읽으셨습니다.
                        <br />
                        잠시 후 새로운 소식을 받아보세요!
                      </p>
                    </>
                  )}
                  {!isLogin && (
                    <div className={styles.before_login}>
                      <h4>관심사를 설정해보세요!</h4>
                      <p>
                        지금 바로 스타트줌에 로그인하고
                        <br />
                        원하는 관심사의 새로운 소식을 받으세요.
                      </p>
                      <a href={getLoginUrl(ZUM, location.href)}>로그인</a>
                    </div>
                  )}
                </div>
                {!!filteredAlarms && (
                  <div className={styles.modal_bottom}>
                    <ClickStatsWrapper
                      event="@Click"
                      properties={{ pos: 'start_notification', cm: 'close' }}
                    >
                      <button type="button" onClick={toggleVisible}>
                        닫기
                      </button>
                    </ClickStatsWrapper>
                  </div>
                )}
              </div>
            )}
          </ClickStatsWrapper>
        </span>
        {checkVisible() && (
          <div className={styles.alarm_modal_bg} onClick={toggleVisible}></div>
        )}
      </>
    );
  }
);
