import * as React from 'react';
import {
  statisticsService,
  StatsInputData,
  eventType,
} from '@/common/services';

interface Props {
  readonly children: React.ReactNode;
  readonly event: eventType;
  readonly properties: StatsInputData;
}

export const ViewStatsWrapper: React.FC<Props> = ({
  children,
  event,
  properties,
}) => {
  const notifierRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!notifierRef.current) return;

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;

        statisticsService.send(event, properties);
        observer.disconnect();
      });
    });

    observer.observe(notifierRef.current);
  }, [event, properties]);

  return (
    <div className="wrapper_block" ref={notifierRef}>
      {children}
    </div>
  );
};
