import { useState } from 'react';

import { timestampService } from '@/common/services/timestampService';
import { TIMES } from '@/common/constants';

interface Props {
  atNotSeenForWeek: number;
  isInitialized: boolean;
}

export const useIsWeekPassed = ({ atNotSeenForWeek, isInitialized }: Props) => {
  const [isWeekPassed, setIsWeekPassed] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  const [currentTimestamp, setCurrentTimestamp] = useState(0);

  if (!isRequested && isInitialized) {
    setIsRequested(true);

    timestampService
      .getTimestamp()
      .then((timestamp) => {
        console.log(
          'timestamp:',
          timestamp,
          'atNotSeenForWeek:',
          atNotSeenForWeek,
          'timestamp - atNotSeenForWeek:',
          timestamp - atNotSeenForWeek
        );

        if (timestamp - atNotSeenForWeek >= TIMES.WEEK_BY_MS) {
          setCurrentTimestamp(timestamp);
          setIsWeekPassed(true);
        } else {
          setIsWeekPassed(false);
        }
      })
      .catch((error) => {
        console.error(error);

        setIsWeekPassed(false);
      });
  }

  return { isWeekPassed, currentTimestamp };
};
