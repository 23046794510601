import React, { useCallback, FC, UIEvent } from 'react';
import { useDispatch } from 'react-redux';

import {
  removeFolder,
  ShortcutsFolder,
  updateShortcutsState,
  fetchPersonalState,
  ShortcutsSite,
} from '@/common/store/slices/personal';
import { toggleModal } from '@/common/store/slices/modal/modalSlice';
import { useRootSelector } from '@/common/hooks';
import { statisticsService } from '@/common/services';
import { convertToProperUrl } from '@/common/utils';
import { FAVICON_URL, DEFAULT_URLS_CSS_CLASSES } from '@/common/constants';
import styles from '@/common/styles/components/links.module.scss';

interface ShortcutFolderItemProps {
  item: ShortcutsFolder;
}

/**
 * 헤더 바로가기 영역 폴더 아이템 컴포넌트
 * @returns
 */
export const ShortcutFolderItem: FC<ShortcutFolderItemProps> = ({ item }) => {
  const dispatch = useDispatch();
  const optionsIsShowName = useRootSelector(
    (state) => state.personalSlice.options.origin.widget.isHidingIconNames
  );
  const toggleLayer = (event: UIEvent<HTMLButtonElement>) => {
    const { parentNode } = event.target as HTMLElement;

    if (!parentNode) return;

    const layer = parentNode.querySelector(`.${styles.edit_layer}`);

    if (!layer) return;

    if (layer.classList.contains(`${styles.layer_on}`)) {
      layer.classList.remove(`${styles.layer_on}`);
    } else {
      layer.classList.add(`${styles.layer_on}`);
    }

    statisticsService.send('@Click', {
      pos: 'start_site',
      cm: 'more',
    });
  };
  const clickFolder = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();

      dispatch(
        toggleModal({
          modal: 'folderModal',
          targetId: item.name,
        })
      );

      statisticsService.send('@Click', {
        pos: 'start_site',
        cm: 'folder',
      });
    },
    [item, dispatch]
  );

  const remove = useCallback(
    async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();

      dispatch(removeFolder({ type: 'folder', name: item.name }));

      await dispatch(updateShortcutsState());
      await dispatch(fetchPersonalState());

      statisticsService.send('@Click', {
        pos: 'start_site',
        cm: 'delete',
      });
    },
    [item, dispatch]
  );

  const renderIcon = useCallback((item: ShortcutsSite) => {
    const iconCssByUrl =
      DEFAULT_URLS_CSS_CLASSES[
        convertToProperUrl(item.url) as keyof typeof DEFAULT_URLS_CSS_CLASSES
      ];

    if (iconCssByUrl)
      return (
        <span key={iconCssByUrl}>
          <span className={`${styles[iconCssByUrl]}`}>{iconCssByUrl}</span>
        </span>
      );

    return (
      <span key={iconCssByUrl}>
        <img src={FAVICON_URL + item.url} alt={item.name} loading="lazy" />
      </span>
    );
  }, []);

  return (
    <>
      <a href="#" onClick={clickFolder}>
        <div className={`${styles.item} ${styles.folder}`}>
          {item.children.map((item) => renderIcon(item))}
        </div>
        {optionsIsShowName ? null : (
          <strong className={styles.item_name}>{item.name}</strong>
        )}
      </a>

      <div className="edit">
        <button type="button" className={styles.btn_edit} onClick={toggleLayer}>
          더보기
        </button>
        <div className={styles.edit_layer}>
          <a href="#" onClick={remove}>
            삭제
          </a>
        </div>
      </div>
    </>
  );
};
