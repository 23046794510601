// 배경

export const DESIRE = '소망';
export const PAINT = '물감';
export const AURORA = '오로라';
export const HOPE = '희망';
export const REST = '휴식';
export const PLANET = '위성';
export const START = '시작';
export const PLEASURE = '기쁨';
export const LIFE = '생명';
export const COOL = '청량';
export const CLOUD = '구름';
export const MODERN = '현대';
export const FOCUS = '집중';
export const SUNSET = '석양';
export const WEATHER = '날씨';
export const AM = 'AM';
export const PM = 'PM';
export const NOON = 12;

// 프로미스 상태들

export const PROMISE_STATES = {
  PENDING: 'pending',
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
} as const;

// HTTP 헤더들

export const HEADERS = {
  CACHE_CONTROL: 'Cache-Control',
  PUBLIC: 'public',
  MAX_AGE: 'max-age',
};

export const DABLE_AD_IDS = {
  FEED_1: '3703rYdo',
  FEED_3: '2o2Kqm57',
  FEED_5: 'ko812p87',
  FEED_7: 'xXAgMGd7',
  FEED_9: 'Box6KJml',
  CATEGORY_1: '1oVgAx07',
  CATEGORY_2: 'KoEOnAZl',
  CATEGORY_3: 'xlz2A4Dl',
  CATEGORY_4: '6o3OGw1o',
  CATEGORY_5: 'V7KBO1Po',
  CATEGORY_6: '2XLWpAVl',
  CATEGORY_7: 'x7yv3gVl',
  CATEGORY_8: 'Pl1Eb6wl',
  CATEGORY_9: 'goB9jD2X',
  CATEGORY_10: 'GlGgpdgl',
} as const;

// 와이더 광고 아이디
export const WIDER_AD_IDS = {
  FEED: '30004',
  CATEGORY_1: '30006',
  CATEGORY_2: '30007',
  CATEGORY_3: '30008',
  CATEGORY_4: '30009',
  CATEGORY_5: '30010',
} as const;

// 인라이플 광고 아이디
export const ENLIPLE_AD_IDS = {
  CATEGORY_1: '686208',
  CATEGORY_2: '686211',
  CATEGORY_3: '686212',
  CATEGORY_4: '686213',
  CATEGORY_5: '686214',
} as const;

export const GOOGLE_AD = {
  PATH_1: '/23075372025/PC_front/PC_front_display_startrside01_240319',
  PATH_2: '/23075372025/PC_front/PC_front_display_startrside02_240319',
  ID_1: 'div-gpt-ad-1710998108135-0',
  ID_2: 'div-gpt-ad-1710998127685-0',
} as const;

// URL들
export const TEST_BASE_URL = 'http://localhost/api';
export const PRODUCTION_BFF_URL = 'https://bff-start.zum.com/api';
export const DEV_BFF_URL = 'http://localhost:8080/api';
export const QA_BFF_URL = 'https://dev-bff-start.zum.com/api';
export const LOCAL_BFF_URL = 'https://bff-local-start.zum.com:8080/api';
export const BASE_BFF_URL =
  process.env.MODE === 'prod'
    ? PRODUCTION_BFF_URL
    : process.env.MODE === 'qa'
    ? QA_BFF_URL
    : process.env.MODE === 'prod-local'
    ? LOCAL_BFF_URL
    : DEV_BFF_URL;
export const DEV_URL = 'http://localhost:3000';
export const QA_URL = 'https://dev-start.zum.com';
export const LOCAL_URL = 'https://local-start.zum.com:3000';
export const START_ZUM_URL = 'https://start.zum.com';
export const BASE_URL =
  process.env.MODE === 'prod'
    ? START_ZUM_URL
    : process.env.MODE === 'qa'
    ? QA_URL
    : process.env.MODE === 'prod-local'
    ? LOCAL_URL
    : DEV_URL;
export const START_ZUM_API_URL = BASE_URL + '/api';
export const FAVICON_URL = 'https://www.google.com/s2/favicons?sz=48&domain=';
export const UNION_API_URL = 'https://union-user-api.zum.com/api';
export const SUGGEST_URL =
  'https://ssug.api.search.zum.com/json.zum?method=app.chrome-zum';
export const HTTP = 'http://';
export const HTTPS = 'https://';
export const SLASH = '/';
export const WWW_DOT = 'www.';

// 데이터 API 경로, 데이터 KEY
export const HOME_DATA_KEY = 'home-data';

export const TIMES = {
  MINUTE_BY_MS: 60000,
  WEEK_BY_MS: 604800000,
  TEN_MINUTES_BY_SECOND: 6000,
};

// 계산을 위한 숫자값
export const THROTTLE_DELAY = 600;
export const PAGE_MOVE_DISTANCE = 116;
export const FODLER_PAGE_MOVE_DISTACNE = 316;
export const SHORTCUT_LIMIT = 40;
export const SECOND_BY_MS = 1000;
export const MINUTE_BY_MS = 60000;
export const HOUR_BY_MS = 3600000;
export const DAY_BY_MS = 86400000;
export const WEEK_BY_MS = 604800000;
export const ITEM_PER_LIST = 9;
export const ITEM_PER_FOLDER_PAGE = 6;
export const ITEM_PER_NOTE_PAGE = 3;
export const ITEM_PER_EVEN_ROW = 10;
export const ITEM_PER_ODD_ROW = 8;
export const ITEM_PER_PAGE_RANKING_NEWS = 8;
export const ROWS_PER_CONTETNS_PAGE = 2;
export const CONTENTS_COUNT = 80;
export const FIRST_PAGE = 0;
export const ROLLING_LIMIT = 10;
export const ROLLING_DELAY = 3000;
export const ITEM_PER_CONTENTS_PAGE = ITEM_PER_ODD_ROW + ITEM_PER_EVEN_ROW;

// 위치 찾기 관련
export const SPECIAL_CITY_SHORTCUTS = {
  서울시: '서울특별시',
  세종시: '세종특별자치시',
  제주도: '제주특별자치도',
} as const;
export const METROPOLITANS_SCHORTCUTS = {
  인천시: '인천광역시',
  대전시: '대전광역시',
  광주시: '광주광역시',
  대구시: '대구광역시',
  울산시: '울산광역시',
  부산시: '부산광역시',
} as const;
export const PROVINCE_SHORTCUTS = {
  충남: '충청남도',
  충북: '충청북도',
  전남: '전라남도',
  전북: '전라북도',
  경남: '경상남도',
  경북: '경상북도',
} as const;

// PATH
export const SAVE_CONTENTS_PATH = 'save-contents';
export const READ_CONTENTS_PATH = 'read-contents';
export const HIDDEN_MEDIA_PATH = 'hidden-media';
export const HIDDEN_CONTENTS_PATH = 'hidden-contents';
export const CONTENT_SETTING_PATH = 'content-setting';
export const DELETE_ALARMS_PATH = 'delete-alarms';
export const TARGET_PATH = 'target';
export const INTEREST_PATH = 'interests';
export const PERSONAL_PATH = 'personal';
export const SETTING_PATH = 'setting';
export const SHORTCUTS_PATH = 'shortcuts';

export const PATHS = {
  SENTRY: 'sentry',
  SAVE_CONTENTS: 'save-contents',
  READ_CONTENTS: 'read-contents',
  HIDDEN_MEDIA: 'hidden-media',
  HIDDEN_CONTENTS: 'hidden-contents',
  CONTENT_SETTING: 'content-setting',
  DELETE_ALARMS: 'delete-alarms',
  HOME: 'home',
  TARGET: 'target',
  INTEREST: 'interests',
  PERSONAL: 'personal',
  SETTING: 'setting',
  SHORTCUTS: 'shortcuts',
  TIMESTAMP: 'timestamp',
};

// TAB
export const ALARM_TAB = 'alarm';
export const OPTION_TAB = 'option';
export const LOGIN_TAB = 'login';
export const COLLECT_TAB = 'collect';

// 통계관련
export const REDIRECT_QM = 'today.start';
export const G_REAL1_START = 'g_real1.start';
export const F_SUGGEST_START = 'f_suggest.start';

export const PAGE_NAMES = {
  POPULARITY: 'popularity',
  NEWS: 'news',
  TV: 'tv',
  ENTERTAINMENT: 'entertainment',
  SPORTS: 'sports',
  BIZ: 'biz',
  FEED: 'feed',
  COLLECT: 'collect',
} as const;

export const PAGE_GROUP_FOR_AD_1 = [
  PAGE_NAMES.POPULARITY,
  PAGE_NAMES.NEWS,
  PAGE_NAMES.ENTERTAINMENT,
  PAGE_NAMES.BIZ,
] as const;

export const PAGE_GROUP_FOR_AD_2 = [PAGE_NAMES.TV, PAGE_NAMES.SPORTS] as const;

export const POPULARITY = 'popularity';
export const NEWS = 'news';
export const TV = 'tv';
export const ENTERTAINMENT = 'entertainment';
export const SPORTS = 'sports';
export const BIZ = 'biz';
export const FEED = 'feed';
export const COLLECT = 'collect';

// 기타 로직을 위한 상수들
export const enum COLLECT_SECTIONS {
  INTEREST_SECTION = 'interest',
  SAVED_CONTENTS_SECTION = 'savedContents',
  READ_CONTENTS_SECTION = 'readContents',
  LIKED_CONTENTS_SECTION = 'likedContents',
  HIDDEN_MEDIA_SECTION = 'hiddenMedia',
  OPTIONS_SECTION = 'options',
}
export const SITE = 'site';
export const FOLDER = 'folder';
export const INFINITE_SCROLL_ROOT_MARGIN = '0px 0px 300px 0px';
export const EZSID = 'EZSID';
export const ENTER = 'Enter';
export const AUTOCOMPLETE = 'autocomplete';
export const COOKIE = 'cookie';
export const USER_INFO = 'userInfo';
export const ID = 'id';
export const SAVE = '저장한 콘텐츠';
export const READ = '읽은 콘텐츠';
export const ZUM = 'ZUM';
export const EXTENSION = 'EXTENSION';
export const CHROME_EXTENSION = 'chrome-extension';

export const URLS = {
  ZUM_URL_1: 'https://www.zum.com',
  ZUM_URL_2: 'http://www.zum.com',
  ZUM_URL_3: 'https://zum.com',
  ZUM_URL_4: 'http://zum.com',
  NAVER_URL_1: 'https://www.naver.com',
  NAVER_URL_2: 'http://www.naver.com',
  NAVER_URL_3: 'https://naver.com',
  NAVER_URL_4: 'http://naver.com',
  DAUM_URL_1: 'https://www.daum.net',
  DAUM_URL_2: 'http://www.daum.net',
  DAUM_URL_3: 'https://daum.net',
  DAUM_URL_4: 'http://daum.net',
  GOOGLE_URL_1: 'https://www.google.co.kr',
  GOOGLE_URL_2: 'http://www.google.co.kr',
  GOOGLE_URL_3: 'https://google.co.kr',
  GOOGLE_URL_4: 'http://google.co.kr',
  NETFLIX_URL_1: 'https://www.netflix.com',
  NETFLIX_URL_2: 'http://www.netflix.com',
  NETFLIX_URL_3: 'https://netflix.com',
  NETFLIX_URL_4: 'http://netflix.com',
  NETFLIX_KR_URL_1: 'https://www.netflix.com/kr',
  NETFLIX_KR_URL_2: 'http://www.netflix.com/kr',
  NETFLIX_KR_URL_3: 'https://netflix.com/kr',
  NETFLIX_KR_URL_4: 'http://netflix.com/kr',
  BING_URL_1: 'https://www.bing.com',
  BING_URL_2: 'http://www.bing.com',
  BING_URL_3: 'https://bing.com',
  BING_URL_4: 'http://bing.com',
  MSN_URL_1: 'https://www.msn.com',
  MSN_URL_2: 'http://www.msn.com',
  MSN_URL_3: 'https://msn.com',
  MSN_URL_4: 'http://msn.com',
  MSN_KR_URL_1: 'https://www.msn.com/ko-kr',
  MSN_KR_URL_2: 'http://www.msn.com/ko-kr',
  MSN_KR_URL_3: 'https://msn.com/ko-kr',
  MSN_KR_URL_4: 'http://msn.com/ko-kr',
  YAHOO_URL_1: 'https://www.yahoo.com',
  YAHOO_URL_2: 'http://www.yahoo.com',
  YAHOO_URL_3: 'https://yahoo.com',
  YAHOO_URL_4: 'http://yahoo.com',
  CHOSUN_URL_1: 'https://www.chosun.com',
  CHOSUN_URL_2: 'http://www.chosun.com',
  CHOSUN_URL_3: 'https://chosun.com',
  CHOSUN_URL_4: 'http://chosun.com',
  JOONGANG_URL_1: 'https://www.joongang.co.kr',
  JOONGANG_URL_2: 'http://www.joongang.co.kr',
  JOONGANG_URL_3: 'https://joongang.co.kr',
  JOONGANG_URL_4: 'http://joongang.co.kr',
  DONGA_URL_1: 'https://www.donga.com',
  DONGA_URL_2: 'http://www.donga.com',
  DONGA_URL_3: 'http://donga.com',
  KHAN_URL_1: 'https://www.khan.co.kr',
  KHAN_URL_2: 'http://www.khan.co.kr',
  KHAN_URL_3: 'https://khan.co.kr',
  KHAN_URL_4: 'http://khan.co.kr',
  HANI_URL_1: 'https://www.hani.co.kr',
  HANI_URL_2: 'http://www.hani.co.kr',
  HANI_URL_3: 'https://hani.co.kr',
  HANI_URL_4: 'http://hani.co.kr',
  YNA_URL_1: 'https://www.yna.co.kr',
  YNA_URL_2: 'http://www.yna.co.kr',
  YNA_URL_3: 'https://yna.co.kr',
  YNA_URL_4: 'http://yna.co.kr',
  YTN_URL_1: 'https://www.ytn.co.kr',
  YTN_URL_2: 'http://www.ytn.co.kr',
  YTN_URL_3: 'https://ytn.co.kr',
  YTN_URL_4: 'http://ytn.co.kr',
  NEWSIS_URL_1: 'https://www.newsis.com',
  NEWSIS_URL_2: 'http://www.newsis.com',
  NEWSIS_URL_3: 'https://newsis.com',
  NEWSIS_URL_4: 'http://newsis.com',
  FACEBOOK_URL_1: 'https://www.facebook.com',
  FACEBOOK_URL_2: 'http://www.facebook.com',
  FACEBOOK_URL_3: 'https://facebook.com',
  FACEBOOK_URL_4: 'http://facebook.com',
  INSTAGRAM_URL_1: 'https://www.instagram.com',
  INSTAGRAM_URL_2: 'http://www.instagram.com',
  INSTAGRAM_URL_3: 'https://instagram.com',
  INSTAGRAM_URL_4: 'http://instagram.com',
  TWITTER_URL_1: 'https://www.twitter.com',
  TWITTER_URL_2: 'http://www.twitter.com',
  TWITTER_URL_3: 'https://twitter.com',
  TWITTER_URL_4: 'http://twitter.com',
  DCINSIDE_URL_1: 'https://www.dcinside.com',
  DCINSIDE_URL_2: 'http://www.dcinside.com',
  DCINSIDE_URL_3: 'https://dcinside.com',
  DCINSIDE_URL_4: 'http://dcinside.com',
  RULIWEB_URL_1: 'https://bbs.ruliweb.com',
  RULIWEB_URL_2: 'http://bbs.ruliweb.com',
  BOBAEDREAM_URL_1: 'https://www.bobaedream.co.kr',
  BOBAEDREAM_URL_2: 'http://www.bobaedream.co.kr',
  BOBAEDREAM_URL_3: 'https://bobaedream.co.kr',
  BOBAEDREAM_URL_4: 'http://bobaedream.co.kr',
  PPOMPPU_URL_1: 'https://www.ppomppu.co.kr',
  PPOMPPU_URL_2: 'http://www.ppomppu.co.kr',
  PPOMPPU_URL_3: 'https://ppomppu.co.kr',
  PPOMPPU_URL_4: 'http://ppomppu.co.kr',
  SLRCLUB_URL_1: 'https://www.slrclub.com',
  SLRCLUB_URL_2: 'http://www.slrclub.com',
  SLRCLUB_URL_3: 'https://slrclub.com',
  SLRCLUB_URL_4: 'http://slrclub.com',
  HOMETAX_URL_1: 'https://www.hometax.go.kr',
  HOMETAX_URL_2: 'http://www.hometax.go.kr',
  HOMETAX_URL_3: 'https://hometax.go.kr',
  HOMETAX_URL_4: 'http://hometax.go.kr',
  GOV_URL_1: 'https://www.gov.kr',
  GOV_URL_2: 'http://www.gov.kr',
  GOV_URL_3: 'https://gov.kr',
  GOV_URL_4: 'http://gov.kr',
  SCOURT_URL_1: 'https://www.scourt.go.kr',
  SCOURT_URL_2: 'http://www.scourt.go.kr',
  SCOURT_URL_3: 'https://scourt.go.kr',
  SCOURT_URL_4: 'http://scourt.go.kr',
  NTS_URL_1: 'https://www.nts.go.kr',
  NTS_URL_2: 'http://www.nts.go.kr',
  NTS_URL_3: 'https://nts.go.kr',
  NTS_URL_4: 'http://nts.go.kr',
  KMA_URL_1: 'https://www.weather.go.kr',
  KMA_URL_2: 'http://www.weather.go.kr',
  KMA_URL_3: 'https://weather.go.kr',
  KMA_URL_4: 'http://weather.go.kr',
  NHIS_URL_1: 'https://www.nhis.or.kr',
  NHIS_URL_2: 'http://www.nhis.or.kr',
  NPS_URL_1: 'https://www.nps.or.kr',
  NPS_URL_2: 'http://www.nps.or.kr',
  NPS_URL_3: 'https://nps.or.kr',
  NPS_URL_4: 'http://nps.or.kr',
  WORKNET_URL_1: 'https://www.work.go.kr',
  WORKNET_URL_2: 'http://www.work.go.kr',
  WORKNET_URL_3: 'https://work.go.kr',
  WORKNET_URL_4: 'http://work.go.kr',
  SARAMIN_URL_1: 'https://www.saramin.co.kr',
  SARAMIN_URL_2: 'http://www.saramin.co.kr',
  SARAMIN_URL_3: 'https://saramin.co.kr',
  SARAMIN_URL_4: 'http://saramin.co.kr',
  JOBKOREA_URL_1: 'https://www.jobkorea.co.kr',
  JOBKOREA_URL_2: 'http://www.jobkorea.co.kr',
  JOBKOREA_URL_3: 'https://jobkorea.co.kr',
  JOBKOREA_URL_4: 'http://jobkorea.co.kr',
  ALBAMON_URL_1: 'https://www.albamon.com',
  ALBAMON_URL_2: 'http://www.albamon.com',
  ALBAMON_URL_3: 'https://albamon.com',
  ALBAMON_URL_4: 'http://albamon.com',
  ALBA_URL_1: 'https://www.alba.co.kr',
  ALBA_URL_2: 'http://www.alba.co.kr',
  ALBA_URL_3: 'https://alba.co.kr',
  ALBA_URL_4: 'http://alba.co.kr',
  KOSAF_URL_1: 'https://www.kosaf.go.kr',
  KOSAF_URL_2: 'http://www.kosaf.go.kr',
  KIDKIDS_URL_1: 'https://www.kidkids.net',
  KIDKIDS_URL_2: 'http://www.kidkids.net',
  KIDKIDS_URL_3: 'https://kidkids.net',
  KIDKIDS_URL_4: 'http://kidkids.net',
  EBS_URL_1: 'https://www.ebs.co.kr',
  EBS_URL_2: 'http://www.ebs.co.kr',
  EBS_URL_3: 'https://ebs.co.kr',
  EBS_URL_4: 'http://www.ebs.co.kr',
  SHINHAN_URL_1: 'https://www.shinhan.com',
  SHINHAN_URL_2: 'http://www.shinhan.com',
  SHINHAN_URL_3: 'https://shinhan.com',
  SHINHAN_URL_4: 'http://shinhan.com',
  KB_URL_1: 'https://www.kbstar.com',
  KB_URL_2: 'http://www.kbstar.com',
  KB_URL_3: 'https://kbstar.com',
  KB_URL_4: 'http://kbstar.com',
  WOORI_URL_1: 'https://www.wooribank.com',
  WOORI_URL_2: 'http://www.wooribank.com',
  WOORI_URL_3: 'https://wooribank.com',
  WOORI_URL_4: 'http://wooribank.com',
  NONGHYUP_URL_1: 'https://banking.nonghyup.com',
  NONGHYUP_URL_2: 'http://banking.nonghyup.com',
  IBK_URL_1: 'https://www.ibk.co.kr',
  IBK_URL_2: 'http://www.ibk.co.kr',
  IBK_URL_3: 'https://ibk.co.kr',
  IBK_URL_4: 'http://ibk.co.kr',
  KEBHANA_URL_1: 'https://www.kebhana.com',
  KEBHANA_URL_2: 'http://www.kebhana.com',
  KEBHANA_URL_3: 'https://kebhana.com',
  KEBHANA_URL_4: 'http://kebhana.com',
  R114_URL_1: 'https://www.r114.com',
  R114_URL_2: 'http://www.r114.com',
  R114_URL_3: 'https://r114.com',
  R114_URL_4: 'http://r114.com',
  KBLAND_URL_1: 'https://www.kbland.kr',
  KBLAND_URL_2: 'http://www.kbland.kr',
  KBLAND_URL_3: 'https://kbland.kr',
  KBLAND_URL_4: 'http://kbland.kr',
  NATE_URL_1: 'https://www.nate.com',
  NATE_URL_2: 'http://www.nate.com',
  NATE_URL_3: 'https://nate.com',
  NATE_URL_4: 'http://nate.com',
  ZUM_INVEST_URL_1: 'https://invest.zum.com',
  ZUM_INVEST_URL_2: 'http://invest.zum.com',
  ZUM_INVEST_MEKKO_URL_1: 'https://invest.zum.com/domestic?cm=invest_main_gnb',
  ZUM_INVEST_MEKKO_URL_2: 'http://invest.zum.com/domestic?cm=invest_main_gnb',
};
//CSS 클래스
export const DEFAULT_URLS_CSS_CLASSES = {
  [URLS.ZUM_URL_1]: 'icon-zum',
  [URLS.ZUM_URL_2]: 'icon-zum',
  [URLS.ZUM_URL_3]: 'icon-zum',
  [URLS.ZUM_URL_4]: 'icon-zum',
  [URLS.NAVER_URL_1]: 'icon-naver',
  [URLS.NAVER_URL_2]: 'icon-naver',
  [URLS.NAVER_URL_3]: 'icon-naver',
  [URLS.NAVER_URL_4]: 'icon-naver',
  [URLS.DAUM_URL_1]: 'icon-daum',
  [URLS.DAUM_URL_2]: 'icon-daum',
  [URLS.DAUM_URL_3]: 'icon-daum',
  [URLS.DAUM_URL_4]: 'icon-daum',
  [URLS.GOOGLE_URL_1]: 'icon-google',
  [URLS.GOOGLE_URL_2]: 'icon-google',
  [URLS.GOOGLE_URL_3]: 'icon-google',
  [URLS.GOOGLE_URL_4]: 'icon-google',
  [URLS.NETFLIX_URL_1]: 'icon-netflix',
  [URLS.NETFLIX_URL_2]: 'icon-netflix',
  [URLS.NETFLIX_URL_3]: 'icon-netflix',
  [URLS.NETFLIX_URL_4]: 'icon-netflix',
  [URLS.NETFLIX_KR_URL_1]: 'icon-netflix',
  [URLS.NETFLIX_KR_URL_2]: 'icon-netflix',
  [URLS.NETFLIX_KR_URL_3]: 'icon-netflix',
  [URLS.NETFLIX_KR_URL_4]: 'icon-netflix',
  [URLS.BING_URL_1]: 'icon-bing',
  [URLS.BING_URL_2]: 'icon-bing',
  [URLS.BING_URL_3]: 'icon-bing',
  [URLS.BING_URL_4]: 'icon-bing',
  [URLS.MSN_URL_1]: 'icon-msn',
  [URLS.MSN_URL_2]: 'icon-msn',
  [URLS.MSN_URL_3]: 'icon-msn',
  [URLS.MSN_URL_4]: 'icon-msn',
  [URLS.MSN_KR_URL_1]: 'icon-msn',
  [URLS.MSN_KR_URL_2]: 'icon-msn',
  [URLS.MSN_KR_URL_3]: 'icon-msn',
  [URLS.MSN_KR_URL_4]: 'icon-msn',
  [URLS.YAHOO_URL_1]: 'icon-yahoo',
  [URLS.YAHOO_URL_2]: 'icon-yahoo',
  [URLS.YAHOO_URL_3]: 'icon-yahoo',
  [URLS.YAHOO_URL_4]: 'icon-yahoo',
  [URLS.CHOSUN_URL_1]: 'icon-chosun',
  [URLS.CHOSUN_URL_2]: 'icon-chosun',
  [URLS.CHOSUN_URL_3]: 'icon-chosun',
  [URLS.CHOSUN_URL_4]: 'icon-chosun',
  [URLS.JOONGANG_URL_1]: 'icon-joins',
  [URLS.JOONGANG_URL_2]: 'icon-joins',
  [URLS.JOONGANG_URL_3]: 'icon-joins',
  [URLS.JOONGANG_URL_4]: 'icon-joins',
  [URLS.DONGA_URL_1]: 'icon-joins',
  [URLS.DONGA_URL_2]: 'icon-joins',
  [URLS.DONGA_URL_3]: 'icon-joins',
  [URLS.KHAN_URL_1]: 'icon-khan',
  [URLS.KHAN_URL_2]: 'icon-khan',
  [URLS.KHAN_URL_3]: 'icon-khan',
  [URLS.KHAN_URL_4]: 'icon-khan',
  [URLS.HANI_URL_1]: 'icon-hani',
  [URLS.HANI_URL_2]: 'icon-hani',
  [URLS.HANI_URL_3]: 'icon-hani',
  [URLS.HANI_URL_4]: 'icon-hani',
  [URLS.YNA_URL_1]: 'icon-yna',
  [URLS.YNA_URL_2]: 'icon-yna',
  [URLS.YNA_URL_3]: 'icon-yna',
  [URLS.YNA_URL_4]: 'icon-yna',
  [URLS.YTN_URL_1]: 'icon-ytn',
  [URLS.YTN_URL_2]: 'icon-ytn',
  [URLS.YTN_URL_3]: 'icon-ytn',
  [URLS.YTN_URL_4]: 'icon-ytn',
  [URLS.NEWSIS_URL_1]: 'icon-newsis',
  [URLS.NEWSIS_URL_2]: 'icon-newsis',
  [URLS.NEWSIS_URL_3]: 'icon-newsis',
  [URLS.NEWSIS_URL_4]: 'icon-newsis',
  [URLS.FACEBOOK_URL_1]: 'icon-facebook',
  [URLS.FACEBOOK_URL_2]: 'icon-facebook',
  [URLS.FACEBOOK_URL_3]: 'icon-facebook',
  [URLS.FACEBOOK_URL_4]: 'icon-facebook',
  [URLS.INSTAGRAM_URL_1]: 'icon-instagram',
  [URLS.INSTAGRAM_URL_2]: 'icon-instagram',
  [URLS.INSTAGRAM_URL_3]: 'icon-instagram',
  [URLS.INSTAGRAM_URL_4]: 'icon-instagram',
  [URLS.TWITTER_URL_1]: 'icon-twitter',
  [URLS.TWITTER_URL_2]: 'icon-twitter',
  [URLS.TWITTER_URL_3]: 'icon-twitter',
  [URLS.TWITTER_URL_4]: 'icon-twitter',
  [URLS.DCINSIDE_URL_1]: 'icon-dcinside',
  [URLS.DCINSIDE_URL_2]: 'icon-dcinside',
  [URLS.DCINSIDE_URL_3]: 'icon-dcinside',
  [URLS.DCINSIDE_URL_4]: 'icon-dcinside',
  [URLS.RULIWEB_URL_1]: 'icon-ruliweb',
  [URLS.RULIWEB_URL_2]: 'icon-ruliweb',
  [URLS.BOBAEDREAM_URL_1]: 'icon-bobaedream',
  [URLS.BOBAEDREAM_URL_2]: 'icon-bobaedream',
  [URLS.BOBAEDREAM_URL_3]: 'icon-bobaedream',
  [URLS.BOBAEDREAM_URL_4]: 'icon-bobaedream',
  [URLS.PPOMPPU_URL_1]: 'icon-ppomppu',
  [URLS.PPOMPPU_URL_2]: 'icon-ppomppu',
  [URLS.PPOMPPU_URL_3]: 'icon-ppomppu',
  [URLS.PPOMPPU_URL_4]: 'icon-ppomppu',
  [URLS.SLRCLUB_URL_1]: 'icon-slrclub',
  [URLS.SLRCLUB_URL_2]: 'icon-slrclub',
  [URLS.SLRCLUB_URL_3]: 'icon-slrclub',
  [URLS.SLRCLUB_URL_4]: 'icon-slrclub',
  [URLS.HOMETAX_URL_1]: 'icon-hometax',
  [URLS.HOMETAX_URL_2]: 'icon-hometax',
  [URLS.HOMETAX_URL_3]: 'icon-hometax',
  [URLS.HOMETAX_URL_4]: 'icon-hometax',
  [URLS.GOV_URL_1]: 'icon-gov',
  [URLS.GOV_URL_2]: 'icon-gov',
  [URLS.GOV_URL_3]: 'icon-gov',
  [URLS.GOV_URL_4]: 'icon-gov',
  [URLS.SCOURT_URL_1]: 'icon-scourt',
  [URLS.SCOURT_URL_2]: 'icon-scourt',
  [URLS.SCOURT_URL_3]: 'icon-scourt',
  [URLS.SCOURT_URL_4]: 'icon-scourt',
  [URLS.NTS_URL_1]: 'icon-nts',
  [URLS.NTS_URL_2]: 'icon-nts',
  [URLS.NTS_URL_3]: 'icon-nts',
  [URLS.NTS_URL_4]: 'icon-nts',
  [URLS.KMA_URL_1]: 'icon-kma',
  [URLS.KMA_URL_2]: 'icon-kma',
  [URLS.KMA_URL_3]: 'icon-kma',
  [URLS.KMA_URL_4]: 'icon-kma',
  [URLS.NHIS_URL_1]: 'icon-nhis',
  [URLS.NHIS_URL_2]: 'icon-nhis',
  [URLS.NPS_URL_1]: 'icon-nps',
  [URLS.NPS_URL_2]: 'icon-nps',
  [URLS.NPS_URL_3]: 'icon-nps',
  [URLS.NPS_URL_4]: 'icon-nps',
  [URLS.WORKNET_URL_1]: 'icon-worknet',
  [URLS.WORKNET_URL_2]: 'icon-worknet',
  [URLS.WORKNET_URL_3]: 'icon-worknet',
  [URLS.WORKNET_URL_4]: 'icon-worknet',
  [URLS.SARAMIN_URL_1]: 'icon-saramin',
  [URLS.SARAMIN_URL_2]: 'icon-saramin',
  [URLS.SARAMIN_URL_3]: 'icon-saramin',
  [URLS.SARAMIN_URL_4]: 'icon-saramin',
  [URLS.JOBKOREA_URL_1]: 'icon-jobkorea',
  [URLS.JOBKOREA_URL_2]: 'icon-jobkorea',
  [URLS.JOBKOREA_URL_3]: 'icon-jobkorea',
  [URLS.JOBKOREA_URL_4]: 'icon-jobkorea',
  [URLS.ALBAMON_URL_1]: 'icon-albamon',
  [URLS.ALBAMON_URL_2]: 'icon-albamon',
  [URLS.ALBAMON_URL_3]: 'icon-albamon',
  [URLS.ALBAMON_URL_4]: 'icon-albamon',
  [URLS.ALBA_URL_1]: 'icon-alba',
  [URLS.ALBA_URL_2]: 'icon-alba',
  [URLS.ALBA_URL_3]: 'icon-alba',
  [URLS.ALBA_URL_4]: 'icon-alba',
  [URLS.KOSAF_URL_1]: 'icon-kosaf',
  [URLS.KOSAF_URL_2]: 'icon-kosaf',
  [URLS.KIDKIDS_URL_1]: 'icon-kidkids',
  [URLS.KIDKIDS_URL_2]: 'icon-kidkids',
  [URLS.KIDKIDS_URL_3]: 'icon-kidkids',
  [URLS.KIDKIDS_URL_4]: 'icon-kidkids',
  [URLS.EBS_URL_1]: 'icon-ebs',
  [URLS.EBS_URL_2]: 'icon-ebs',
  [URLS.EBS_URL_3]: 'icon-ebs',
  [URLS.EBS_URL_4]: 'icon-ebs',
  [URLS.SHINHAN_URL_1]: 'icon-shinhan',
  [URLS.SHINHAN_URL_2]: 'icon-shinhan',
  [URLS.SHINHAN_URL_3]: 'icon-shinhan',
  [URLS.SHINHAN_URL_4]: 'icon-shinhan',
  [URLS.KB_URL_1]: 'icon-kb',
  [URLS.KB_URL_2]: 'icon-kb',
  [URLS.KB_URL_3]: 'icon-kb',
  [URLS.KB_URL_4]: 'icon-kb',
  [URLS.WOORI_URL_1]: 'icon-woori',
  [URLS.WOORI_URL_2]: 'icon-woori',
  [URLS.WOORI_URL_3]: 'icon-woori',
  [URLS.WOORI_URL_4]: 'icon-woori',
  [URLS.NONGHYUP_URL_1]: 'icon-nonghyup',
  [URLS.NONGHYUP_URL_2]: 'icon-nonghyup',
  [URLS.IBK_URL_1]: 'icon-ibk',
  [URLS.IBK_URL_2]: 'icon-ibk',
  [URLS.IBK_URL_3]: 'icon-ibk',
  [URLS.IBK_URL_4]: 'icon-ibk',
  [URLS.KEBHANA_URL_1]: 'icon-kebhana',
  [URLS.KEBHANA_URL_2]: 'icon-kebhana',
  [URLS.KEBHANA_URL_3]: 'icon-kebhana',
  [URLS.KEBHANA_URL_4]: 'icon-kebhana',
  [URLS.R114_URL_1]: 'icon-r114',
  [URLS.R114_URL_2]: 'icon-r114',
  [URLS.R114_URL_3]: 'icon-r114',
  [URLS.R114_URL_4]: 'icon-r114',
  [URLS.KBLAND_URL_1]: 'icon-kbland',
  [URLS.KBLAND_URL_2]: 'icon-kbland',
  [URLS.KBLAND_URL_3]: 'icon-kbland',
  [URLS.KBLAND_URL_4]: 'icon-kbland',
  [URLS.NATE_URL_1]: 'icon-nate',
  [URLS.NATE_URL_2]: 'icon-nate',
  [URLS.NATE_URL_3]: 'icon-nate',
  [URLS.NATE_URL_4]: 'icon-nate',
  [URLS.YAHOO_URL_1]: 'icon-youtube',
  [URLS.YAHOO_URL_2]: 'icon-youtube',
  [URLS.YAHOO_URL_3]: 'icon-youtube',
  [URLS.YAHOO_URL_4]: 'icon-youtube',
  [URLS.ZUM_INVEST_URL_1]: 'icon-zum-invest',
  [URLS.ZUM_INVEST_URL_2]: 'icon-zum-invest',
  [URLS.ZUM_INVEST_MEKKO_URL_1]: 'icon-zum-invest',
  [URLS.ZUM_INVEST_MEKKO_URL_2]: 'icon-zum-invest',
};

export const BG_CSS_CLASSES = {
  [DESIRE]: 'desire',
  [PAINT]: 'paint',
  [AURORA]: 'aurora',
  [HOPE]: 'hope',
  [REST]: 'rest',
  [PLANET]: 'planet',
  [START]: 'start',
  [PLEASURE]: 'pleasure',
  [LIFE]: 'life',
  [COOL]: 'cool',
  [CLOUD]: 'cloud',
  [MODERN]: 'modern',
  [FOCUS]: 'focus',
  [SUNSET]: 'sunset',
};

export const MEDIA_CSS_CLASSES: { [key: string]: string } = {
  경향신문: 'logo-khan',
  노컷뉴스: 'logo-nocutnews',
  동아일보: 'logo-donga',
  서울신문: 'logo-seoul',
  세계일보: 'logo-segye',
  아시아투데이: 'logo-asiatoday',
  아이뉴스24: 'logo-inews24',
  조선일보: 'logo-chosun',
  중앙일보: 'logo-joongang',
  한겨레: 'logo-hani',
  한국일보: 'logo-hankookilbo',
  CBC뉴스: 'logo-cbci',
  JTBC: 'logo-jtbc',
  MBC: 'logo-mbc',
  MBN: 'logo-mbn',
  OBS: 'logo-obs',
  SBS: 'logo-sbs',
  TV조선: 'logo-tvchosun',
  YTN: 'logo-ytn',
  국제뉴스: 'logo-gukjenews',
  뉴스1: 'logo-news1',
  뉴시스: 'logo-newsis',
  연합뉴스: 'logo-yna',
  연합뉴스TV: 'logo-yonhapnewstv',
  한국경제TV: 'logo-wowtv',
  뉴스핌: 'logo-newspim',
  매일경제: 'logo-mk',
  머니투데이: 'logo-mt',
  비즈니스워치: 'logo-bizwatch',
  서울경제: 'logo-sedaily',
  세계비즈: 'logo-segyebiz',
  아시아경제: 'logo-asiae',
  아주경제: 'logo-ajunews',
  와이어드코리아: 'logo-wired',
  이데일리: 'logo-edaily',
  이코노믹리뷰: 'logo-econovill',
  이투데이: 'logo-etoday',
  인더뉴스: 'logo-inthenews',
  조선비즈: 'logo-bizchosun',
  파이낸셜뉴스: 'logo-fnnews',
  팍스경제TV: 'logo-paxetv',
  한국금융신문: 'logo-fntimes',
  경제: 'logo-bizherald',
  AI타임스: 'logo-aitimes',
  'IT NEWS': 'logo-itnews',
  ITWorld: 'logo-itworld',
  IT동아: 'logo-itdonga',
  IT조선: 'logo-itchosun',
  게임동아: 'logo-gamedonga',
  게임메카: 'logo-gamemeca',
  경향게임스: 'logo-khgames',
  더게임스데일리: 'logo-tgdaily',
  동아사이언스: 'logo-dongascience',
  디지털데일리: 'logo-ddaily',
  디지털투데이: 'logo-digitaltoday',
  매경게임진: 'logo-gamemk',
  벤처스퀘어: 'logo-venturesquare',
  스마트PC사랑: 'logo-ilovepc',
  전자신문: 'logo-etnews',
  지디넷코리아: 'logo-zdnet',
  테크M: 'logo-techm',
  플래텀: 'logo-platum',
  OSEN: 'logo-osen',
  'SBS Golf': 'logo-sbsgolf',
  'SBS Sports': 'logo-sbs',
  SBS연예뉴스: 'logo-entsbs',
  TV리포트: 'logo-tvreport',
  디스패치: 'logo-dispatch',
  스타데일리뉴스: 'logo-stardailynews',
  스타투데이: 'logo-mkstar',
  스포츠W: 'logo-sportsw',
  스포츠서울: 'logo-sportsseoul',
  스포츠월드: 'logo-sportsworldi',
  스포츠투데이: 'logo-stoo',
  스포티비뉴스: 'logo-spotvnews',
  엑스포츠뉴스: 'logo-xportsnews',
  인터풋볼: 'logo-interfootball',
  조이뉴스24: 'logo-joynews24',
  텐아시아: 'logo-tenasia',
  뉴스웨이: 'logo-newsway',
  뉴스타파: 'logo-newstapa',
  더스쿠프: 'logo-thescoop',
  더팩트: 'logo-tf',
  메트로신문사: 'logo-metroseoul',
  오마이뉴스: 'logo-ohmynews',
  쿠키뉴스: 'logo-kukinews',
  프레시안: 'logo-pressian',
  fn아이포커스: 'logo-fneyefocus',
  뉴스테이지: 'logo-newstage',
  라포르시안: 'logo-rapportian',
  매경이코노미: 'logo-mkeconomy',
  문화뉴스: 'logo-mhns',
  베이비뉴스: 'logo-ibabynews',
  일요시사: 'logo-ilyosisa',
  케미컬뉴스: 'logo-chemicalnews',
  하이닥: 'logo-hidoc',
  한겨레21: 'logo-han21',
  힐팁: 'logo-healtip',
  중부매일: 'logo-jbnews',
  충청일보: 'logo-ccdailynews',
  이뉴스24: 'logo-enews24',
  미디어원: 'logo-enews245',
  엠스플뉴스: 'logo-mspnews',
  헬스프레스: 'logo-healthpress',
  마이데일리: 'logo-mydaily',
  신동아: 'logo-shindonga',
  시사IN: 'logo-sisain',
  // 'e경제뉴스': 'logo-economy',
  // '테크42': 'logo-tech42',
  // 'MHN스포츠': 'logo-mhnse',
  // '우먼컨슈머': 'logo-womancs',
  // '파인드비': 'logo-findb',
};
