import * as React from 'react';
import { useDispatch } from 'react-redux';

import styles from '@/common/styles/components/modal.module.scss';
import { toggleModal } from '@/common/store/slices/modal/modalSlice';

/**
 *
 * @returns
 */
export const NonSelectedInterestModal: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const closeModal = React.useCallback(() => {
    dispatch(
      toggleModal({
        modal: 'nonSelectedInterestModal',
      })
    );
  }, [dispatch]);

  return (
    <>
      <div className={styles.common_modal}>
        <div className="inner">
          <div className={`${styles.icon} noti`}></div>
          <div className={styles.text}>
            최소 1개의 관심사를
            <br />
            선택해주세요!
          </div>
          <div className={`${styles.btn_bottom} full`}>
            <button type="button" onClick={closeModal}>
              확인
            </button>
          </div>
        </div>
        <button
          type="button"
          className={styles.btn_modal_close}
          onClick={closeModal}
        >
          닫기
        </button>
      </div>

      <div className={styles.modal_bg} onClick={closeModal}></div>
    </>
  );
});
