import React, { FC, memo, useCallback, useEffect, useState } from 'react';

import styles from '@/common/styles/components/weather.module.scss';
import { useHomeData, useTarget, useRootSelector } from '@/common/hooks';
import { Weather } from '@/common/domain';
import { REDIRECT_QM, WEATHER } from '@/common/constants';

export interface Address {
  id: string;
  addr: string;
}

const mapping = {
  수원시: '경기',
  강릉시: '강원',
  서산시: '충청남',
  청주시: '충청북',
  포항시: '경상북',
  창원시: '경상남',
  전주시: '전라북',
  여수시: '전라남',
};

const addresses: Address[] = require('../../../resources/stub/address.json');

/**
 * 헤더 상단 왼쪽에 있는 날씨 컴포넌트
 * @returns
 */
export const HeaderWeather: FC = memo(() => {
  const { data, isLoading, isError } = useHomeData();

  const location = useRootSelector(
    (state) => state.personalSlice.options.origin.location
  );
  const { target } = useTarget();
  const [selectedWeather, setSelectedWeather] = useState<Weather>();
  const [address, setAddress] = useState('');

  const appendedWeathers = useCallback((weathers: Weather[]) => {
    return Object.entries({ ...mapping }).map(([k, v]) => {
      const selectedWeather = weathers.find(
        ({ provinceName }) => provinceName === k
      );

      if (selectedWeather) return { ...selectedWeather, provinceName: v };

      return selectedWeather;
    });
  }, []);

  const renderIcon = useCallback(() => {
    return (
      <i
        className={`${styles.icon} ${styles[`wt_${selectedWeather?.nowIcon}`]}`}
      />
    );
  }, [selectedWeather]);

  useEffect(() => {
    if (!data?.weather) return;

    const weathers = [...data?.weather, ...appendedWeathers(data.weather)];
    const targetAddress = [...addresses].find(
      ({ id }) => Number(target) === Number(id)
    );

    if (targetAddress) setAddress(targetAddress.addr);

    const selectedWeather = weathers.find((weather) => {
      if (!weather) return false;

      if ((!location || location === null) && target && targetAddress)
        return targetAddress.addr.includes(weather.provinceName);

      if (location) return location.includes(weather.provinceName);

      return false;
    });

    if (!selectedWeather) {
      setSelectedWeather(weathers[0]);

      return;
    }

    setSelectedWeather(selectedWeather);
  }, [data?.weather, location, target, appendedWeathers]);

  if (isLoading) {
    return <></>;
  }

  if (isError) {
    return <></>;
  }

  return (
    <div className={styles.info}>
      <a
        href={`https://search.zum.com/search.zum?method=uni&option=accu&rd=1&query=${encodeURIComponent(
          `${
            location
              ? location
              : address
              ? address
              : selectedWeather?.provinceName
          } ${WEATHER}`
        )}&qm=${REDIRECT_QM}&rc=weather`}
        target="_blank"
      >
        {renderIcon()}
        <p className={styles.text}>
          <strong className="temperature">{selectedWeather?.nowTemp}°</strong>{' '}
          <span className="climate">{selectedWeather?.nowText}</span>
        </p>
      </a>
    </div>
  );
});
