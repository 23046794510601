import { v4 as uuid } from '@lukeed/uuid';
import { authService } from './authService';

interface StartZumConfig {
  version: string;
}

declare global {
  interface Window {
    zum: { start: StartZumConfig } & Record<string, unknown>;

    ESTatTracker: {
      push: ([event, data]: [eventType, StatsOutputData]) => void;
    };
    ga: any;
  }
}

// 가능한 event의 type
export type eventType =
  | '@Click'
  | '@PageView'
  | '@SuggestPageView'
  | '@SuggestMove'
  | '@SuggestQuery'
  | '@ScrollView'
  | '@ScrollToBottom'
  | '@ArrayClick'
  | '@IconMove'
  | '@SearchQuery'
  | '@BannerPageView';

export interface StatsInputData {
  pos?: string; // 클릭 위치 구분
  cm?: string; // 클릭 발생 위치
  outurl?: string; // 랜딩 페이지 url
  image?: string; // 화면 선택 이미지
  selectTab?: string; // 선택한 탭명
  isAlert?: string; // 알림 표시 유무
  ac?: number; // 노출된 서제스트 개수
  em?: string; // 동작 구분, keypress이거나 click
  qm?: string; // 최근검색어 / 서제스트(추천검색어)
  selkey?: string; // 실제 입력한 키워드
  keyword?: string; // 사용자가 선택한 키워드
  eng?: string[]; // 검색엔진
  newWin?: boolean; // 새창 유무
  query?: string; // 검색한 키워드
  page?: number; // 페이지 번호
  order?: number; // 콘텐츠 번호
  dateTab?: string; /// 기간 탭명
  r?: string; // 콘텐츠 번호
  thumb?: string; // 썸네일 기사 구분
  scrollPos?: string; // 스크롤 노출 영역
  items?: string[]; // 선택된 관심사들
  brand?: string; // 매체 출처
  pr_name?: string; // 언론사 코드에 따른 매체사명
}

// 옵셔널 값들은 디폴트로로 estat가 삽입하므로 특별한 경우 아니면 임의로 삽입 금지
export interface StatsDefaultData {
  referrer?: string; // 사용자 referrer url
  url: string; // 사용자 현재 url
  screenSize?: string; // 실제 렌더링 해상도(스크린 사이즈)
  clientDocSize?: string; // 사용자 모니터 해상도
  time?: number; // 사용자 컴퓨터 시간
  version?: string; // estat 버전 구분
  puid: string; // 페이지 고유 id
  isAuth: string; // 로그인 여부
  codeVersion: string;
}

export type StatsOutputData = StatsDefaultData & StatsInputData;

// 기본 uuid 형식을 따르되, zum.com 프로젝트임을 명시하기 위해 가장 첫 글자, 마지막 글자는 G로 치환함
export const puid = uuid()
  .replace(/^[0-9a-z]/im, 'S')
  .replace(/[0-9a-z]$/im, 'S');

let prevUrl = '';

// at.js가 로드되지 않았을 때 통계 정보를 모아두는 배열
const unloadedScriptStats: any[] = [];

export const statisticsService = {
  getStatsData(event: eventType, data: StatsInputData): StatsOutputData {
    const mergedData: StatsOutputData = {
      ...data,
      url: location.href,
      puid,
      isAuth: String(authService.checkIsAuth()),
      codeVersion: window.zum.start.version,
    };

    if (prevUrl) mergedData.referrer = prevUrl;

    if (event === '@PageView') prevUrl = location.href;

    for (const [key, value] of Object.entries(mergedData)) {
      if (value !== null && value !== undefined) continue;

      delete mergedData[key as keyof StatsOutputData];
    }

    return mergedData;
  },

  send(event: eventType, inputData: StatsInputData) {
    const outputData = this.getStatsData(event, inputData);

    if (process.env.NODE_ENV !== 'production') {
      console.log(
        `%c ESTatTracker Statistics\n${JSON.stringify(
          [event, outputData],
          null,
          2
        )}`,
        'color: #f09'
      );
    }

    if (window.ESTatTracker && typeof window.ESTatTracker.push === 'function') {
      // 로드되지 않았을 때 전송요청한 통계
      unloadedScriptStats.forEach((stat) => window.ESTatTracker.push(stat));
      unloadedScriptStats.splice(0);
      window.ESTatTracker.push([event, outputData]); // 통계 전송
    } else {
      // 통계 전송 함수가 없으면 임시 배열에 모아둔다.
      unloadedScriptStats.push([event, outputData]); // 통계 전송
    }
  },
};
