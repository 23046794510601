import React, { FC, useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

import styles from '@/common/styles/components/navigation.module.scss';
import {
  BIZ,
  ENTERTAINMENT,
  FEED,
  NEWS,
  POPULARITY,
  SPORTS,
  TV,
} from '@/common/constants';
import { useDispatch } from 'react-redux';
import {
  setCurrentRoute,
  CurrentRoute,
} from '@/common/store/slices/root/rootSlice';
import { useRootSelector } from '@/common/hooks';
import { ClickStatsWrapper } from '../hoc';

/**
 * 헤더 하단에 있는 GNB 컴포넌트
 * @returns
 */
export const HeaderGnb: FC = () => {
  const currentRoute = useRootSelector((state) => state.rootSlice.currentRoute);

  const dispatch = useDispatch();
  const selectTab = useCallback(
    (route) => {
      dispatch(setCurrentRoute(route));
    },
    [dispatch]
  );

  const router = useRouter();
  useEffect(() => {
    dispatch(setCurrentRoute(router.pathname.slice(1) as CurrentRoute));
  }, [dispatch, router.pathname]);

  const tabs = [
    { name: '내 피드', route: '' },
    { name: '인기', route: POPULARITY },
    { name: '뉴스', route: NEWS },
    { name: 'TV', route: TV },
    { name: '연예', route: ENTERTAINMENT },
    { name: '스포츠', route: SPORTS },
    { name: '비즈', route: BIZ },
  ];

  return (
    <div className={styles.wrap}>
      <div className="inner">
        <nav className={styles.tabs}>
          <ul>
            {tabs.map((tab) => (
              <li
                className={tab.route === currentRoute ? styles.selected : ''}
                key={tab.name}
                onClick={() => {
                  selectTab(tab.route);
                }}
              >
                <ClickStatsWrapper
                  event="@Click"
                  properties={{
                    pos: 'gnb_contents',
                    selectTab: tab.route ? tab.route : FEED,
                    cm: 'tab',
                  }}
                >
                  <Link href={`/${tab.route}`} as={`/${tab.route}`}>
                    {tab.name}
                  </Link>
                </ClickStatsWrapper>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};
