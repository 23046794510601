import React, {
  FC,
  IframeHTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useRef,
} from 'react';

interface Props extends IframeHTMLAttributes<HTMLIFrameElement> {
  onIframeClick?: (iframe: HTMLIFrameElement) => void;
}

export const ClickableIframe: FC<Props> = memo((props: Props) => {
  const iframeRef = useRef<null | HTMLIFrameElement>(null);
  const iframeCallbackRef = useCallback((element: null | HTMLIFrameElement) => {
    iframeRef.current = element;
  }, []);
  const newProps = {
    ...props,
  };

  delete newProps.onIframeClick;

  useEffect(() => {
    const onBlur = () => {
      const { activeElement } = document;

      if (
        activeElement &&
        activeElement.tagName === 'IFRAME' &&
        iframeRef.current &&
        iframeRef.current === activeElement
      ) {
        props.onIframeClick && props.onIframeClick(iframeRef.current);
      }
    };

    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('blur', onBlur);
    };
  });

  return <iframe {...newProps} ref={iframeCallbackRef}></iframe>;
});
