import axios from 'axios';

import { PATHS } from '@/common/constants';

export const sentryService = {
  /**
   * Sentry 이벤트가 이전에 발생하여 서버에 캐시되어있는지 확인하는 메소드
   * 중복되는 이벤트를 Sentry에 적재하지 않기 위함
   */
  checkEventIsCached({ eventCacheKey }: { eventCacheKey: string }) {
    return axios.post(`${PATHS.SENTRY}`, {
      eventCacheKey,
    });
  },
};
